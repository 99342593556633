import React, { ReactElement, useCallback } from 'react'
import { SmileNeutralIcon } from '@woorcs/design-system'
import { useParams } from 'react-router'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as TO from 'fp-ts/TaskOption'
import { pipe } from 'fp-ts/function'
import {
  AccountProvider,
  ApiClientProvider,
  MePayload,
  useAccount
} from '@woorcs/api-client'
import { NonEmptyString } from '@woorcs/utils/formik'
import * as P from 'fp-ts/Predicate'

import { useAuth } from '@app/auth'
import { EmptyState } from '@app/components'
import { ResetRouterContext, useRouter } from '@app/utils/router'
import { AuthError } from '@app/auth/AuthError'

import { LobbyLayout } from './components'
import { SelectOrganizationPage, CreateOrganizationPage } from './pages'

const baseUrl = import.meta.env.VITE_REACT_APP_API_URL?.toString() || ''

const NoAccessPage = () => (
  <LobbyLayout>
    <EmptyState
      Icon={SmileNeutralIcon}
      status='danger'
      title="You don't have access to this organization"
      description='If you think this is wrong contact an administrator.'
    />
  </LobbyLayout>
)

interface OrganizationPageProps {
  children: (props: { organizationId: string }) => ReactElement
}

const isOrganizationMember = (organizationId: string) => (viewer: MePayload) =>
  pipe(
    viewer.organizations,
    A.some((organization) => organization.id === organizationId)
  )
const isSuperuser = (viewer: MePayload) => viewer.account.superuser

const hasAccess = (organizationId: string) =>
  pipe(isOrganizationMember(organizationId), P.or(isSuperuser))

const OrganizationGuard = ({ children }: OrganizationPageProps) => {
  const account = useAccount()
  const params = useParams()
  const organizationId = pipe(
    O.fromNullable(params.organizationId),
    O.chainEitherK(NonEmptyString.decode)
  )

  // const hasAccess = viewer.role === Role.Admin || viewer.superuser

  return pipe(
    organizationId,
    O.chain((organizationId) =>
      pipe(
        account,
        O.fromPredicate(hasAccess(organizationId)),
        O.map(() => (
          // eslint-disable-next-line react/jsx-key
          <ResetRouterContext>
            {children({ organizationId })}
          </ResetRouterContext>
        ))
      )
    ),
    // O.chain((app) => (hasAccess ? O.some(app) : O.none)),
    O.getOrElse(() => <NoAccessPage />)
  )
}

type LobbyProps = OrganizationPageProps

export const Lobby = ({ children }: LobbyProps) => {
  const [, { getAccessTokenSilently }] = useAuth()
  const route = useRouter([
    {
      path: 'create-organization',
      element: <CreateOrganizationPage />
    },
    {
      path: ':organizationId/*',
      element: <OrganizationGuard>{children}</OrganizationGuard>
    },
    {
      path: '*',
      element: <SelectOrganizationPage />
    }
  ]) as ReactElement
  const getAccessToken = useCallback(
    () =>
      pipe(
        TO.tryCatch(() => getAccessTokenSilently()),
        TO.chain(TO.fromNullable)
      ),
    [getAccessTokenSilently]
  )

  return (
    <ApiClientProvider getAccessToken={getAccessToken} baseUrl={baseUrl}>
      <AccountProvider renderError={() => <AuthError />}>
        {route}
      </AccountProvider>
    </ApiClientProvider>
  )
}
