import React from 'react'
import { space, rem } from '@woorcs/utils'

import { system, PropsOf, forwardRef } from '../../../system'

import { isInvalid, isDisabled, isFocused } from './states'
import {
  InputVariant,
  isUnderlined,
  isOutlined,
  isFilled,
  isInline,
  isSmall,
  InputSize
} from './variants'

export interface InputState {
  focused?: boolean
  disabled?: boolean
  readOnly?: boolean
  invalid?: boolean
  error?: string
}

interface InputContainerProps extends InputState {
  size?: InputSize
  variant: InputVariant
}

export const underlined = isUnderlined(
  {
    px: space(3),
    minHeight: rem(42),
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderColor: 'grey.100',
    transition: 'border-color 0.3s ease-out'
  },
  isSmall({
    px: 2,
    minHeight: rem(32)
  }) as any
)

export const outlined = isOutlined(
  {
    minHeight: rem(42),
    alignItems: 'center',
    px: 3,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 'small',
    backgroundColor: 'white',
    borderColor: '#D7D7DB',
    transition: 'border-color 0.3s ease-out'
  },
  isSmall({
    px: 2,
    minHeight: rem(32)
  }) as any
)

export const filled = isFilled(
  {
    minHeight: rem(42),
    alignItems: 'center',
    px: 3,
    borderWidth: 1,
    backgroundColor: 'grey.50',
    borderStyle: 'solid',
    borderRadius: 'small',
    borderColor: 'grey.50',
    transition: 'border-color 0.3s ease-out'
  },
  isSmall({
    px: 2,
    minHeight: rem(32)
  }) as any,
  isFocused({
    bg: 'primary.100'
  }) as any
)

export const inline = isInline({
  display: 'inline-flex',
  border: 'none'
})

export const focused = isFocused({
  borderColor: 'primary.500',
  bg: '#F8F8FB'
})

export const invalid = isInvalid({
  color: 'danger',
  borderColor: 'currentColor'
})

export const invalidAndDisabled = isInvalid({
  borderColor: 'currentColor'
})

export const disabled = isDisabled({
  backgroundColor: 'grey.50',
  color: 'grey.400',
  borderColor: 'grey.100',
  cursor: 'not-allowed',
  '& > ::placeholder': {
    color: 'grey.300'
  }
})

const StyledGroup = system('div')<InputContainerProps>(
  {
    display: 'flex',
    position: 'relative',
    maxWidth: '100%',
    width: '100%',
    color: 'black',
    fontSize: 'base'
  },
  isSmall({
    fontSize: 'small'
  }),
  underlined,
  outlined,
  filled,
  inline,
  disabled,
  focused,
  invalid,
  invalidAndDisabled
)

interface InputGroupProps extends PropsOf<typeof system.div>, InputState {
  variant: InputVariant
}

export const InputGroup = forwardRef<InputGroupProps, typeof system.div>(
  (props, ref) => <StyledGroup ref={ref} {...props} />
)
