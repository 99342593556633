import { Eq } from 'fp-ts/Eq'
import { Decoder } from 'io-ts/lib/Decoder'
import { Guard } from 'io-ts/lib/Guard'

import { getDecoder, getGuard, getEq } from './instances'
import { Schema } from './schemable'

export interface Type<A> extends Decoder<unknown, A>, Guard<unknown, A>, Eq<A> {
  schema: Schema<A>
}

export const type = <A>(schema: Schema<A>): Type<A> => {
  return {
    schema,
    ...getDecoder(schema),
    ...getGuard(schema),
    ...getEq(schema)
  }
}

export type TypeOf<S> = S extends Type<infer A>
  ? A
  : S extends Schema<infer A>
  ? A
  : never
