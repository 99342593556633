import React from 'react'
import {
  Box,
  Flex,
  H3,
  PrimaryButton,
  SecondaryButton,
  Tab,
  TabList
} from '@woorcs/design-system'
import { Link } from 'react-router-dom'
import { PreviewFormModal } from '@woorcs/inspection-form-editor'

import { FormVersionBadge, ListPageHeader } from '@app/components'

import { FormDetailsPageFormFragment } from './__generated__/FormDetails'

interface FormDetailsPageHeaderrProps {
  form: FormDetailsPageFormFragment
}

export const FormDetailsPageHeader = ({
  form
}: FormDetailsPageHeaderrProps) => (
  <ListPageHeader borderBottom='base' mb={0} pb={0}>
    <Box width='100%'>
      <Flex justifyContent='space-between' mb={8} width='100%'>
        <Box>
          <H3 mb={3}>{form.title}</H3>
          <FormVersionBadge form={form} />
        </Box>

        <Flex alignItems='center'>
          <PreviewFormModal
            backButtonLabel='Back'
            definition={form.latestRevision.definition}
          >
            <SecondaryButton mr={4}>Preview</SecondaryButton>
          </PreviewFormModal>

          <PrimaryButton as={Link} to={`/forms/${form.id}/edit`}>
            Edit
          </PrimaryButton>
        </Flex>
      </Flex>

      <TabList size='large' marginBottom='-1px'>
        <Tab id='pages'>Submissions</Tab>
        <Tab id='revisions'>Revisions</Tab>
        <Tab id='languages'>Languages</Tab>
      </TabList>
    </Box>
  </ListPageHeader>
)
