import React, { useCallback, useRef } from 'react'
import {
  Composite,
  CompositeGroup,
  CompositeItem,
  useCompositeState
} from 'reakit/Composite'
import { useId } from '@woorcs/hooks'
import { palette } from '@woorcs/design-tokens'
import { forkRefs } from '@woorcs/utils'

import { Flex } from '../../layout'
import { Input, InputProps } from '../Input'
import { PopoverContent, PopoverProvider, usePopover } from '../../overlays'
import { Swatch } from '../../data'

type ColorInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  defaultColors?: string[]
  value?: string
  onChange?(value: string): void
}

const defaultColorPalette = [
  palette.red['500'],
  palette.red['300'],
  palette.yellow['300'],
  palette.yellow['500'],
  palette.secondary['300'],
  palette.secondary['500'],
  palette.primary['300'],
  palette.primary['500']
]

export const ColorInput = ({
  value,
  defaultColors = defaultColorPalette,
  onChange,
  ...other
}: ColorInputProps) => {
  const inputId = useId()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const context = usePopover({
    placement: 'bottom-start',
    gutter: 8,
    autoFocusOnShow: false,
    autoFocusOnHide: false
  })
  const composite = useCompositeState({
    currentId: inputId
  })
  const disclosureRef = forkRefs(
    context.state.unstable_disclosureRef,
    context.state.unstable_referenceRef
  ) as any // TODO: Fix forkRefs return

  const handleFocus = useCallback(() => {
    context.state.show()
  }, [context.state])

  const handleChange = useCallback(
    (e) => {
      onChange?.(e.target.value)
    },
    [onChange]
  )

  return (
    <PopoverProvider value={context}>
      <Composite {...composite}>
        <Input
          ref={inputRef}
          value={value}
          containerRef={disclosureRef}
          id={inputId}
          autoComplete='off'
          leftAddon={<Swatch color={value} ml={-1} />}
          onChange={handleChange}
          onFocus={handleFocus}
          {...other}
        />

        <PopoverContent p={2} tabIndex={-1}>
          <CompositeGroup {...composite} role='grid' aria-label='Colors'>
            {(props) => (
              <Flex flexDirection='row' {...props}>
                {defaultColors.map((color) => (
                  <CompositeItem key={color} {...composite}>
                    {(props) => (
                      <Swatch
                        color={color}
                        {...props}
                        css={{
                          cursor: 'pointer'
                        }}
                        display='block'
                        mr={1}
                        onClick={() => {
                          onChange?.(color)
                          context.state.hide()
                        }}
                      />
                    )}
                  </CompositeItem>
                ))}
              </Flex>
            )}
          </CompositeGroup>
        </PopoverContent>
      </Composite>
    </PopoverProvider>
  )
}
