import React from 'react'
import { Table, TableBody } from '@woorcs/design-system'

import { SubmissionRevisionListItem } from './SubmissionRevisionListItem'
import { SubmissionRevisionListItemSubmissionRevisionFragment } from './__generated__/SubmissionRevisionListFragment'

interface RevisionListProps {
  submissionId: string
  revisions: SubmissionRevisionListItemSubmissionRevisionFragment[]
}

export const SubmissionRevisionList = ({
  submissionId,
  revisions
}: RevisionListProps) => {
  return (
    <Table>
      <TableBody>
        {revisions.map((revision) => (
          <SubmissionRevisionListItem
            key={revision.id}
            submissionId={submissionId}
            revision={revision}
          />
        ))}
      </TableBody>
    </Table>
  )
}
