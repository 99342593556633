import { UpdateResolver, Cache } from '@urql/exchange-graphcache'
import { constant, pipe, constNull } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as IOO from 'fp-ts-contrib/IOOption'
import * as Optional from 'monocle-ts/Optional'
import {
  AddUserToOrganizationSuccess,
  CreateTagSuccess,
  FormRevisionStatus,
  PublishFormRevisionSuccess
} from '@woorcs/graphql/schema'
import {
  OrganizationFragmentDoc,
  OrganizationQuery,
  OrganizationQueryDocument
} from '@woorcs/graphql/src/react/__generated__/organization'
import { Endomorphism } from 'fp-ts/Endomorphism'

import {
  PublishFormRevisionMutation,
  PublishFormRevisionMutationVariables
} from '@app/components/EditForm/__generated__/EditForm'
import {
  AddTagMutation,
  AddTagMutationVariables
} from '@app/components/AddTagModal/__generated__/AddTagModal'
import {
  DeleteTagMutation,
  DeleteTagMutationVariables,
  TagListQuery,
  TagListQueryDocument
} from '@app/components/TagsList/__generated__/TagsList'
import {
  AddUserToOrganizationMutation,
  AddUserToOrganizationMutationVariables
} from '@app/components/AddUserModal/__generated__/AddUserModal'
import { userListQueryResponseOptional } from '@app/components'
import { UserListQueryDocument } from '@app/components/UserList/__generated__/UserListQueries'
import { UserListUserFragment } from '@app/components/UserList/__generated__/UserListFragments'
import {
  RemoveUsersFromOrganizationMutation,
  RemoveUsersFromOrganizationMutationVariables
} from '@app/pages/Users/__generated__/UsersPage'
import {
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
} from '@app/components/EditUserModal/__generated__/EditUserModal'

import { FormFragmentDoc } from './__generated__/mutations'

const organizationOptional = pipe(
  Optional.id<OrganizationQuery>(),
  Optional.prop('organization'),
  Optional.fromNullable
)

const getCachedOrganization = (cache: Cache) =>
  pipe(
    cache.readQuery({ query: OrganizationQueryDocument }),
    O.fromNullable,
    O.chain(organizationOptional.getOption)
  )

const publishFormRevisionResultOptional = pipe(
  Optional.id<PublishFormRevisionMutation>(),
  Optional.prop('publishFormRevision'),
  Optional.fromNullable,
  Optional.filter(
    (result): result is PublishFormRevisionSuccess =>
      result.__typename === 'PublishFormRevisionSuccess'
  )
)

const publishFormRevision: UpdateResolver<
  PublishFormRevisionMutation,
  PublishFormRevisionMutationVariables
> = (result, variables, cache) => {
  const updateCache = pipe(
    publishFormRevisionResultOptional.getOption(result),
    O.chain(({ form }) =>
      pipe(cache.readFragment(FormFragmentDoc, form.id), O.fromNullable)
    ),
    IOO.fromOption,
    IOO.map((existingForm) =>
      cache.writeFragment(FormFragmentDoc, {
        ...existingForm,
        revisions: existingForm.revisions.map((revision) => {
          if (revision.id !== variables.input.formRevisionId) {
            return revision
          }

          return {
            ...revision,
            status: FormRevisionStatus.Published,
            publishedAt: Date.now()
          }
        })
      })
    )
  )

  updateCache()
}

const createTagResultOptional = pipe(
  Optional.id<AddTagMutation>(),
  Optional.prop('createTag'),
  Optional.fromNullable,
  Optional.filter(
    (result): result is CreateTagSuccess =>
      result.__typename === 'CreateTagSuccess'
  )
)

const tagListResultOptional = pipe(
  Optional.id<TagListQuery>(),
  Optional.prop('organization'),
  Optional.prop('tags')
)

const getCachedTags = (cache: Cache) =>
  pipe(
    cache.readQuery({ query: TagListQueryDocument }),
    O.fromNullable,
    O.chain(tagListResultOptional.getOption)
  )

const createTag: UpdateResolver<AddTagMutation, AddTagMutationVariables> = (
  result,
  _,
  cache
) => {
  const updateCache = pipe(
    O.Do,
    O.apS('result', createTagResultOptional.getOption(result)),
    O.apS('organization', getCachedOrganization(cache)),
    IOO.fromOption,
    IOO.map(({ result, organization }) => {
      const tags = pipe(getCachedTags(cache), O.getOrElseW(constant([])))

      cache.writeFragment(OrganizationFragmentDoc, {
        ...organization,
        tags: [result.tag, ...tags]
      })
    })
  )

  updateCache()
}

const deleteTag: UpdateResolver<DeleteTagMutation, DeleteTagMutationVariables> =
  (_, { id }, cache) => {
    const updateCache = pipe(
      O.Do,
      O.apS('organization', getCachedOrganization(cache)),
      O.apS(
        'tags',
        pipe(
          getCachedTags(cache),
          O.map((tags) => tags.filter((t) => t.id !== id))
        )
      ),
      IOO.fromOption,
      IOO.map(({ organization, tags }) => {
        cache.writeFragment(OrganizationFragmentDoc, {
          ...organization,
          tags
        })
      })
    )

    updateCache()
  }

const addUserToOrganizationResultOptional = pipe(
  Optional.id<AddUserToOrganizationMutation>(),
  Optional.prop('addUserToOrganization'),
  Optional.fromNullable,
  Optional.filter(
    (result): result is AddUserToOrganizationSuccess =>
      result.__typename === 'AddUserToOrganizationSuccess'
  )
)

const userListResponseDataOptional = pipe(
  userListQueryResponseOptional,
  Optional.prop('data')
)

const updateUserListResponseData = (
  update: Endomorphism<UserListUserFragment[]>
) => pipe(userListResponseDataOptional, Optional.modify(update))

const appendUser = (user: UserListUserFragment) =>
  pipe(updateUserListResponseData((data) => pipe(data, A.append(user))))

const addUserToOrganization: UpdateResolver<
  AddUserToOrganizationMutation,
  AddUserToOrganizationMutationVariables
> = (result, _, cache) => {
  const updateCache = pipe(
    result,
    O.fromNullable,
    O.chain(addUserToOrganizationResultOptional.getOption),
    IOO.fromOption,
    IOO.chain((result) => () => {
      cache.updateQuery({ query: UserListQueryDocument }, (userList) =>
        pipe(
          O.fromNullable(userList),
          O.map(appendUser(result.user)),
          O.getOrElseW(constNull)
        )
      )

      return O.none
    })
  )

  updateCache()
}

const removeUsersFromOrganization: UpdateResolver<
  RemoveUsersFromOrganizationMutation,
  { input: RemoveUsersFromOrganizationMutationVariables }
  // eslint-disable-next-line max-params
> = (_, variables, cache) => {
  const { userIds } = variables.input
  if (Array.isArray(userIds)) {
    userIds.forEach((id) => cache.invalidate({ __typename: 'User', id }))
  } else {
    cache.invalidate({ __typename: 'User', id: userIds })
  }
}

const updateUser: UpdateResolver<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
> = (_, { input }, cache) => {
  const { id } = input

  cache.invalidate({ __typename: 'User', id })
}

export const updates = {
  Mutation: {
    publishFormRevision,
    createTag,
    deleteTag,
    addUserToOrganization,
    removeUsersFromOrganization,
    updateUser
  },
  Subscription: {}
}
