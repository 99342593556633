import {
  Checkbox,
  TableCell,
  TableCheckboxCell,
  TableRow,
  Badge
} from '@woorcs/design-system'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@app/auth'

import { TagBadge } from '../../TagBadge'
import { UserListUserFragment } from '../__generated__/UserListFragments'

import { UserRowMenu } from './Menu'

interface UserRowProps {
  user: UserListUserFragment
  isSelected: boolean
  onSelect(id: string): void
  onRemove(id: string): void
  onDisable(user: UserListUserFragment): void
}

export const UserRow = ({
  user,
  isSelected,
  onSelect,
  onRemove,
  onDisable
}: UserRowProps) => {
  const { t } = useTranslation()
  const [authState] = useAuth()
  const isViewer = authState.user?.id === user.id

  const handleRemoveUser = useCallback(() => {
    onRemove(user.id)
  }, [onRemove, user.id])

  const handleDisableUser = useCallback(() => {
    onDisable(user)
  }, [onDisable, user])

  return (
    <TableRow>
      <TableCheckboxCell>
        <Checkbox
          disabled={isViewer}
          checked={isSelected}
          onChange={() => onSelect(user.id)}
        />
      </TableCheckboxCell>
      <TableCell>{user.name}</TableCell>
      <TableCell />
      <TableCell>
        {user.role && <Badge mr={2}>{t(`roles:${user.role}` as const)}</Badge>}
      </TableCell>
      <TableCell>
        {user.tags.map((tag) => (
          <TagBadge key={tag.id} mr={2} mb={2} tag={tag}>
            {tag.name}
          </TagBadge>
        ))}
      </TableCell>
      {/* <TableCell>{user.disabled ? t('common:no') : t('common:yes')}</TableCell> */}
      <TableCell textAlign='right'>
        <UserRowMenu
          user={user}
          isViewer={isViewer}
          onDisable={handleDisableUser}
          onRemove={handleRemoveUser}
        />
      </TableCell>
    </TableRow>
  )
}
