import React from 'react'

import { IconButton, IconButtonProps } from '../IconButton'
import { CloseIcon } from '../../icons'
import { forwardRef } from '../../../system'

export type CloseButtonProps = Omit<IconButtonProps, 'children'>

export const CloseButton = forwardRef<CloseButtonProps, 'button'>(
  (props, ref) => (
    <IconButton ref={ref} aria-label='close' variant='plain' {...props}>
      <CloseIcon color='currentColor' />
    </IconButton>
  )
)
