import React, { Suspense } from 'react'
import {
  FilterButton,
  PlusIcon,
  FilterButtonLabel,
  Menu,
  MenuContent,
  MenuItem,
  MenuButton,
  Spinner
} from '@woorcs/design-system'
import * as R from 'fp-ts/Record'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as NEA from 'fp-ts/NonEmptyArray'
import { constNull, pipe } from 'fp-ts/function'

import { useFiltersContext } from '../../context'
import { FilterConfig } from '../../types'
import { SelectUserModal } from '../../../SelectUserModal'
import { SelectTagsModal } from '../../../SelectTagsModal'
import { SelectFormModal } from '../../../SelectFormModal'
import { DateRangeFilterMenu } from '../../../DateRangeFilter'

interface FilterMenuItemProps {
  filter: FilterConfig
  onAdd(value: unknown): void
}

const FilterMenuItem = ({ filter, onAdd }: FilterMenuItemProps) => {
  switch (filter._tag) {
    case 'user': {
      return (
        <SelectUserModal
          selectedId={O.none}
          searchPlaceholder='Filter by submitter'
          onSelect={(user) => onAdd(user.id)}
        >
          <MenuItem>{filter.label}</MenuItem>
        </SelectUserModal>
      )
    }

    case 'tag': {
      return (
        <SelectTagsModal onSelect={onAdd}>
          <MenuItem>{filter.label}</MenuItem>
        </SelectTagsModal>
      )
    }

    case 'form': {
      return (
        <SelectFormModal
          selectedId={O.none}
          onSelect={(form) => onAdd(form.id)}
        >
          <MenuItem>{filter.label}</MenuItem>
        </SelectFormModal>
      )
    }

    case 'date-range': {
      return (
        <DateRangeFilterMenu placement={'right-start'} onChange={onAdd}>
          <MenuItem>{filter.label}</MenuItem>
        </DateRangeFilterMenu>
      )
    }
  }

  return <MenuItem onClick={onAdd}>{filter.label}</MenuItem>
}

export const FilterMenu = () => {
  const { availableFilters, filters, setFilter } = useFiltersContext()

  return pipe(
    availableFilters,
    R.filterWithIndex((key) => O.isNone(filters[key])),
    R.toArray,
    NEA.fromArray,
    O.fold(constNull, (availableFilters) => (
      <Menu>
        <MenuButton>
          <FilterButton mr={4}>
            <FilterButtonLabel>
              <PlusIcon size='small' mr={2} />
              Add filter
            </FilterButtonLabel>
          </FilterButton>
        </MenuButton>
        <MenuContent aria-label='filters'>
          <Suspense fallback={null}>
            {pipe(
              availableFilters,
              A.map(([key, filter]) => (
                <FilterMenuItem
                  key={key}
                  filter={filter}
                  onAdd={(value) => setFilter(key, O.some(value))}
                />
              ))
            )}
          </Suspense>
        </MenuContent>
      </Menu>
    ))
  )
}
