import React from 'react'

import { system, PropsOf, forwardRef } from '../../../system'
import { IconButton } from '../../buttons'
import { InfoIcon } from '../../icons'
import { Flex } from '../../layout'
import { Tooltip } from '../../overlays'

import { useFormFieldLabel } from './useFormField'

const Section = system('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomColor: 'grey.50',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid'
})

const Label = system('label')({
  display: 'block',
  fontSize: 'small',
  fontWeight: 'bold',
  lineHeight: 1.25,
  color: 'grey.900',
  cursor: 'pointer',
  WebkitTapHighlightColor: 'transparent'
})

const RequiredIndicator = system('span')({
  color: 'danger',
  fontWeight: 'bold'
})

interface FormFieldLabelProps extends PropsOf<typeof system.label> {
  required?: boolean
  informativeText?: string | null
}

export const FormFieldLabel = forwardRef<
  FormFieldLabelProps,
  typeof system.label
>(({ children, informativeText, ...other }: FormFieldLabelProps, ref) => {
  const { required, ...props } = useFormFieldLabel(other)

  return (
    <Section>
      <Flex alignItems='center'>
        <Label ref={ref} mb={2} {...props}>
          {children}

          {required && (
            <RequiredIndicator role='presentation' aria-hidden>
              *
            </RequiredIndicator>
          )}
        </Label>
      </Flex>
      {informativeText && (
        <Tooltip title={informativeText}>
          <IconButton size='small' as='span'>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )}
    </Section>
  )
})
