import React, { memo, useCallback } from 'react'
import { focusOutlineColor, Box } from '@woorcs/design-system'
import { palette } from '@woorcs/design-tokens'
import { FormElement } from '@woorcs/form'
import { Path } from '@woorcs/types/Path'
import { UUID } from '@woorcs/types/UUID'
import * as O from 'fp-ts/Option'
import { constFalse, pipe } from 'fp-ts/function'

import { ElementActionMenu } from '../../ElementActionMenu'

import { useSortableElementListItem } from './useSortableElementListItem'
import { ElementListItem } from './ListItem'

export interface SortableElementListItemProps {
  element: FormElement.FormElementType
  isAnimating: boolean
  isSelected?: boolean
  path: Path
  onClick(questionID: UUID): void
  onEdit(questionID: UUID): void
}

const animations = {
  initial: {
    backgroundColor: 'white'
  },
  dragging: {
    backgroundColor: palette.primary['50'],
    height: 120
  }
}

export const SortableElementListItem = memo(
  ({
    element,
    isSelected = false,
    isAnimating,
    path,
    onClick,
    onEdit
  }: SortableElementListItemProps) => {
    const [dragRef, { isDragging, isDraggingOther, isDraggingOtherId }] =
      useSortableElementListItem({
        element,
        path,
        isAnimating
      })

    const isDragged =
      isDragging ||
      pipe(
        isDraggingOtherId,
        O.fold(constFalse, (id) => id === element.id)
      )

    // const otherDrag = pipe(
    //   isDraggingOtherId,
    //   O.fold(constFalse, (id) => id !== element.id)
    // )

    const handleClick = useCallback(() => {
      onClick(element.id)
    }, [element.id, onClick])

    const hoverStyle = () => {
      if (isDraggingOther) {
        return {}
      }

      return {
        transition: 'transform 0.3s ease-out',
        ':hover': {
          boxShadow: `0 0 2px 3px ${focusOutlineColor}, 0 0 0 1px ${palette.primary[500]};`,
          transform: 'scale(1.005)'
        }
      }
    }

    const getAnimationVariant = () => {
      if (isDragged) {
        return 'dragging'
      }

      return 'initial'
    }

    return (
      <ElementActionMenu
        element={element}
        path={path}
        placement='top-start'
        disabled={isDragging || isDraggingOther}
        onEdit={onEdit}
      >
        <Box>
          <ElementListItem
            ref={dragRef}
            key={element.id}
            element={element}
            css={{
              cursor: 'pointer',
              ':focus': { outline: 'none' },
              bg: isDragged ? 'primary.50' : 'white',
              // pointerEvents: otherDrag ? 'none' : 'auto',
              borderRadius: 'medium',
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'transparent',
              '> *': {
                opacity: isDragged ? 0 : 1
              },
              willChange: 'boxShadow, transform',
              ...hoverStyle(),
              ...(isSelected && !(isDragging || isDraggingOther)
                ? {
                    boxShadow: `0 0 2px 3px ${focusOutlineColor}, 0 0 0 1px ${palette.primary[500]};`,
                    transform: 'scale(1.005)'
                  }
                : {})
            }}
            initial='initial'
            // css={css({
            //   ...hoverStyle(),
            //   bg: isDragged ? 'primary.50' : 'white',
            //   // borderColor: isDragged ? 'transparent' : 'grey.50',
            //   ':focus, :active': {
            //     outline: 'none',
            //     ...hoverStyle()
            //   },
            //   '> *': {
            //     opacity: isDragged ? 0 : 1
            //   },
            //   ...(selected
            //     ? {
            //         boxShadow: `0 0 2px 3px ${focusOutlineColor}, 0 0 0 1px ${palette.primary[500]};`,
            //         transform: 'scale(1.005)'
            //       }
            //     : {})
            // })}
            variants={animations}
            animate={getAnimationVariant()}
            whileHover={{
              // scale: 1.005,
              borderColor: isDragged ? 'transparent' : palette.primary[500]
              // shadow: `0 0 2px 3px ${focusOutlineColor}, 0 0 0 1px ${palette.primary[500]};`
            }}
            whileTap={{ scale: 0.98 }}
            layout
            onClick={handleClick}
          />
        </Box>
      </ElementActionMenu>
    )
  }
)
