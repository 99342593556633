import React, { ReactElement, cloneElement, forwardRef } from 'react'
import { PopoverDisclosure as BasePopoverDisclosure } from 'reakit/Popover'

import { usePopoverDisclosure, UsePopoverDisclosure } from './usePopover'

interface PopoverDisclosureProps extends UsePopoverDisclosure {
  children: ReactElement
}

export const PopoverDisclosure = forwardRef<
  HTMLButtonElement,
  PopoverDisclosureProps
>(({ children, ...props }, ref) => {
  const state = usePopoverDisclosure(props)

  return (
    <BasePopoverDisclosure {...state} ref={ref}>
      {(disclosureProps) => cloneElement(children, disclosureProps)}
    </BasePopoverDisclosure>
  )
})
