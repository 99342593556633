import React, { ReactNode, useCallback } from 'react'
import { DialogSidebar } from '@woorcs/design-system'
import { useNavigate } from 'react-router'
import { Location } from 'history'

export interface SidebarRouteProps {
  prevLocation: Location
  children: ReactNode
}

export const SidebarRoute = ({ prevLocation, children }: SidebarRouteProps) => {
  const navigate = useNavigate()

  const handleVisibilityChange = useCallback(
    (visible) => {
      if (!visible) {
        navigate(prevLocation.pathname, { replace: true })
      }
    },
    [navigate, prevLocation.pathname]
  )

  return (
    <DialogSidebar
      placement='right'
      defaultVisible
      onVisiblityChange={handleVisibilityChange}
    >
      {children}
    </DialogSidebar>
  )
}
