import {
  IconButton,
  Menu,
  MenuButton,
  MenuContent,
  MenuItem,
  MoreIcon,
  ConfirmAlertDialog
} from '@woorcs/design-system'
import React, { ReactElement, useState, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { EditUserModal } from '../../EditUserModal'
import { UserListUserFragment } from '../__generated__/UserListFragments'

type ConfirmRemoveAlertProps = {
  user: UserListUserFragment
  onConfirm(): void
  children: ReactElement
}

type ConfirmDisableAlertProps = {
  user: UserListUserFragment
  onConfirm(): void
  children: ReactElement
  disabled: boolean
  confirmLabel: string
}

const ConfirmRemoveAlert = memo(
  ({ user, onConfirm, children }: ConfirmRemoveAlertProps) => {
    return (
      <ConfirmAlertDialog
        title={`Ta bort \`${user.firstName} ${user.lastName}\``}
        body='Är du säker på att du vill ta bort den här användaren?'
        confirmLabel='Ta bort'
        onConfirm={onConfirm}
      >
        {children}
      </ConfirmAlertDialog>
    )
  }
)

const ConfirmDisableAlert = memo(
  ({
    user,
    onConfirm,
    children,
    disabled,
    confirmLabel
  }: ConfirmDisableAlertProps) => {
    const { t } = useTranslation()
    const title = disabled
      ? t('users:userList.confirmActivateDialog.title', {
          name: `${user.firstName} ${user.lastName}`
        })
      : t('users:userList.confirmDisableDialog.title', {
          name: `${user.firstName} ${user.lastName}`
        })
    const body = disabled
      ? t('users:userList.confirmActivateDialog.body')
      : t('users:userList.confirmDisableDialog.body')
    //'users:userList.confirmDeleteDialog.title'
    return (
      <ConfirmAlertDialog
        title={title}
        body={body}
        confirmLabel={confirmLabel}
        onConfirm={onConfirm}
      >
        {children}
      </ConfirmAlertDialog>
    )
  }
)

type UserRowMenuProps = {
  user: UserListUserFragment
  isViewer: boolean
  onRemove(): void
  onDisable(): void
}

export const UserRowMenu = memo(
  ({ user, isViewer, onRemove }: UserRowMenuProps) => {
    const { t } = useTranslation()
    const [visible, setVisible] = useState(false)
    // const disableTitle = user.disabled
    //   ? t('common:activate')
    //   : t('common:disable')

    return (
      <Menu
        placement='bottom-end'
        visible={visible}
        onVisiblityChange={setVisible}
      >
        <MenuButton>
          <IconButton color='primary' display='inline-flex'>
            <MoreIcon />
          </IconButton>
        </MenuButton>

        <MenuContent aria-label='User menu'>
          <EditUserModal key={user.id} user={user}>
            <MenuItem>{t('common:edit')}</MenuItem>
          </EditUserModal>
          {!isViewer && (
            <>
              {/* <ConfirmDisableAlert
                disabled={user.disabled}
                user={user}
                confirmLabel={disableTitle}
                onConfirm={onDisable}
              >
                <MenuItem>{disableTitle}</MenuItem>
              </ConfirmDisableAlert> */}
              <ConfirmRemoveAlert user={user} onConfirm={onRemove}>
                <MenuItem destructive>{t('common:remove')}</MenuItem>
              </ConfirmRemoveAlert>
            </>
          )}
        </MenuContent>
      </Menu>
    )
  }
)
