import React from 'react'
import { useDragLayer, XYCoord } from 'react-dnd'
import { Box } from '@woorcs/design-system'

import {
  PageListItemDragItem,
  PAGE_LIST_ITEM_DRAG_TYPE
} from '../PageList/Page/DragItem'
import { PreviewPageListItem } from '../PageList'
import {
  PreviewElementListItem,
  ElementListItemDragItem,
  ELEMENT_LIST_ITEM_DRAG_TYPE
} from '../ElementList/ListItem'

function getItemStyles(
  initialOffset: XYCoord | null,
  currentOffset: XYCoord | null
) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none'
    }
  }

  const { x, y } = currentOffset
  const transform = `translate3d(${x}px, ${y}px, 0)`

  return {
    transform,
    WebkitTransform: transform
  }
}

type DragLayerType =
  | typeof PAGE_LIST_ITEM_DRAG_TYPE
  | typeof ELEMENT_LIST_ITEM_DRAG_TYPE

type DragLayerItem = PageListItemDragItem | ElementListItemDragItem

export const EditorDragLayer = () => {
  const { item, itemType, isDragging, currentOffset, initialOffset } =
    useDragLayer((monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging()
    }))

  function renderItem(type: DragLayerType, item: DragLayerItem) {
    switch (type) {
      case PAGE_LIST_ITEM_DRAG_TYPE: {
        const dragItem = item as PageListItemDragItem

        return <PreviewPageListItem page={dragItem.page} rect={dragItem.rect} />
      }

      case ELEMENT_LIST_ITEM_DRAG_TYPE: {
        const dragItem = item as ElementListItemDragItem

        return (
          <PreviewElementListItem
            element={dragItem.element}
            rect={dragItem.rect}
          />
        )
      }
    }
  }

  if (!isDragging) {
    return null
  }

  return (
    <Box
      css={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 2000,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        cursor: 'grabbing'
      }}
    >
      <div style={getItemStyles(initialOffset, currentOffset)}>
        {renderItem(itemType as DragLayerType, item)}
      </div>
    </Box>
  )
}
