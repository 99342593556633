import React, { ReactNode, AriaAttributes } from 'react'
import { splitObject, space, whenTrue } from '@woorcs/utils'

import { PlainLink } from '../../PlainLink'
import { PlainButton } from '../../buttons'
import { system, css, PropsOf, forwardRef } from '../../../system'
import { BoxProps } from '../../layout'

const HEIGHT = space(8)

const isActive = whenTrue(['active'])

export const MenuListItemContainer = system('li')({
  height: HEIGHT
})

const linkStyle = [
  css({
    display: 'inline-flex',
    alignItems: 'center',
    height: HEIGHT,
    px: 3,
    // fontSize: 'small',
    fontWeight: 'medium',
    textAlign: 'left',
    color: 'black',
    cursor: 'pointer',
    borderRadius: 'small',
    whiteSpace: 'nowrap',
    ':hover': {
      color: 'primary.500'
    },
    ':focus': {
      color: 'primary.500',
      backgroundColor: 'primary.50'
    },
    ':active': {
      color: 'primary.500',
      backgroundColor: 'primary.50'
    }
  }),
  isActive({
    color: 'primary.500',
    backgroundColor: 'primary.50'
  })
]

export const MenuListLink = system(PlainLink)(
  ...linkStyle,
  css({
    '&[aria-current="page"]': {
      color: 'primary.500',
      backgroundColor: 'primary.50'
    }
  })
)

export const MenuListButton = system(PlainButton)(...linkStyle)

interface LinkProps extends Pick<BoxProps, 'fontSize' | 'color'> {
  to?: string
  href?: string
  target?: string
  active?: boolean
  'aria-current'?: AriaAttributes['aria-current']
}

export interface MenuListItemProps
  extends LinkProps,
    PropsOf<typeof MenuListItemContainer> {
  active?: boolean
  children: ReactNode
}

const getLinkProps = (props: LinkProps) =>
  splitObject(props, [
    'href',
    'to',
    'target',
    'active',
    'aria-current',
    'fontSize',
    'color'
  ])

export const MenuListItem = forwardRef<MenuListItemProps, 'li'>(
  ({ children, ...other }, ref) => {
    if (typeof children === 'string') {
      const [linkProps, props] = getLinkProps(other)

      return (
        <MenuListItemContainer {...props}>
          <MenuListLink ref={ref} {...linkProps}>
            {children}
          </MenuListLink>
        </MenuListItemContainer>
      )
    }

    return (
      <MenuListItemContainer ref={ref} {...other}>
        {children}
      </MenuListItemContainer>
    )
  }
)
