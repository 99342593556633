import {
  useDayzed,
  Props as UseDayzedProps,
  DateObj,
  Calendar as DayzedCalendar
} from 'dayzed'
import { useState, useCallback } from 'react'
import { isArray } from '@woorcs/utils'
import { useControllableProp } from '@woorcs/hooks'
import * as O from 'fp-ts/Option'
import * as boolean from 'fp-ts/boolean'
import * as Tuple from 'fp-ts/Tuple'
import { pipe, constFalse } from 'fp-ts/function'

import * as DateRangeTuple from './DateRangeTuple'

export type CalendarDate = DateObj

export interface Calendar extends Omit<DayzedCalendar, 'weeks'> {
  weeks: CalendarDate[][]
}

interface UseCalendarBaseProps extends Omit<UseDayzedProps, 'onDateSelected'> {
  range?: boolean
}

type CalendarValue = DateRangeTuple.DateRangeTuple | O.Option<Date>

interface UseSingleCalendarProps extends UseCalendarBaseProps {
  range?: false
  value?: O.Option<Date>
  defaultValue?: O.Option<Date>
  onChange?(date: O.Option<Date>): void
}

interface UseRangeCalendarProps extends UseCalendarBaseProps {
  range: true
  defaultValue?: DateRangeTuple.DateRangeTuple
  value?: DateRangeTuple.DateRangeTuple
  onChange?(date: DateRangeTuple.DateRangeTuple): void
}

export type UseCalendarStateProps =
  | UseSingleCalendarProps
  | UseRangeCalendarProps

interface UseCalendarStateBaseReturn {
  range: boolean
  calendars: Calendar[]
  getDateProps(date: CalendarDate): Record<string, any>
  getBackProps(offset?: number): Record<string, any>
  getForwardProps(offset?: number): Record<string, any>
  setHoveredDate(date: O.Option<Date>): void
  isInRange(date: Date): boolean
}

interface UseCalendarStateRangeReturn extends UseCalendarStateBaseReturn {
  range: true
  currentDate: DateRangeTuple.DateRangeTuple
}

interface UseCalendarStateSingleReturn extends UseCalendarStateBaseReturn {
  range: false
  currentDate: O.Option<Date>
}

export type UseCalendarStateReturn =
  | UseCalendarStateRangeReturn
  | UseCalendarStateSingleReturn

export const useCalendarState = ({
  range = false,
  value,
  onChange,
  defaultValue = range ? DateRangeTuple.empty : O.some(new Date()),
  ...props
}: UseCalendarStateProps): UseCalendarStateReturn => {
  const [currentDateState, setCurrentDate] =
    useState<CalendarValue>(defaultValue)
  const [isControlled, currentDate] = useControllableProp(
    value,
    currentDateState
  )
  const [hoveredDate, setHoveredDate] = useState<O.Option<Date>>(O.none)

  const handleChange = useCallback(
    (value) => {
      if (!isControlled) {
        setCurrentDate(value)
      }

      onChange?.(value)
    },
    [isControlled, onChange]
  )

  const handleRangeChange = useCallback(
    ({ date, selectable }: CalendarDate) => {
      if (!selectable || !DateRangeTuple.Guard.is(currentDate)) {
        return
      }

      const dateTime = date.getTime()
      const [start, end] = currentDate as DateRangeTuple.DateRangeTuple

      const value: DateRangeTuple.DateRangeTuple = pipe(
        start,
        O.chain((start) =>
          pipe(
            end,
            O.map(() => DateRangeTuple.dateRangeTuple(O.some(date), O.none)),
            O.alt(() => {
              const startTime = start.getTime()

              if (startTime < dateTime) {
                return O.some(
                  DateRangeTuple.dateRangeTuple(O.some(start), O.some(date))
                )
              }

              return O.some(
                DateRangeTuple.dateRangeTuple(O.some(date), O.some(start))
              )
            })
          )
        ),
        // O.alt(() => O.some([O.some(date), O.none]))
        O.getOrElse(() => DateRangeTuple.dateRangeTuple(O.some(date), O.none))
      )

      handleChange(value)
    },
    [currentDate, handleChange]
  )

  const getRange = () => {
    if (!DateRangeTuple.Guard.is(currentDate)) {
      return O.toNullable(currentDate as O.Option<Date>)
    }

    const [start, end] = currentDate as DateRangeTuple.DateRangeTuple

    return [O.toNullable(start), O.toNullable(end)]
  }

  const { calendars, ...calendarState } = useDayzed({
    showOutsideDays: true,
    selected: getRange() as any, //pipe(DateRangeTuple.is(currentDate), boolean.fold()),
    onDateSelected: (date) => {
      if (!range) {
        handleChange(O.fromNullable(date.date))

        return
      }

      handleRangeChange(date)
    },
    ...props
  })

  const isInRange = useCallback(
    (date: Date): boolean => {
      if (!range || !DateRangeTuple.Guard.is(currentDate)) {
        return false
      }

      const [start, end] = currentDate as DateRangeTuple.DateRangeTuple

      return pipe(
        start,
        O.chain((start) => {
          const time = date.getTime()
          const firstSelected = start.getTime()

          return pipe(
            end,
            O.chain((end) => {
              const secondSelected = end.getTime()

              return firstSelected < time && secondSelected > time
                ? O.some(true)
                : O.none
            }),
            O.alt(() =>
              pipe(
                hoveredDate,
                O.chain((hoveredDate) => {
                  const hoveredTime = hoveredDate?.getTime()

                  return (firstSelected < time && hoveredTime >= time) ||
                    (time < firstSelected && time >= hoveredTime)
                    ? O.some(true)
                    : O.none
                })
              )
            )
          )
        }),
        O.getOrElseW(constFalse)
      )
    },
    [currentDate, hoveredDate, range]
  )

  const getDateProps = useCallback(
    (date: CalendarDate) =>
      calendarState.getDateProps({
        dateObj: date
      }),
    [calendarState]
  )

  const getBackProps = useCallback(
    (offset?: number) =>
      calendarState.getBackProps({
        calendars,
        offset
      }),
    [calendarState, calendars]
  )

  const getForwardProps = useCallback(
    (offset?: number) =>
      calendarState.getForwardProps({
        calendars,
        offset
      }),
    [calendarState, calendars]
  )

  return {
    currentDate,
    range,
    calendars: calendars as Calendar[],
    getDateProps,
    getBackProps,
    getForwardProps,
    setHoveredDate,
    isInRange
  } as UseCalendarStateReturn
}
