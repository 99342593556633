import React, { ReactElement, ReactNode, useMemo } from 'react'
import { createContext } from '@woorcs/utils'
import * as Optional from 'monocle-ts/Optional'
import { constNull, pipe } from 'fp-ts/function'
import * as DE from '@nll/datum/DatumEither'
import { CombinedError, useQuery } from 'urql'
import { queryToDatumEither } from '@woorcs/graphql'

import {
  ViewerFragment,
  ViewerQuery,
  ViewerQueryDocument
} from './__generated__/viewer'

type ViewerContext = ViewerFragment

const [Provider, useViewerContext] = createContext<ViewerContext>()

// type ErrorHandlerProps = {
//   error: Error | any
// }

// const isUnauthenticatedError = (error: Error) => {
//   if (!isApolloError(error)) {
//     return false
//   }

//   return (
//     error.graphQLErrors,
//     A.findFirst(
//       (error: GraphQLError) => error.extensions?.code === 'UNAUTHENTICATED'
//     ),
//     O.fold(constFalse, constTrue)
//   )
// }

// const ErrorHandler = ({ error }: ErrorHandlerProps) => {
//   const [, { logout }] = useAuth()

//   useOnce(() => {
//     if (isUnauthenticatedError(error)) {
//       logout()
//     }
//   })

//   return null
// }

export const viewerQueryResponseOptional = pipe(
  Optional.id<ViewerQuery>(),
  Optional.prop('viewer'),
  Optional.fromNullable
)

interface ViewerProviderProps {
  onNone?(refreshing?: boolean): ReactElement | null
  onFailure?(error: CombinedError, refreshing?: boolean): ReactElement | null
  children: ReactElement
}

export const ViewerProvider = ({
  onNone = constNull,
  onFailure = constNull,
  children
}: ViewerProviderProps) =>
  pipe(
    useMemo(() => ({ query: ViewerQueryDocument }), []),
    useQuery,
    queryToDatumEither(viewerQueryResponseOptional),
    DE.squash(onNone, onFailure, (viewer) => (
      <Provider value={viewer}>{children}</Provider>
    ))
  )

export const useViewer = useViewerContext
