import React from 'react'
import { MenuItem as BaseMenuItem } from 'reakit/Menu'
import { PropsOf } from '@emotion/react'

import { system, forwardRef } from '../../../system'

import { useMenuItem, UseMenuItemProps } from './useMenu'
import { MenuItemButton } from './MenuItemButton'

export interface MenuItemProps
  extends UseMenuItemProps,
    Omit<PropsOf<typeof system.button>, 'onClick'> {
  destructive?: boolean
}

export const MenuItem = forwardRef<MenuItemProps, 'button'>(
  ({ destructive = false, ...props }, ref) => {
    const state = useMenuItem(props)

    return (
      <BaseMenuItem
        ref={ref}
        as={MenuItemButton}
        destructive={destructive}
        {...state}
      />
    )
  }
)
