import React, { useCallback } from 'react'
import * as A from 'fp-ts/Array'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as O from 'fp-ts/Option'
import * as Optional from 'monocle-ts/Optional'
import { Box, Spinner, Flex, SecondaryButton } from '@woorcs/design-system'
import * as DE from '@nll/datum/DatumEither'
import { pipe } from 'fp-ts/function'
import { useNavigate } from 'react-router'
import { useQuery } from 'urql'
import { queryToDatumEither } from '@woorcs/graphql'

import { EmptyState } from '../EmptyState'
import { CreateFormModal } from '../CreateFormModal'
import { CreateFormFormFragment } from '../CreateFormForm/__generated__/CreateFormForm'

import { FormListDocument, FormListQuery } from './__generated__/FormList'
import { FormListItemFormFragment } from './__generated__/FormListItem'
import { FormListItem } from './FormListItem'

const FormListEmptyState = () => {
  const navigate = useNavigate()
  const handleCreateForm = useCallback(
    (form: CreateFormFormFragment) => {
      navigate(`/forms/${form.id}/edit`)
    },
    [navigate]
  )
  return (
    <EmptyState
      title='No forms'
      description="You haven't created any forms yet"
    >
      <CreateFormModal onCreateForm={handleCreateForm}>
        <SecondaryButton>Create your first form</SecondaryButton>
      </CreateFormModal>
    </EmptyState>
  )
}

const responseOptional = Optional.id<FormListQuery>()

const renderItems = (items: FormListItemFormFragment[]) =>
  pipe(
    items,
    A.map((form) => <FormListItem key={form.id} form={form} />),
    (items) => <Box>{items}</Box>
  )

export const FormList = () => {
  return pipe(
    useQuery({ query: FormListDocument }),
    queryToDatumEither(responseOptional),
    DE.squash(
      () => (
        <Flex
          width='100%'
          height='100%'
          justifyContent='center'
          alignItems='center'
        >
          <Spinner />
        </Flex>
      ),
      () => <FormListEmptyState />,
      (response) =>
        pipe(
          response.forms,
          NEA.fromArray,
          O.fold(() => <FormListEmptyState />, renderItems)
        )
    )
  )
}
