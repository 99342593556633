import React from 'react'
import { Circle, PropsOf } from '@woorcs/design-system'
import { Tag } from '@woorcs/graphql/schema'

export interface TagAvatarProps
  extends Omit<PropsOf<typeof Circle>, 'radius'>,
    Partial<Pick<PropsOf<typeof Circle>, 'radius'>> {
  tag: Pick<Tag, 'color'>
}

export const TagAvatar = ({ tag, ...other }: TagAvatarProps) => (
  <Circle backgroundColor={tag.color ?? 'primary.500'} radius={8} {...other} />
)
