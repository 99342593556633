import {
  DialogStateReturn,
  DialogInitialState,
  DialogProps,
  DialogHTMLProps,
  useDialogState,
  DialogDisclosureOptions,
  DialogDisclosureProps,
  DialogDisclosureHTMLProps,
  DialogBackdropProps,
  DialogBackdropHTMLProps
} from 'reakit/Dialog'
import { createContext } from '@woorcs/utils'
import { useControllableState } from '@woorcs/hooks'
import { MutableRefObject } from 'react'

interface ModalOptions
  extends Pick<
    DialogProps,
    'hideOnEsc' | 'hideOnClickOutside' | 'preventBodyScroll'
  > {
  /**
   * The element that will be focused when the dialog shows. When not set, the
   * first tabbable element within the dialog will be used.
   */
  initialFocusRef?: MutableRefObject<HTMLElement | null>
  finalFocusRef?: MutableRefObject<HTMLElement | null>
}

export interface ModalContext {
  state: DialogStateReturn
  getDialogProps(): DialogStateReturn & Omit<DialogProps, keyof DialogHTMLProps>
  getDisclosureProps(): Omit<
    DialogDisclosureProps,
    keyof DialogDisclosureHTMLProps
  >
  getBackdropProps(): Omit<DialogBackdropProps, keyof DialogBackdropHTMLProps>
}

export const [ModalProvider, useModalContext] = createContext<ModalContext>({
  errorMessage:
    'Modal components must be used inside the context of a Modal element'
})

export interface UseModalProps extends DialogInitialState, ModalOptions {
  /**
   * Controls the visiblity state of the popover
   */
  visible?: boolean
  /**
   * Sets the default visibility state
   */
  defaultVisible?: boolean

  /**
   * Called when the visibility state changes
   */
  onVisiblityChange?(visible: boolean): void
}

export const useModal = ({
  visible: propVisible,
  defaultVisible,
  animated = true,
  modal = true,
  hideOnEsc,
  hideOnClickOutside,
  preventBodyScroll,
  initialFocusRef,
  finalFocusRef,
  onVisiblityChange,
  ...props
}: UseModalProps): ModalContext => {
  const state = useDialogState({
    ...props,
    visible: defaultVisible,
    animated,
    modal
  })

  const visible = useControllableState(
    propVisible,
    state.visible,
    state.setVisible,
    onVisiblityChange
  )

  const getDialogProps = () => ({
    ...state,
    visible,
    hideOnEsc,
    hideOnClickOutside,
    preventBodyScroll,
    unstable_initialFocusRef: initialFocusRef,
    unstable_finalFocusRef: finalFocusRef
  })

  const getDisclosureProps = () => ({
    visible,
    baseId: state.baseId,
    toggle: state.toggle
  })

  const getBackdropProps = () => ({
    visible,
    baseId: state.baseId,
    animated: state.animated,
    animating: state.animating,
    stopAnimation: state.stopAnimation,
    modal: state.modal
  })

  return {
    getDialogProps,
    getDisclosureProps,
    getBackdropProps,
    state: {
      ...state,
      visible
    }
  }
}

export const useModalDialog = () => {
  const { getDialogProps } = useModalContext()

  return {
    ...getDialogProps()
  }
}

export type UseModalDisclosureProps = Pick<
  DialogDisclosureOptions,
  'disabled' | 'focusable'
>

export const useModalDisclosure = (props: UseModalDisclosureProps) => {
  const { getDisclosureProps } = useModalContext()

  return {
    ...getDisclosureProps(),
    ...props
  }
}

export type UseModalBackdropProps = Record<string, any>

export const useModalBackdrop = (props: UseModalBackdropProps) => {
  const { getBackdropProps } = useModalContext()

  return {
    ...getBackdropProps(),
    ...props
  }
}
