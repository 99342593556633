import React, { cloneElement, memo, ReactElement, useCallback } from 'react'
import * as O from 'fp-ts/Option'
import { Box, css, Flex } from '@woorcs/design-system'
import { Popover, PopoverDisclosure, PopoverInitialState } from 'reakit/Popover'
import { pipe, constNull } from 'fp-ts/function'
import { FormElement } from '@woorcs/form'
import { Path } from '@woorcs/types/Path'
import { UUID } from '@woorcs/types/UUID'
import { InspectionFormDocument } from '@woorcs/inspection-form'

import { useParentElement } from '../../state'

import { ElementActionMenuActions } from './Actions'
import { useElementActionMenuPopoverState } from './usePopoverState'

interface ElementActionMenuProps
  extends Pick<PopoverInitialState, 'placement' | 'gutter'> {
  path: Path
  element: FormElement.FormElementType
  children: ReactElement
  disabled?: boolean
  onEdit(elementID: UUID): void
}

export const ElementActionMenu = memo(
  ({
    element,
    path,
    placement,
    gutter = 8,
    disabled,
    children,
    onEdit
  }: ElementActionMenuProps) => {
    const parent = useParentElement(element)
    const popoverState = useElementActionMenuPopoverState({
      placement,
      gutter,
      animated: true,
      disabled
    })

    const handleMouseEnter = useCallback(() => {
      if (disabled) {
        return
      }

      popoverState.show()
    }, [disabled, popoverState])

    const handleEdit = useCallback(() => {
      onEdit(element.id)
    }, [element.id, onEdit])

    const popoverProps = {
      ...popoverState,
      onMouseEnter: handleMouseEnter,
      onMouseLeave: popoverState.hide,
      ['aria-label']: 'Element actions'
    }

    return (
      <>
        <PopoverDisclosure {...popoverProps}>
          {(disclosureProps) => cloneElement(children, disclosureProps)}
        </PopoverDisclosure>

        <Popover
          {...popoverProps}
          unstable_autoFocusOnShow={false}
          aria-label={`Element actions`}
        >
          {(props) =>
            popoverState.visible && (
              <Box
                css={css({
                  zIndex: 'popover'
                })}
                borderRadius='medium'
                onClick={popoverState.hide}
                {...props}
              >
                <Flex
                  css={{
                    boxShadow:
                      '0 4px 16px rgb(0 0 0 / 12%), 0 0 0 1px rgb(0 0 0 / 2%)',
                    transition:
                      'opacity 250ms ease-in-out, transform 150ms ease-in-out',
                    opacity: 0,
                    transformOrigin: 'top center',
                    transform: 'translate3d(0, 8px, 0)',
                    '[data-enter] > &': {
                      opacity: 1,
                      transform: 'translate3d(0, 0, 0)'
                    },
                    willChange: 'opacity, transform'
                  }}
                  alignItems='center'
                  borderRadius='medium'
                  bg='grey.900'
                  border='base'
                  py={2}
                  pl={3}
                  pr={2}
                >
                  {pipe(
                    parent,
                    O.fold(constNull, (parent) => (
                      <ElementActionMenuActions
                        element={element}
                        parent={
                          parent as InspectionFormDocument.InspectionFormPageElement
                        }
                        path={path}
                        onEdit={handleEdit}
                      />
                    ))
                  )}
                </Flex>
              </Box>
            )
          }
        </Popover>
      </>
    )
  }
)
