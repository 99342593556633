import React, { ReactElement, useState } from 'react'
import {
  Box,
  Button,
  Popover,
  PopoverContent,
  PopoverDisclosure
} from '@woorcs/design-system'
import { FormDocument } from '@woorcs/form'

import { ManageDocumentsModal } from '../ManageDocuments'

import { DocumentList } from './List'

type SelectDocumentMenuProps = {
  children: ReactElement
  onSelect(document: FormDocument.FormDocument): void
}

export const SelectDocumentMenu = ({
  children,
  onSelect
}: SelectDocumentMenuProps) => {
  const [visible, setVisible] = useState(false)

  return (
    <Popover
      placement='bottom-start'
      visible={visible}
      onVisiblityChange={setVisible}
    >
      <PopoverDisclosure>{children}</PopoverDisclosure>
      <PopoverContent p={4} width={320}>
        <DocumentList
          onDocumentClick={(document) => {
            setVisible(false)
            onSelect(document)
          }}
        />

        <Box>
          <ManageDocumentsModal>
            <Button colorVariant='secondary' fullWidth>
              Manage documents
            </Button>
          </ManageDocumentsModal>
        </Box>
      </PopoverContent>
    </Popover>
  )
}
