import { Kind, Kind2, URIS, URIS2 } from 'fp-ts/HKT'
import * as SC from 'io-ts/Schema'
import { unsafeCoerce } from 'fp-ts/function'

import { Schemable2C, Schemable1, Schema } from './schemable'

export const interpreter: {
  <S extends URIS2, E>(S: Schemable2C<S, E>): <A>(
    schema: Schema<A>
  ) => Kind2<S, unknown, A>
  <S extends URIS>(S: Schemable1<S>): <A>(schema: Schema<A>) => Kind<S, A>
} = unsafeCoerce(SC.interpreter)
