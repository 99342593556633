import React from 'react'
import { Popover } from 'reakit/Popover'

import { usePopoverContent } from './usePopover'
import {
  PopoverContainer,
  PopoverInner,
  PopoverContainerProps
} from './PopoverContainer'

export type PopoverContentProps = PopoverContainerProps

export const PopoverContent = ({ children, ...props }: PopoverContentProps) => {
  const state = usePopoverContent()

  return (
    <Popover as={PopoverContainer} {...state}>
      <PopoverInner visible={state.visible} {...props}>
        {children}
      </PopoverInner>
    </Popover>
  )
}
