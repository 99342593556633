import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

import { system, forwardRef, PropsOf } from '../../../system'

export const PopoverContainer = system(motion.div)({
  bg: 'background.base',
  boxShadow: 'large',
  position: 'relative',
  zIndex: 'menu',
  borderRadius: 'large',
  ':focus': {
    outline: 'none'
  }
})

export type PopoverContainerProps = PropsOf<typeof PopoverContainer>

const transition = {
  duration: 0.15,
  ease: [0.48, 0.15, 0.25, 0.96]
}

const animations = {
  initial: { opacity: 0, scale: 0.8 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.8 }
}

export interface PopoverInnerProps extends PopoverContainerProps {
  visible: boolean
}

export const PopoverInner = forwardRef<PopoverInnerProps, 'div'>(
  ({ visible, children, ...props }, ref) => {
    return (
      <AnimatePresence initial={false}>
        {visible ? (
          <motion.div
            ref={ref}
            initial={animations.initial}
            animate={animations.animate}
            transition={transition}
            exit={animations.exit}
          >
            <PopoverContainer {...props}>{children}</PopoverContainer>
          </motion.div>
        ) : null}
      </AnimatePresence>
    )
  }
)
