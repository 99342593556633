import * as TE from 'fp-ts/TaskEither'
import * as TO from 'fp-ts/TaskOption'
import * as E from 'fp-ts/Either'
import * as TD from 'io-ts/TaskDecoder'
import { constant, pipe } from 'fp-ts/function'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

interface GetRequest<A> {
  method: 'GET'
  path: string
  responseDecoder: TD.TaskDecoder<unknown, A>
}

interface PostRequest<A> {
  method: 'POST'
  path: string
  body: Record<string, unknown>
  responseDecoder: TD.TaskDecoder<unknown, A>
}

type RequestOptions<A> = GetRequest<A> | PostRequest<A>

interface TokenMissingError {
  _tag: 'tokenMissingError'
}

interface DecodeError {
  _tag: 'decodeError'
  decodeError: TD.DecodeError
}

interface HttpResponseStatusError {
  _tag: 'httpResponseStatusError'
  status: number
}

interface HttpContentTypeError {
  _tag: 'httpContentTypeError'
  error: Error
}

interface HttpRequestError {
  _tag: 'httpRequestError'
  error: Error
}

export type FetchError =
  | TokenMissingError
  | DecodeError
  | HttpResponseStatusError
  | HttpContentTypeError
  | HttpRequestError

// -------------------------------------------------------------------------------------
// instance
// -------------------------------------------------------------------------------------

const createUrl = (baseUrl: string, path: string) => `${baseUrl}${path}`

export const toJson = (
  response: Response
): TE.TaskEither<HttpContentTypeError, unknown> =>
  TE.tryCatch(
    () => response.json(),
    (error: any): HttpContentTypeError => ({
      _tag: 'httpContentTypeError',
      error
    })
  )

export const ensureStatus =
  (min: number, max: number) =>
  (response: Response): E.Either<HttpResponseStatusError, Response> =>
    min <= response.status && response.status < max
      ? E.right(response)
      : E.left({ _tag: 'httpResponseStatusError', status: response.status })

const shouldRefreshToken = (error: FetchError) => {
  if (error._tag !== 'httpResponseStatusError') {
    return false
  }

  return error.status === 400
}

const performRequest = <A>(
  baseUrl: string,
  accessToken: string,
  options: RequestOptions<A>
) =>
  pipe(
    TE.tryCatch(
      () =>
        fetch(createUrl(baseUrl, options.path), {
          method: options.method,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body:
            options.method === 'POST' ? JSON.stringify(options.body) : undefined
        }),
      (error: any): HttpRequestError => ({
        _tag: 'httpRequestError',
        error
      })
    ),
    TE.chainEitherKW(ensureStatus(200, 300))
  )

export interface CreateFetchOptions {
  baseUrl: string
  getAccessToken(): TO.TaskOption<string>
}

export const createFetch =
  ({ baseUrl, getAccessToken }: CreateFetchOptions) =>
  <A>(options: RequestOptions<A>): TE.TaskEither<FetchError, A> =>
    pipe(
      getAccessToken(),
      TE.fromTaskOption(
        (): TokenMissingError => ({
          _tag: 'tokenMissingError'
        })
      ),
      TE.chainW((accessToken) => performRequest(baseUrl, accessToken, options)),
      //TE.orElseW((e) => {
      //  if (!shouldRefreshToken(e)) {
      //    return TE.left(e)
      //  }
      //
      //  return pipe(
      //    refreshToken(),
      //    TE.fromTaskOption(constant(e)),
      //    TE.chainW((accessToken) =>
      //      performRequest(baseUrl, accessToken, options)
      //    )
      //  )
      //}),
      TE.chainW(toJson),
      TE.chainW((response) =>
        pipe(
          options.responseDecoder.decode(response),
          TE.mapLeft(
            (decodeError): DecodeError => ({
              _tag: 'decodeError',
              decodeError
            })
          )
        )
      )
    )
