import { constant, pipe } from 'fp-ts/function'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as A from 'fp-ts/Array'
import * as O from 'fp-ts/Option'
import * as L from 'monocle-ts/Lens'
import * as T from 'monocle-ts/Traversal'
import * as S from '@woorcs/types/Schemable'
import { uuid, UUID } from '@woorcs/types/UUID'

import * as TranslateableText from '../i18n/TranslateableText'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

export type ResponseSetOption = {
  readonly id: UUID
  readonly label: TranslateableText.TranslateableText
}

export type ResponseSet = {
  readonly id: UUID
  readonly name: string
  readonly options: NEA.NonEmptyArray<ResponseSetOption>
}

const ResponseSetOption: S.Type<ResponseSetOption> = S.type((S) =>
  S.struct({
    id: UUID.schema(S),
    label: TranslateableText.TranslateableText.schema(S)
  })
)

export const ResponseSet: S.Type<ResponseSet> = S.type((S) =>
  S.struct({
    id: UUID.schema(S),
    name: S.string,
    options: S.nonEmptyArray(ResponseSetOption.schema(S))
  })
)

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const responseSetOption = (label: string): ResponseSetOption => ({
  id: uuid(),
  label: TranslateableText.translateableText(label)
})

export const responseSet = (
  name: string,
  options: NEA.NonEmptyArray<ResponseSetOption>
): ResponseSet => ({
  id: uuid(),
  name,
  options
})

export const of = (label: string) => responseSet('', [responseSetOption(label)])

// -------------------------------------------------------------------------------------
// lenses
// -------------------------------------------------------------------------------------

const nameL = pipe(L.id<ResponseSet>(), L.prop('name'))
const optionsL = pipe(L.id<ResponseSet>(), L.prop('options'))

const optionsT = (id: UUID) =>
  pipe(
    T.id<NEA.NonEmptyArray<ResponseSetOption>>(),
    T.findFirstNonEmpty((option) => option.id === id)
  )

const optionT = (id: UUID) => pipe(optionsL, L.composeTraversal(optionsT(id)))

const optionLabelL = pipe(L.id<ResponseSetOption>(), L.prop('label'))

const optionLabelT = (id: UUID) =>
  pipe(optionT(id), T.composeLens(optionLabelL))

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export const setName = nameL.set

export const addOption = (option: ResponseSetOption) =>
  pipe(
    optionsL,
    L.modify((options) => NEA.snoc(options, option))
  )

export const removeOption = (id: UUID) =>
  pipe(
    optionsL,
    L.modify((options) =>
      pipe(
        options,
        NEA.filter((option) => option.id !== id),
        O.getOrElse(constant(options))
      )
    )
  )

export const updateOption = (
  id: UUID,
  update: (a: ResponseSetOption) => ResponseSetOption
) => pipe(optionT(id), T.modify(update))

export const updateOptionLabel = (id: UUID, label: string) =>
  pipe(optionLabelT(id), T.modify(TranslateableText.setText(label)))

export const getOption = (id: UUID) => (responseSet: ResponseSet) =>
  pipe(
    responseSet.options,
    A.findFirst((option) => option.id === id)
  )

export const updateLabel = (text: string) =>
  pipe(optionLabelL, L.modify(TranslateableText.setText(text)))

/**
 * TODO: handle translations of default response sets
 */
export const createDefaultResponseSets = () => [
  responseSet('Yes / No', [responseSetOption('Yes'), responseSetOption('No')]),
  responseSet('Yes / No / N/A', [
    responseSetOption('Yes'),
    responseSetOption('No'),
    responseSetOption('N/A')
  ]),
  responseSet('Risk', [
    responseSetOption('Safe'),
    responseSetOption('At risk'),
    responseSetOption('N/A')
  ])
]
