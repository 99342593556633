import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { UserListUserFragmentDoc } from '../../UserList/__generated__/UserListFragments';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type AddUserToOrganizationMutationVariables = SchemaTypes.Exact<{
  input: SchemaTypes.AddUserToOrganizationInput;
}>;


export type AddUserToOrganizationMutation = { __typename: 'Mutation', addUserToOrganization: { __typename: 'AddUserToOrganizationSuccess', user: { __typename: 'User', id: string, name: string, firstName: string | null, lastName: string | null, status: SchemaTypes.UserStatus, role: SchemaTypes.Role | null, tags: Array<{ __typename: 'Tag', id: string, name: string, color: string | null }> } } | { __typename: 'UserAlreadyAddedError', message: string } };


export const AddUserToOrganizationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddUserToOrganization"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddUserToOrganizationInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addUserToOrganization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"AddUserToOrganizationSuccess"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserListUserFragment"}}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"UserAlreadyAddedError"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]}},...UserListUserFragmentDoc.definitions]} as unknown as DocumentNode<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>;