import React from 'react'
import {
  unstable_useGridState as useGridState,
  unstable_Grid as Grid,
  unstable_GridRow as GridRow,
  unstable_GridRowProps as GridRowProps,
  unstable_GridRowHTMLProps as GridRowHTMLProps
} from 'reakit/Grid'

import { Flex, FlexProps } from '../../layout'
import { Overline } from '../../typography'
import { css } from '../../../system'

import { Calendar } from './useCalendarState'
import { CalendarWeekdays } from './CalendarWeekdays'
import { CalendarTodayCell, CalendarDayCell } from './CalendarDayCell'

export const monthNamesFull = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const getMonth = (n: number) => monthNamesFull[n]

interface CalendarWeekProps
  extends Omit<GridRowProps, keyof GridRowHTMLProps>,
    FlexProps {}

const CalendarWeek = (props: CalendarWeekProps) => {
  return <GridRow as={Flex} {...props} />
}

interface CalendarMonthProps {
  calendar: Calendar
}

export const CalendarMonth = ({ calendar }: CalendarMonthProps) => {
  const grid = useGridState({
    wrap: true
  })

  return (
    <Grid
      key={calendar.month}
      css={css({ mr: 2, ':last-child': { mr: 0 } })}
      aria-label={getMonth(calendar.month)}
      {...grid}
    >
      <Flex display='inline-flex' flexDirection='column'>
        <Flex
          display='inline-flex'
          justifyContent='center'
          alignItems='center'
          width='100%'
          pt={6}
        >
          <Overline color='text.emphasized'>
            {getMonth(calendar.month)}
          </Overline>
        </Flex>

        <CalendarWeekdays />

        {calendar.weeks.map((days, windex) => (
          <CalendarWeek
            key={`${calendar.month}${calendar.year}${windex}`}
            {...grid}
          >
            {days.map((day, dindex) => {
              const key = `${calendar.month}${calendar.year}${windex}${dindex}`

              if (day.today) {
                return <CalendarTodayCell key={key} day={day} {...grid} />
              }

              return <CalendarDayCell key={key} day={day} {...grid} />
            })}
          </CalendarWeek>
        ))}
      </Flex>
    </Grid>
  )
}
