import React, { ReactText } from 'react'

import { useSelectContext } from './useSelectState'

interface SelectPlaceholderProps {
  children: ReactText
}

export const SelectPlaceholder = ({ children }: SelectPlaceholderProps) => {
  const { hasValue } = useSelectContext()

  if (hasValue) {
    return null
  }

  return <>{children}</>
}
