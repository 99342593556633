import React, { cloneElement, ReactElement } from 'react'
import {
  useTooltipState,
  Tooltip as BaseTooltip,
  TooltipReference,
  TooltipProps as BaseTooltipProps,
  TooltipArrow,
  TooltipInitialState
} from 'reakit/Tooltip'

import { system, SystemProps, splitSystemProps } from '../../../system'

const TooltipContainer = system('div')({
  px: 2,
  py: 1,
  borderRadius: 'small',
  backgroundColor: 'grey.700',
  color: 'white',
  fontSize: 'mini',
  fontWeight: 'bold',
  transition: 'opacity 250ms ease-in-out, transform 250ms ease-in-out',
  opacity: 0,
  transformOrigin: 'top center',
  transform: 'translate3d(0, 4px, 0)',
  '[data-enter] &': {
    opacity: 1,
    transform: 'translate3d(0, 0, 0)'
  }
})

interface TooltipProps
  extends SystemProps,
    Omit<BaseTooltipProps, 'color'>,
    Partial<Pick<TooltipInitialState, 'placement'>> {
  title: string
  children: ReactElement
  disabled?: boolean
}

export const Tooltip = ({
  children,
  title,
  disabled,
  placement,
  ...props
}: TooltipProps) => {
  const [systemProps, other] = splitSystemProps(props)
  const tooltip = useTooltipState({
    animated: true,
    placement
  })

  return (
    <>
      <TooltipReference {...tooltip} {...children.props}>
        {(referenceProps) =>
          cloneElement(children, { ...referenceProps, ...systemProps })
        }
      </TooltipReference>

      {!disabled && tooltip.visible && (
        <BaseTooltip
          as={system.div}
          __css={{ zIndex: 'tooltip' }}
          {...tooltip}
          {...other}
        >
          <TooltipContainer>
            <TooltipArrow
              as={system.div}
              css={{ backgroundColor: 'grey.700' }}
              size={8}
              {...tooltip}
            />
            {title}
          </TooltipContainer>
        </BaseTooltip>
      )}
    </>
  )
}
