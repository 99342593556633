import React, { ReactNode, Children } from 'react'
import { variant } from 'styled-system'
import { space, isString } from '@woorcs/utils'

import { system, PropsOf, forwardRef, css } from '../../../system'
import { CheckIcon, InfoIcon, DangerIcon } from '../../icons'
import {
  Button,
  ButtonProps,
  CloseButton,
  CloseButtonProps
} from '../../buttons'

import {
  AlertVariant,
  AlertStyleProvider,
  useAlertStyle
} from './useAlertStyle'

const variants = variant({
  prop: 'variant',
  variants: {
    success: {
      bg: 'secondary.500',
      color: 'secondary.50'
    },
    info: {
      bg: 'primary.50',
      color: 'primary.500'
    },
    neutral: {
      bg: 'grey.50',
      color: 'grey.500'
    },
    warning: {
      bg: 'orange.50',
      color: 'orange.500'
    },
    danger: {
      backgroundColor: 'red.50',
      color: 'red.500'
    }
  }
})

interface AlertProps extends PropsOf<typeof system.div> {
  variant?: AlertVariant
}

export const AlertContainer = system('div')<AlertProps>(
  css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: 'medium',
    px: 4,
    py: 2,
    minHeight: space(11),
    fontSize: 'small'
  }),
  variants
)

const getIconForVariant = (variant: AlertVariant) => {
  switch (variant) {
    case 'success': {
      return CheckIcon
    }

    case 'neutral':
    case 'info': {
      return InfoIcon
    }

    case 'warning':
    case 'danger': {
      return DangerIcon
    }
  }
}

export const AlertIcon = () => {
  const { variant } = useAlertStyle()
  const Icon = getIconForVariant(variant)

  return <Icon aria-hidden='true' mr={2} flexGrow={0} />
}

export const AlertCloseButton = (props: CloseButtonProps) => {
  const { variant } = useAlertStyle()

  const colorVariant = variant === 'info' ? 'primary' : variant

  return <CloseButton colorVariant={colorVariant} {...props} />
}

type AlertActionButtonProps = Omit<ButtonProps, 'size' | 'colorVariant'>

export const AlertActionButton = (props: AlertActionButtonProps) => {
  const { variant } = useAlertStyle()

  const colorVariant = variant === 'info' ? 'primary' : variant

  return (
    <Button
      size='small'
      variant='plain'
      colorVariant={colorVariant}
      {...props}
    />
  )
}

interface AlertMessageProps {
  children: ReactNode
}

export const AlertMessage = ({ children }: AlertMessageProps) => {
  return (
    <system.span flex={1} fontWeight='semi-bold'>
      {children}
    </system.span>
  )
}

export const Alert = forwardRef<AlertProps, 'div'>(
  ({ variant = 'info', children, ...other }, ref) => {
    return (
      <AlertStyleProvider value={{ variant }}>
        <AlertContainer ref={ref} role='alert' variant={variant} {...other}>
          {Children.map(children, (child) => {
            if (isString(child)) {
              return <AlertMessage>{child}</AlertMessage>
            }

            return child
          })}
        </AlertContainer>
      </AlertStyleProvider>
    )
  }
)
