import * as O from 'fp-ts/Option'
import * as E from 'fp-ts/Either'
import { pipe, constant, identity } from 'fp-ts/function'
import * as D from 'io-ts/Decoder'
import * as DE from '@nll/datum/DatumEither'
import * as Optional from 'monocle-ts/Optional'
import { CombinedError, UseQueryResponse } from 'urql'

export function queryToDatumEither<A, B>(
  responseOptional: Optional.Optional<A, B>
) {
  return ([query]: UseQueryResponse<A>): DE.DatumEither<CombinedError, B> => {
    const getResult = (): O.Option<DE.DatumEither<CombinedError, B>> => {
      return pipe(
        O.fromNullable(query.data),
        O.chain(responseOptional.getOption),
        O.map((response) => DE.success(response)),
        // O.map((data) =>
        //   pipe(
        //     responseOptional.getOption(data)
        //     E.mapLeft(invalidResponse),
        //     E.fold(DE.failure, (data) => DE.success(data))
        //   )
        // ),
        O.altW(() => {
          if (query.error) {
            return O.some(DE.failure(query.error))
          }

          return O.none
        })
      )
    }

    // if (!query.called) {
    //   return DE.initial
    // }

    const result = getResult()

    if (!query.fetching) {
      return pipe(
        result,
        // TODO: this None case should be impossible but we should probably handle it anyway somehow.
        O.fold(() => DE.initial, identity)
      )
    }

    return pipe(result, O.fold(constant(DE.pending), DE.toRefresh))
  }
}
