import {
  Auth0ContextInterface,
  LogoutOptions,
  useAuth0
} from '@auth0/auth0-react'
// eslint-disable-next-line import/no-unresolved
import { AuthState } from '@auth0/auth0-react/dist/auth-state'
import { useCallback, useMemo } from 'react'

interface AuthActions
  extends Omit<Auth0ContextInterface, keyof AuthState | 'logout'> {
  logout(options?: LogoutOptions): void
}

export const useAuth = (): [AuthState, AuthActions] => {
  const { isAuthenticated, isLoading, error, user, logout, ...other } =
    useAuth0()

  const handleLogout = useCallback(
    (options?: LogoutOptions) => {
      window.localStorage.clear()

      logout({
        returnTo: import.meta.env.VITE_REACT_APP_MARKETING_URL?.toString(),
        client_id: import.meta.env.VITE_REACT_APP_AUTH_CLIENT_ID?.toString(),
        ...options
      })
    },
    [logout]
  )

  const state = useMemo(
    () => ({
      isAuthenticated,
      isLoading,
      error,
      user: user ? { id: user.sub, ...user } : undefined
    }),
    [error, isAuthenticated, isLoading, user]
  )

  const actions = useMemo<AuthActions>(
    () => ({
      logout: handleLogout,
      ...other
    }),
    [handleLogout, other]
  )

  return [state, actions]
}
