import { CSSObject } from '@emotion/serialize'
import systemCss, {
  CssFunctionReturnType,
  SystemStyleObject
} from '@styled-system/css'

import { Theme } from '../theme'

import { Interpolation } from './interpolation'

// eslint-disable-next-line @typescript-eslint/ban-types
export const css = <Props extends { theme?: Theme } = {}>(
  style: SystemStyleObject
): ((props: Props) => CSSObject) => systemCss(style) as any
// ): Interpolation<Props> => systemCss(style) as any

css({})
