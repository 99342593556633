/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import * as ReactQuery from 'react-query'
import * as DE from '@nll/datum/DatumEither'
import * as E from 'fp-ts/Either'
import * as TE from 'fp-ts/TaskEither'
import { createContext } from '@woorcs/utils'
import { pipe, constNull, constVoid } from 'fp-ts/function'

import {
  ApiClient,
  createClient,
  CreateOrganizationInput,
  MePayload,
  CreateOrganizationPayload,
  CreateAPIClientOptions
} from './client'
import { FetchError } from './fetch'

const [ApiClientContextProvider, useApiClient] = createContext<ApiClient>()

const defaultQueryClient = new ReactQuery.QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      useErrorBoundary: false
    },
    mutations: {
      useErrorBoundary: false
    }
  }
})

interface ApiClientProviderProps extends CreateAPIClientOptions {
  children: ReactNode
  queryClient?: ReactQuery.QueryClient
}

export const ApiClientProvider = ({
  children,
  queryClient = defaultQueryClient,
  ...options
}: ApiClientProviderProps) => {
  const client = createClient(options)

  return (
    <ApiClientContextProvider value={client}>
      <ReactQuery.QueryClientProvider client={queryClient}>
        {children}
      </ReactQuery.QueryClientProvider>
    </ApiClientContextProvider>
  )
}

type AccountContext = MePayload

const [AccountContextProvider, useAccount] = createContext<AccountContext>()

export { useAccount }

interface AccountProviderProps {
  children: ReactElement
  onError?(error: FetchError): void
  renderEmpty?(loading?: boolean): ReactElement | null
  renderError?(
    error: FetchError,
    retry: () => void,
    loading?: boolean
  ): ReactElement | null
}

export const AccountProvider = ({
  children,
  onError = constVoid,
  renderEmpty = constNull,
  renderError = constNull
}: AccountProviderProps) => {
  const me = useMe()
  const queryClient = ReactQuery.useQueryClient()

  useEffect(() => {
    if (DE.isFailure(me)) {
      onError(me.value.left)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me])

  return pipe(
    me,
    DE.squash(
      renderEmpty,
      (error, loading) =>
        renderError(error, () => queryClient.invalidateQueries('me'), loading),
      (me) => (
        <AccountContextProvider value={me}>{children}</AccountContextProvider>
      )
    )
  )
}

function queryToDatumEither<E, A>(
  query: ReactQuery.UseQueryResult<A, E>
): DE.DatumEither<E, A> {
  switch (query.status) {
    case 'idle': {
      return DE.initial
    }

    case 'success': {
      if (query.isFetching) {
        return DE.toRefresh(DE.success(query.data))
      }

      return DE.success(query.data)
    }

    case 'error': {
      if (query.isFetching) {
        return DE.toRefresh(DE.failure(query.error))
      }

      return DE.failure(query.error)
    }

    case 'loading': {
      return DE.pending
    }
  }
}

export const useMe = () => {
  const client = useApiClient()

  const response = pipe(
    ReactQuery.useQuery<MePayload, FetchError>('me', () => {
      const getMe = client.me()

      return getMe().then(
        E.getOrElseW((e: FetchError) => {
          // rethrow error so that ReactQuery handles it
          throw e
        })
      )
    }),
    queryToDatumEither
  )

  return response
}

export const useCreateOrganization = () => {
  const [updating, setUpdating] = useState(false)
  const client = useApiClient()
  const queryClient = ReactQuery.useQueryClient()

  const createOrganization = (
    input: CreateOrganizationInput
  ): Promise<E.Either<FetchError, CreateOrganizationPayload>> => {
    const createOrganization = pipe(
      TE.fromIO<void, FetchError>(() => setUpdating(true)),
      TE.chainW(() => client.createOrganization(input)),
      TE.chainFirstTaskK(() => () => {
        setUpdating(false)

        return queryClient.invalidateQueries('me')
      })
    )

    return createOrganization()
  }

  return [updating, createOrganization] as const
}
