import React, { Children } from 'react'
import { variant, space } from 'styled-system'
import { whenTrue, isString } from '@woorcs/utils'

import { focusStyle } from '../../../styles'
import { system, forwardRef, PropsOf } from '../../../system'
import { BUTTON_HEIGHT } from '../Button'
import { plainButtonStyle } from '../PlainButton'

import { FilterButtonLabel } from './FilterButtonLabel'

export type FilterButtonSize = 'small' | 'medium'

const size = variant({
  prop: 'size',
  variants: {
    small: {
      minWidth: BUTTON_HEIGHT.small,
      height: BUTTON_HEIGHT.small,
      px: space(0.5)
    },
    medium: {
      minWidth: BUTTON_HEIGHT.medium,
      height: BUTTON_HEIGHT.medium,
      px: 1
    }
  }
})

const selected = whenTrue(['selected'])({
  bg: 'grey.100',
  borderColor: 'grey.200',
  color: 'grey.900',
  ':focus': {
    bg: 'grey.100'
  }
})

const invalid = whenTrue(['invalid'])({
  color: 'danger',
  borderColor: 'currentColor'
})

const insideGroup = whenTrue(['insideGroup'])({
  borderRadius: 0,
  borderWidth: 0
})

interface ToggleButtonProps {
  selected?: boolean
  invalid?: boolean
  size?: 'small' | 'medium'
  insideGroup?: boolean
}

const StyledToggleButton = system('button')<ToggleButtonProps>(
  plainButtonStyle,
  {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    bg: 'grey.50',
    color: 'grey.900',
    borderRadius: 'medium',
    border: 'none',
    fontSize: 'mini',
    fontWeight: 'bold',
    transition: 'border-color 0.3s ease-out, background 0.3s ease-out',
    ':hover, :focus': {
      bg: 'grey.100'
      // borderColor: 'primary.100'
    },
    ':focus': focusStyle,
    ':disabled, &[disabled]': {
      backgroundColor: 'grey.100',
      color: 'grey.100'
    }
  },
  selected,
  invalid,
  size,
  insideGroup
)

export interface FilterButtonProps extends PropsOf<typeof system.button> {
  size?: FilterButtonSize
}

export const FilterButton = forwardRef<FilterButtonProps, typeof system.button>(
  ({ size = 'medium', children, ...other }, ref) => {
    const childs = Children.map(children, (child) => {
      if (isString(child)) {
        return <FilterButtonLabel size={size}>{child}</FilterButtonLabel>
      }

      return child
    })

    return (
      <StyledToggleButton ref={ref} size={size} {...other}>
        {childs}
      </StyledToggleButton>
    )
  }
)
