import React, { useMemo, ReactElement } from 'react'
import {
  Box,
  Table,
  TableHead,
  TableHeadCell,
  TableBody,
  Pagination,
  InboxIcon,
  Flex,
  DateRangeTuple
} from '@woorcs/design-system'
import * as DE from '@nll/datum/DatumEither'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as Optional from 'monocle-ts/Optional'
import { useTranslation } from 'react-i18next'
import { constant, pipe } from 'fp-ts/function'
import { space } from '@woorcs/utils'
import { queryToDatumEither } from '@woorcs/graphql'
import { useQuery } from 'urql'

import { EmptyState, isEmptyFilters } from '@app/components'

import { ErrorView } from '../ErrorView'
import { useSubmissionListFiltersContext } from '../SubmissionListFilters/filters'

import {
  SubmissionListQuery,
  SubmissionListQueryDocument,
  SubmissionListSubmissionFragment
} from './__generated__/SubmissionList'
import { SubmissionListRow } from './Row'

interface ListProps {
  isLoading: boolean
  data: SubmissionListSubmissionFragment[]
}

const List = ({ data }: ListProps) => {
  const { t } = useTranslation('submissions')

  return (
    <Table outerPadding={space(12)}>
      <TableHead>
        <TableHeadCell>{t('submissionList.columns.form.label')}</TableHeadCell>
        <TableHeadCell>
          {t('submissionList.columns.submittedBy.label')}
        </TableHeadCell>
        <TableHeadCell>{t('submissionList.columns.tags.label')}</TableHeadCell>
        <TableHeadCell>
          {t('submissionList.columns.updated.label')}
        </TableHeadCell>
        <TableHeadCell />
      </TableHead>
      <TableBody>
        {data.map((submission) => (
          <SubmissionListRow key={submission.id} submission={submission} />
        ))}
      </TableBody>
    </Table>
  )
}

const EmptyList = () => {
  const { filters } = useSubmissionListFiltersContext()

  if (isEmptyFilters(filters)) {
    return (
      <EmptyState
        Icon={InboxIcon}
        title={`It\'s empty here`}
        description={`No forms has been submitted yet.`}
      />
    )
  }

  return (
    <EmptyState
      Icon={InboxIcon}
      title={`No submissions found`}
      description='No submissions found for the current filters'
    />
  )
}

const submissionsResultOptional = pipe(
  Optional.id<SubmissionListQuery>(),
  Optional.prop('submissions'),
  // Optional.fromNullable,
  Optional.filter((result) => A.isNonEmpty(result.data))
)

export const SubmissionList = () => {
  const { filters, limit, offset, setOffset } =
    useSubmissionListFiltersContext()

  const variables = useMemo(
    () => ({
      from: pipe(
        filters.published,
        O.chain(DateRangeTuple.start),
        O.toUndefined
      ),
      to: pipe(filters.published, O.chain(DateRangeTuple.end), O.toUndefined),
      formId: O.toNullable(filters.formId),
      creatorId: O.toNullable(filters.creatorId),
      tags: pipe(filters.tags, O.getOrElseW(constant([]))),
      offset,
      limit
    }),
    [filters, limit, offset]
  )

  const query = useQuery({
    query: SubmissionListQueryDocument,
    variables,
    requestPolicy: 'network-only'
  })

  return pipe(
    query,
    queryToDatumEither(submissionsResultOptional),
    DE.squash(
      () => (
        <Flex flex={1} flexDirection='column' justifyContent='center'>
          <EmptyList />
        </Flex>
      ),
      () => (
        <ErrorView body='Something went wrong while fetching the submissions' />
      ),
      (response, loading): ReactElement => {
        const pageInfo = response.pageInfo
        const totalPages = Math.max(
          Math.ceil(pageInfo.totalCount / pageInfo.limit),
          1
        )

        const handlePageChange = (page: number) => {
          const offset = pageInfo.limit * (page - 1)

          setOffset(offset)
        }

        return pipe(response.data, (data) => (
          <Box>
            <List isLoading={loading ?? false} data={data} />

            <Box py={6} px={12}>
              <Pagination
                currentPage={pageInfo.offset / pageInfo.limit + 1}
                totalPages={totalPages}
                onChange={handlePageChange}
              />
            </Box>
          </Box>
        ))
      }
    )
  )
}
