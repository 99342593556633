import React from 'react'
import {
  AbsoluteFill,
  Box,
  Button,
  ChevronDownIcon,
  ChevronLeftIcon,
  Flex,
  IconButton,
  Navbar,
  PrimaryButton,
  SecondaryButton,
  Separator,
  Spinner,
  Text,
  Tooltip
} from '@woorcs/design-system'
import { FormDocument } from '@woorcs/form'
import { formatDistanceToNow } from 'date-fns'
import * as O from 'fp-ts/Option'
import { UUID } from '@woorcs/types/UUID'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { Path } from '@woorcs/types/Path'
import { constNull, pipe } from 'fp-ts/function'

import { SelectPageMenu } from '../SelectPageMenu'
import { PreviewFormModal } from '../PreviewFormModal'
import { HistoryActions } from '../HistoryActions'
import { useElement, useValue } from '../../state'

interface EditorHeaderProps {
  selectedPageId: UUID
  updatedAt: Date
  isPublishing: boolean
  isSaving: boolean
  published: boolean
  onQuit(): void
  onPublish(): void
  onPageChange(id: O.Option<UUID>): void
}

export const EditorHeader = ({
  selectedPageId,
  updatedAt = new Date(),
  isSaving,
  isPublishing,
  published,
  onPublish,
  onQuit,
  onPageChange
}: EditorHeaderProps) => {
  const page = useElement(selectedPageId) as O.Option<{
    element: InspectionFormDocument.InspectionFormPageElement
    path: Path
  }>
  const root = useValue()

  return (
    <Box>
      <Navbar
        bg='white'
        flexShrink={0}
        position='fixed'
        top={0}
        left={0}
        right={0}
        borderBottom='base'
      >
        <Flex justifyContent='space-between' alignItems='center' width='100%'>
          <Flex alignItems='center' height='100%'>
            <Tooltip title='Exit editor'>
              <IconButton mr={4} onClick={onQuit}>
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
            <Box>
              <Text as='p' fontWeight='bold' lineHeight='1' mb={1}>
                {FormDocument.getDefaultTitle(root)}
              </Text>
              {isSaving && (
                <Flex alignItems='center'>
                  <Spinner size='mini' color='primary.500' mr={2} />
                  <Text fontSize='small' lineHeight='1'>
                    saving draft...
                  </Text>
                </Flex>
              )}

              {!isSaving && (
                <Flex>
                  <Text fontSize='small' lineHeight='1'>
                    last updated {formatDistanceToNow(updatedAt)} ago
                  </Text>
                </Flex>
              )}
            </Box>
          </Flex>

          {pipe(
            page,
            O.fold(constNull, ({ element }) => (
              <AbsoluteFill
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{
                  pointerEvents: 'none'
                }}
              >
                <Box style={{ pointerEvents: 'auto' }}>
                  <SelectPageMenu
                    selectedID={element.id}
                    onSelectPage={(id) => onPageChange(O.some(id))}
                  >
                    <Button variant='plain'>
                      <Flex alignItems='center'>
                        <Text
                          fontWeight='regular'
                          fontSize='base'
                          color='text.base'
                        >
                          {FormDocument.getDefaultTitle(root)}
                        </Text>
                        <Text
                          mx={2}
                          color='text.base'
                          fontWeight='regular'
                          fontSize='base'
                        >
                          /
                        </Text>
                        <Text
                          fontWeight='bold'
                          color='text.base'
                          fontSize='base'
                        >
                          {FormDocument.getDefaultTitle(element)}
                        </Text>
                        <ChevronDownIcon ml={2} color='text.base' />
                      </Flex>
                    </Button>
                  </SelectPageMenu>
                </Box>
              </AbsoluteFill>
            ))
          )}

          <Flex alignItems='center'>
            <HistoryActions />
            <Separator orientation='vertical' height={8} ml={8} mr={10} />
            <PreviewFormModal
              definition={root}
              backButtonLabel='Back to editor'
            >
              <SecondaryButton mr={3}>Preview</SecondaryButton>
            </PreviewFormModal>
            <PrimaryButton
              disabled={isPublishing || published}
              loading={isPublishing}
              onClick={onPublish}
            >
              Publish
            </PrimaryButton>
          </Flex>
        </Flex>
      </Navbar>
    </Box>
  )
}
