import React, { ReactNode } from 'react'
import { isFunction } from '@woorcs/utils'

import { useMenu, MenuProvider, UseMenuProps, MenuContext } from './useMenu'

interface MenuProps extends UseMenuProps {
  children: ReactNode | ((context: MenuContext) => ReactNode)
}

export const Menu = ({ children, ...props }: MenuProps) => {
  const context = useMenu(props)

  return (
    <MenuProvider value={context}>
      {isFunction(children) ? children(context) : children}
    </MenuProvider>
  )
}
