import React from 'react'
import {
  DialogBackdrop as BaseDialogBackdrop,
  DialogBackdropProps as BaseDialogBackdropProps
} from 'reakit/Dialog'
import Color from 'color'
import { palette } from '@woorcs/design-tokens'

import { css } from '../../../system'

interface DialogBackdropProps extends BaseDialogBackdropProps {
  centerContent?: boolean
}

export const DialogBackdrop = ({
  centerContent = false,
  ...props
}: DialogBackdropProps) => (
  <BaseDialogBackdrop
    css={css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: centerContent ? 'center' : 'flex-start',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      maxHeight: '100vh',
      overflowY: 'scroll',
      background: Color(palette.primary['50']).alpha(0.65).toString(),
      perspective: '800px',
      transition: 'opacity 250ms ease-in-out',
      opacity: 0,
      zIndex: 'modal',
      willChange: 'opacity',
      pt: centerContent ? 0 : '20vh',
      pb: 12,
      '&[data-enter]': {
        opacity: 1
      }
    })}
    {...props}
  />
)
