import React, { ReactElement, cloneElement } from 'react'
import { rem, combineInterpolators } from '@woorcs/utils'

import {
  Button,
  BUTTON_HEIGHT,
  ButtonProps,
  ButtonSizeVariant
} from '../Button'
import { forwardRef, css } from '../../../system'

const SIZE = {
  ...BUTTON_HEIGHT,
  mini: rem(18)
}

export interface IconButtonProps
  extends Omit<ButtonProps, 'size' | 'children' | 'leftIcon' | 'rightIcon'> {
  size?: keyof typeof SIZE
  children: ReactElement
}

const sizeStyle = (props: IconButtonProps) => {
  const size = SIZE[props.size ?? 'medium']

  if (!size) {
    return {}
  }

  return {
    width: size,
    height: size
  } as const
}

export const IconButton = forwardRef<IconButtonProps, 'button'>(
  ({ children, size, ...other }, ref) => (
    <Button
      ref={ref}
      __css={
        combineInterpolators(
          css({
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100%',
            paddingLeft: 0,
            paddingRight: 0
          }),
          sizeStyle as any
        ) as any
      }
      size={size as ButtonSizeVariant}
      paddingLeft={0}
      {...other}
    >
      {cloneElement(children as ReactElement, {
        size
      })}
    </Button>
  )
)

IconButton.defaultProps = {
  colorVariant: 'secondary',
  variant: 'filled',
  size: 'small'
}
