import React, { ReactNode } from 'react'
import { rem } from '@woorcs/utils'
import { animated, useSpring } from 'react-spring'

import { Box } from '../../layout'
import {
  Alert,
  AlertActionButton,
  AlertCloseButton,
  AlertMessage,
  AlertVariant
} from '../../feedback'
import { css } from '../../../system'

import { useAutoHide } from './useAutoHide'

export type SnackbarVariant = AlertVariant

export type SnackbarAction = {
  label: string
  onClick(): void
}

export interface SnackbarProps {
  visible?: boolean
  variant?: SnackbarVariant
  children: ReactNode
  action?: SnackbarAction
  autoHideDuration?: number
  onHide(): void
  onEntered?(): void
  onExited?(): void
}

export const Snackbar = ({
  children,
  variant = 'info',
  visible = false,
  autoHideDuration = 3000,
  action,
  onHide,
  onEntered,
  onExited
}: SnackbarProps) => {
  const autoHideProps = useAutoHide({
    visible,
    duration: autoHideDuration,
    onHide
  })
  const animatedStyle = useSpring({
    from: visible
      ? { opacity: 0, transform: `translate3d(0, 24px, 0)` }
      : { opacity: 1, transform: `translate3d(0, 0px, 0)` },
    opacity: visible ? 1 : 0,
    transform: `translate3d(0, ${visible ? 0 : 24}px, 0)`,
    onRest: () => {
      if (visible) {
        onEntered?.()

        return
      }

      onExited?.()
    }
  })

  return (
    <Box
      css={css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pb: 12,
        perspective: '800px',
        zIndex: 'snackbar',
        pointerEvents: 'none'
      })}
    >
      <animated.div style={{ ...animatedStyle, willChange: 'transform' }}>
        <Alert
          role='alert'
          __css={{
            boxShadow: 'large',
            transformOrigin: 'top center',
            width: rem(420),
            pointerEvents: 'auto'
          }}
          variant={variant}
          {...autoHideProps}
        >
          <AlertMessage>{children}</AlertMessage>
          {!action && <AlertCloseButton onClick={onHide} />}

          {action && (
            <AlertActionButton onClick={action.onClick}>
              {action.label}
            </AlertActionButton>
          )}
        </Alert>
      </animated.div>
    </Box>
  )
}
