import React, { FormEvent, ReactElement, useCallback, useState } from 'react'
import { pipe } from 'fp-ts/function'
import {
  Modal,
  FormField,
  PrimaryButton,
  SecondaryButton,
  Input,
  Box,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalDialog,
  ModalDisclosure
} from '@woorcs/design-system'
import { InspectionFormDefinition } from '@woorcs/inspection-form'
import { FormDocument, TranslateableText } from '@woorcs/form'

import { useEditorContext, useValue } from '../../state'

type AddDocumentModalFormProps = {
  onSubmit(title: string): void
}

const AddDocumentModalForm = ({ onSubmit }: AddDocumentModalFormProps) => {
  const [name, setTitle] = useState('')
  const handlenameChange = useCallback((e) => {
    const { value } = e.target

    setTitle(value)
  }, [])

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLElement>) => {
      e.preventDefault()

      onSubmit(name)
    },
    [onSubmit, name]
  )

  return (
    <form onSubmit={handleSubmit}>
      <ModalBody>
        <Box>
          <FormField label='Document name'>
            <Input name='Name' onChange={handlenameChange} />
          </FormField>
        </Box>
      </ModalBody>

      <ModalFooter>
        <ModalDisclosure>
          <SecondaryButton mr={2}>Cancel</SecondaryButton>
        </ModalDisclosure>
        <PrimaryButton onClick={handleSubmit}>Create document</PrimaryButton>
      </ModalFooter>
    </form>
  )
}

interface AddDocumentModalProps {
  onAddDocument?(document: FormDocument.FormDocument): void
  children: ReactElement
}

export const AddDocumentModal = ({
  onAddDocument,
  children
}: AddDocumentModalProps) => {
  const root = useValue()
  const { editor } = useEditorContext()
  const [visible, setVisible] = useState(false)

  const handleSubmit = useCallback(
    (name: string) => {
      const document = FormDocument.formDocument({
        title: TranslateableText.translateableText(name),
        type: 'GroupForm',
        children: []
      })

      const addDocument = pipe(
        root,
        InspectionFormDefinition.addFormDocument(document),
        editor.updateElement
      )

      setVisible(false)
      onAddDocument?.(document)
      addDocument()
    },
    [editor.updateElement, onAddDocument, root]
  )

  return (
    <Modal visible={visible} onVisiblityChange={setVisible}>
      <ModalDisclosure>{children}</ModalDisclosure>
      <ModalDialog>
        <ModalHeader width='420px'>
          <ModalTitle>Add document</ModalTitle>
        </ModalHeader>

        <AddDocumentModalForm onSubmit={handleSubmit} />
      </ModalDialog>
    </Modal>
  )
}
