import createStyled from '@emotion/styled'
import { isFunction, Dict } from '@woorcs/utils'
import * as A from 'fp-ts/Array'
import { pipe } from 'fp-ts/function'

import { Theme } from '../theme'

import { css } from './css'
import { SystemComponent } from './systemComponent'
import { shouldForwardProp } from './shouldForwardProps'
import { domElements, DOMElements } from './domElements'
import { systemProps } from './props'
import { As } from './forwardRef'
import { Interpolation } from './interpolation'

interface Options {
  shouldForwardProp?(prop: string): boolean
}

// eslint-disable-next-line @typescript-eslint/ban-types
const cast = <P = { theme: object }>(arg: any) => arg as Interpolation<P>

const __css = (props: Dict) => {
  const result = {} as Dict

  if (isFunction(props.__css)) {
    return props.__css(props)
  }

  for (const key in props.__css) {
    const exists = key in props

    if (!exists || props[key] == null) {
      result[key] = props.__css[key]
    }
  }

  return pipe(props.theme, css(result))
}

type CreateSystemStyledComponent = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  <T extends As>(component: T, options?: Options): <P = {}>(
    ...xs: Interpolation<P & { theme: Theme }>[]
  ) => SystemComponent<T, P>
}

// declare const f = CreateSystemStyledComponent<"div">

function systemStyled<T extends As>(component: T, options: Options = {}) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return <P = {}>(
    ...interpolators: Interpolation<P>[]
  ): SystemComponent<T, P> => {
    // ): SystemComponent<T, Omit<P, 'theme' | 'className'>> => {
    const styledComponent = createStyled(component as any, {
      ...options,
      shouldForwardProp
    })
    const styles = pipe(
      interpolators,
      A.map((style) => {
        if (!style) {
          return
        }

        return isFunction(style) ? style : css(style)
      })
    )

    return styledComponent(
      ...(styles as any),
      cast(__css) as any,
      systemProps
    ) as any
  }
}

type SystemJSXElements = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [Tag in DOMElements]: SystemComponent<Tag, {}>
}

export const system = systemStyled as unknown as CreateSystemStyledComponent &
  SystemJSXElements

domElements.forEach((tag) => {
  system[tag] = system(tag)()
})
