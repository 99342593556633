import React from 'react'
import { rem } from '@woorcs/utils'
import { PropsOf } from '@emotion/react'

import {
  Modal,
  ModalProps,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalDialog,
  ModalDisclosure,
  ModalDisclosureProps
} from '../Modal'
import { H4 } from '../../typography'

export type AlertDialogProps = ModalProps

export const AlertDialog = (props: ModalProps) => <Modal {...props} />

export const AlertDialogContent = (props: AlertDialogProps) => (
  <ModalDialog role='alertdialog' css={{ maxWidth: rem(448) }} {...props} />
)

export const AlertDialogDisclosure = (props: ModalDisclosureProps) => (
  <ModalDisclosure {...props} />
)

export const AlertDialogHeader = ModalHeader
export const AlertDialogTitle = (props: PropsOf<typeof H4>) => <H4 {...props} />
export const AlertDialogBody = ModalBody
export const AlertDialogFooter = ModalFooter
