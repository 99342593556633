/* eslint-disable max-lines */
import React, { ReactElement, ReactNode, useCallback, useState } from 'react'
import { pipe, identity, constNull } from 'fp-ts/function'
import {
  Modal,
  PrimaryButton,
  SecondaryButton,
  Box,
  ModalDisclosure,
  Navbar,
  Flex,
  ProgressBar,
  Button,
  ChevronDownIcon,
  Text,
  Popover,
  PopoverDisclosure,
  PopoverContent,
  AbsoluteFill,
  ChevronLeftIcon,
  Card,
  H4,
  H5
} from '@woorcs/design-system'
import {
  InspectionFormDefinition,
  InspectionFormDocument,
  InspectionFormSubmissionRenderer
} from '@woorcs/inspection-form'
import {
  InspectionFormProvider,
  useInspectionFormEnvironment,
  useInspectionFormProgram
} from '@woorcs/inspection-form/react'
import {
  FormDocument,
  FormElement,
  FormEnvironment,
  FormSubmissionData
} from '@woorcs/form'
import { RenderFormElement } from '@woorcs/form/react-dom'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Reader'
import * as RA from 'fp-ts/ReadonlyArray'
import { UUID } from '@woorcs/types/UUID'
import { DateRange } from '@woorcs/types'
import { format } from 'date-fns'

import { FullScreenModalDialog } from '../FullScreenModalDialog'
import { SelectPageMenuList } from '../SelectPageMenu'

const getVisiblePages = (
  definition: InspectionFormDefinition.InspectionFormDefinition
) =>
  pipe(
    definition.children,
    R.traverseArray(InspectionFormDocument.renderPage(identity)),
    R.map((children) => pipe(children, RA.filterMap(identity)))
  )

export const useInspectionFormPages = (
  definition: InspectionFormDefinition.InspectionFormDefinition
) => useInspectionFormProgram(getVisiblePages(definition))

interface PreviewFormPageProps {
  page: InspectionFormDocument.InspectionFormPageElement
}

const renderElement = (element: FormElement.FormElementType) => (
  <Box
    key={element.id}
    style={{
      marginBottom: 24
    }}
  >
    <RenderFormElement element={element} />
  </Box>
)

const renderChildren = (
  children: FormElement.FormElement[]
): R.Reader<FormEnvironment, ReactNode> =>
  pipe(
    children,
    R.traverseArray((element) =>
      pipe(
        element as FormElement.FormElementType,
        FormElement.renderElement(renderElement),
        R.map(O.getOrElseW(constNull))
      )
    )
  )

const PreviewFormPage = ({ page }: PreviewFormPageProps) => {
  const elements = useInspectionFormProgram(renderChildren(page.children))

  return (
    <Box>
      <form>{elements}</form>
    </Box>
  )
}

type SelectPageMenuProps = {
  pages: InspectionFormDocument.InspectionFormPageElement[]
  selectedID: UUID
  onSelectPage(
    page: InspectionFormDocument.InspectionFormPageElement,
    index: number
  ): void
  children: ReactElement
}

const SelectPageMenu = ({
  pages,
  selectedID,
  onSelectPage,
  children
}: SelectPageMenuProps) => {
  return (
    <Popover placement='bottom'>
      {({ hide }) => (
        <>
          <PopoverDisclosure>{children}</PopoverDisclosure>
          <PopoverContent aria-label='Select page' width={480} p={5}>
            <SelectPageMenuList
              pages={pages}
              selectedID={selectedID}
              onSelectPage={(page, index) => {
                hide()
                onSelectPage(page, index)
              }}
            />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}

interface PreviewFormTitleProps {
  pages: InspectionFormDocument.InspectionFormPageElement[]
  definition: InspectionFormDefinition.InspectionFormDefinition
  page: InspectionFormDocument.InspectionFormPageElement
  onPageChange(
    page: InspectionFormDocument.InspectionFormPageElement,
    index: number
  ): void
}

const PreviewFormTitle = ({
  pages,
  definition,
  page,
  onPageChange
}: PreviewFormTitleProps) => {
  return (
    <SelectPageMenu
      pages={pages}
      selectedID={page.id}
      onSelectPage={onPageChange}
    >
      <Button variant='plain'>
        <Flex alignItems='center'>
          <Text fontWeight='regular' fontSize='base' color='text.base'>
            {FormDocument.getDefaultTitle(definition)}
          </Text>
          <Text mx={2} color='text.base' fontWeight='regular' fontSize='base'>
            /
          </Text>
          <Text fontWeight='bold' color='text.base' fontSize='base'>
            {FormDocument.getDefaultTitle(page)}
          </Text>
          <ChevronDownIcon ml={2} color='text.base' />
        </Flex>
      </Button>
    </SelectPageMenu>
  )
}

interface PreviewFormWizardProps {
  definition: InspectionFormDefinition.InspectionFormDefinition
  currentPageIndex: number
  onNext(): void
  onPrev(): void
}

const PreviewFormWizard = ({
  definition,
  currentPageIndex,
  onNext,
  onPrev
}: PreviewFormWizardProps) => {
  const pages = useInspectionFormPages(definition)

  return (
    <>
      <Flex
        justifyContent='center'
        py={8}
        style={{ overflowY: 'auto' }}
        height='100%'
        width='100%'
        position='relative'
      >
        <Box width={640} pb={8}>
          {pipe(
            pages,
            RA.lookup(currentPageIndex),
            O.fold(
              () => null,
              (page) => <PreviewFormPage page={page} />
            )
          )}
        </Box>
      </Flex>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          width: '100%'
        }}
      >
        <Flex
          width={960}
          p={4}
          bg='white'
          justifyContent='space-between'
          alignItems='center'
          flexShrink={0}
        >
          <Flex flexDirection='row' alignItems='center' width='100%'>
            <Text as='p' mr={4} flexShrink={0} fontWeight='bold'>
              {currentPageIndex + 1} / {pages.length}
            </Text>
            <ProgressBar
              value={currentPageIndex / (pages.length - 1)}
              mr={12}
            />
          </Flex>
          <Flex>
            <PrimaryButton
              mr={2}
              disabled={currentPageIndex === 0}
              onClick={onPrev}
            >
              Back
            </PrimaryButton>
            <SecondaryButton onClick={onNext}>Next</SecondaryButton>
          </Flex>
        </Flex>
      </Box>
    </>
  )
}

interface TextValueProps {
  children: ReactNode
}

const TextValue = ({ children }: TextValueProps) => (
  <Text variant='body'>{children}</Text>
)

// eslint-disable-next-line complexity
export const renderInputElementResponse = <
  T extends FormElement.InputElementType
>(
  element: T,
  value: O.Option<FormElement.ValueOfInputElement<T>>
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderElement = (value: any) =>
    FormElement.InputElementType.match({
      TextInput: () => <TextValue>{value}</TextValue>,
      DateInput: (element) => {
        const formatting = element.options.withTime
          ? 'yyyy-MM-dd'
          : 'yyyy-MM-dd kk:mm'
        const date = format(new Date(value), formatting)

        return <TextValue>{date}</TextValue>
      },
      DateRangeInput: () => {
        const range = value as DateRange
        const formatting = 'yyyy-MM-dd'
        const fromDate =
          value && range.from ? format(new Date(range.from), formatting) : ''
        const toDate =
          value && range.to ? format(new Date(range.to), formatting) : ''

        return (
          <TextValue>
            {fromDate} - {toDate}
          </TextValue>
        )
      },
      TimeInput: () => {
        try {
          const date = format(new Date(value as string), 'kk:mm')

          return <TextValue>{date}</TextValue>
        } catch (error) {
          return <TextValue>{value}</TextValue>
        }
      },
      SelectInput: () => <TextValue>{value}</TextValue>,
      MultiSelectInput: () => {
        if (!Array.isArray(value)) {
          return <TextValue>-</TextValue>
        }

        return (
          <>
            {value.map((value: string, index: number) => (
              <Box key={index}>
                <TextValue>{value}</TextValue>
              </Box>
            ))}
          </>
        )
      },
      SignatureInput: () => <TextValue>{value}</TextValue>,
      ImageInput: () => {
        if (Array.isArray(value)) {
          return (
            <>
              {value.map((src, index) => (
                <Box key={index}>
                  <TextValue>{src}</TextValue>
                </Box>
              ))}
            </>
          )
        }

        return <TextValue>{value}</TextValue>
      },
      NumberInput: () => <TextValue>{value}</TextValue>,
      EmailInput: () => <TextValue>{value}</TextValue>,
      LocationInput: () => null,
      GroupInput: () => null
    })

  return pipe(
    value,
    O.fold(
      () => <TextValue>-</TextValue>,
      (value) => pipe(element, renderElement(value))
    )
  )
}

type PreviewFormSummaryProps = {
  formDefinition: InspectionFormDefinition.InspectionFormDefinition
  data: FormSubmissionData
}

export const PreviewFormSummary = ({
  formDefinition,
  data
}: PreviewFormSummaryProps) =>
  InspectionFormSubmissionRenderer.getInspectionFormSubmissionRenderer<ReactElement>(
    {
      definition: formDefinition,
      data,
      renderer: {
        renderRoot: (pages) => <Box py={8}>{pages}</Box>,
        renderDocument: (_, children) => (
          <Box bg='grey.50' borderRadius='medium' p={4}>
            {children}
          </Box>
        ),
        renderPage: (page, title, children) => (
          <Card key={page.key} title={title} width={540} mb={4} p={4}>
            <H5 mb={4}>{title}</H5>
            {children}
          </Card>
        ),
        renderInputElement: (element, label, value) => (
          <Box key={element.id} mb={4}>
            <Box mb={2}>
              <Text variant='body' fontWeight='bold'>
                {label}
              </Text>
            </Box>
            {renderInputElementResponse(element, value)}
          </Box>
        ),
        renderLayoutElement: (_, children) => <>{children}</>,
        renderGroupInputElement: (element, children) => (
          <Box key={element.id} p={2}>
            <Box bg='greyLighter' borderRadius='m' p={2}>
              <Text variant='subtitle2'>{element.label.text}</Text>
              <Box>{children}</Box>
            </Box>
          </Box>
        )
      }
    }
  )

type FormStatus = 'intro' | 'submitting' | 'completed'

interface PreviewFormProps {
  backButtonLabel: string
  definition: InspectionFormDefinition.InspectionFormDefinition
}

const PreviewForm = ({ definition, backButtonLabel }: PreviewFormProps) => {
  const [status, setStatus] = useState<FormStatus>('intro')
  const [data, environment] = useInspectionFormEnvironment(definition, {}, 'no')
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const pages = getVisiblePages(definition)(environment)

  const handleNext = useCallback(() => {
    const next = Math.min(currentPageIndex + 1, pages.length - 1)

    if (currentPageIndex === pages.length - 1) {
      setStatus('completed')

      return
    }

    setCurrentPageIndex(next)
  }, [currentPageIndex, pages.length])

  const handlePrev = useCallback(() => {
    setCurrentPageIndex(Math.max(0, currentPageIndex - 1))
  }, [currentPageIndex])

  const renderStatus = (status: FormStatus) => {
    switch (status) {
      case 'intro': {
        return (
          <Flex
            flexDirection='column'
            height='100vh'
            alignItems='center'
            justifyContent='center'
          >
            <Card
              display='flex'
              flexDirection='column'
              p={5}
              width={540}
              minHeight={320}
            >
              <Box flex={1} mb={4}>
                <H4 mb={2}>{definition.title.text}</H4>
                <Text>{definition.description.text}</Text>
              </Box>
              <Flex justifyContent='flex-end'>
                <Button onClick={() => setStatus('submitting')}>Start</Button>
              </Flex>
            </Card>
          </Flex>
        )
      }

      case 'submitting': {
        return (
          <PreviewFormWizard
            currentPageIndex={currentPageIndex}
            definition={definition}
            onNext={handleNext}
            onPrev={handlePrev}
          />
        )
      }

      case 'completed': {
        return (
          <Flex
            justifyContent='center'
            py={8}
            style={{ overflowY: 'auto' }}
            height='100%'
            width='100%'
            position='relative'
          >
            <PreviewFormSummary
              formDefinition={definition}
              data={data}
              {...environment}
            />
          </Flex>
        )
      }
    }
  }

  return (
    <InspectionFormProvider {...environment}>
      <Flex
        flexDirection='column'
        bg='grey.50'
        height='100vh'
        alignItems='center'
      >
        <Box style={{ width: '100%' }}>
          <Navbar
            bg='white'
            flexShrink={0}
            position='relative'
            borderBottom='base'
            width='100%'
          >
            <ModalDisclosure>
              <SecondaryButton leftIcon={<ChevronLeftIcon />}>
                {backButtonLabel}
              </SecondaryButton>
            </ModalDisclosure>

            {pipe(
              pages,
              RA.lookup(currentPageIndex),
              O.fold(
                () => null,
                (page) => (
                  <AbsoluteFill
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    style={{
                      pointerEvents: 'none'
                    }}
                  >
                    <Box style={{ pointerEvents: 'auto' }}>
                      <PreviewFormTitle
                        definition={definition}
                        pages={RA.toArray(pages)}
                        page={page}
                        onPageChange={(_, index) => {
                          setStatus('submitting')
                          setCurrentPageIndex(index)
                        }}
                      />
                    </Box>
                  </AbsoluteFill>
                )
              )
            )}
          </Navbar>
        </Box>
        {renderStatus(status)}
      </Flex>
    </InspectionFormProvider>
  )
}

interface PreviewFromModalProps {
  definition: InspectionFormDefinition.InspectionFormDefinition
  backButtonLabel: string
  children: ReactElement
}

export const PreviewFormModal = ({
  definition,
  backButtonLabel,
  children
}: PreviewFromModalProps) => {
  const [visible, setVisible] = useState(false)

  return (
    <Modal visible={visible} onVisiblityChange={setVisible}>
      <ModalDisclosure>{children}</ModalDisclosure>
      <FullScreenModalDialog>
        <PreviewForm
          definition={definition}
          backButtonLabel={backButtonLabel}
        />
      </FullScreenModalDialog>
    </Modal>
  )
}
