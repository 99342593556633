import React, { ChangeEvent, useCallback } from 'react'
import { Flex, FormField, FormFieldLabel, Switch } from '@woorcs/design-system'
import * as Lens from 'monocle-ts/Lens'
import { FormElement, TranslateableText } from '@woorcs/form'
import { pipe } from 'fp-ts/function'

import { useEditorContext } from '../../state'

import { BlurInput } from './BlurInput'

export function useElementField<E extends FormElement.FormElement, A>(
  element: E,
  lens: Lens.Lens<E, A>
) {
  const { editor } = useEditorContext()

  const handleChange = useCallback(
    (value: A) => {
      const update = pipe(element, lens.set(value), editor.updateElement)

      update()
    },
    [editor.updateElement, element, lens]
  )

  return [lens.get(element), handleChange] as const
}

interface TextFieldProps<E extends FormElement.FormElement> {
  label: string
  element: E
  lens: Lens.Lens<E, string | null>
}

export const TextField = <E extends FormElement.FormElement>({
  label,
  element,
  lens
}: TextFieldProps<E>) => {
  const [value, setValue] = useElementField(element, lens)

  return (
    <FormField label={label} mb={4}>
      <BlurInput value={value ?? ''} onChange={setValue} />
    </FormField>
  )
}

interface NumberFieldProps<E extends FormElement.FormElement> {
  label: string
  element: E
  lens: Lens.Lens<E, number | null>
}

export const NumberField = <E extends FormElement.FormElement>({
  label,
  element,
  lens
}: NumberFieldProps<E>) => {
  const [value, setValue] = useElementField(element, lens)

  const handleChange = useCallback(
    (value) => {
      setValue(Number(value))
    },
    [setValue]
  )

  return (
    <FormField label={label} mb={4}>
      <BlurInput type='number' value={String(value)} onChange={handleChange} />
    </FormField>
  )
}

interface TranslateableTextFieldProps<E extends FormElement.FormElement> {
  label: string
  element: E
  lens: Lens.Lens<E, TranslateableText.TranslateableText>
}

export const TranslateableTextField = <E extends FormElement.FormElement>({
  label,
  element,
  lens
}: TranslateableTextFieldProps<E>) => {
  const [value, setValue] = useElementField(element, lens)

  const handleChange = useCallback(
    (text: string) => {
      const translated = pipe(value, TranslateableText.setText(text))

      setValue(translated)
    },
    [value, setValue]
  )

  return (
    <FormField label={label} mb={4}>
      <BlurInput value={value?.text} onChange={handleChange} />
    </FormField>
  )
}

interface BooleanFieldProps<E extends FormElement.FormElement, A> {
  label: string
  element: E
  lens: Lens.Lens<E, A>
}

export const BooleanField = <E extends FormElement.FormElement>({
  label,
  element,
  lens
}: BooleanFieldProps<E, boolean>) => {
  const [value, setValue] = useElementField(element, lens)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.checked)
    },
    [setValue]
  )

  return (
    <Flex justifyContent='space-between' alignItems='center' mb={4}>
      <FormFieldLabel mb={0}>{label}</FormFieldLabel>
      <Switch checked={value} onChange={handleChange} />
    </Flex>
  )
}
