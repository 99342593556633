import React, { memo } from 'react'
import * as O from 'fp-ts/Option'
import { pipe, constNull, constFalse, identity } from 'fp-ts/function'
import { UUID } from '@woorcs/types/UUID'
import { FormElement } from '@woorcs/form'
import { motion, AnimateSharedLayout } from 'framer-motion'
import { useBoolean } from '@woorcs/hooks'

import { useElementPath } from '../../state'

import { ElementListSeparator } from './Separator'
import { SortableElementListItem } from './ListItem'

type ElementProps = {
  element: FormElement.FormElement
  isSelected: boolean
  onClick(questionID: UUID): void
  onEdit(questionID: UUID): void
}

const Element = memo(
  ({ element, isSelected, onClick, onEdit }: ElementProps) => {
    const [isAnimating, { on, off }] = useBoolean(false)

    return pipe(
      useElementPath(element),
      O.fold(constNull, (path) => (
        <motion.div
          key={element.id}
          layout
          onAnimationStart={on}
          onAnimationEnd={off}
        >
          <SortableElementListItem
            key={element.id}
            element={element as FormElement.FormElementType}
            isSelected={isSelected}
            isAnimating={isAnimating}
            path={path}
            onClick={onClick}
            onEdit={onEdit}
          />
          <ElementListSeparator key={`${element.id}-separator`} path={path} />
        </motion.div>
      ))
    )
  }
)

type ElementListProps = {
  elements: FormElement.FormElement[]
  selectedElementId?: O.Option<UUID>
  onEditElement(questionID: UUID): void
  onElementClick(questionID: UUID): void
}

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.125
    }
  }
}

export const ElementList = ({
  elements,
  selectedElementId = O.none,
  onEditElement,
  onElementClick
}: ElementListProps) => {
  const isElementSelected = (id: UUID) =>
    pipe(
      selectedElementId,
      O.map((selectedID) => selectedID === id),
      O.fold(constFalse, identity)
    )

  return (
    <AnimateSharedLayout>
      <motion.div variants={container} initial='hidden' animate='show' layout>
        {elements.map((element) => (
          <Element
            key={element.id}
            element={element}
            isSelected={isElementSelected(element.id)}
            onEdit={onEditElement}
            onClick={onElementClick}
          />
        ))}
      </motion.div>
    </AnimateSharedLayout>
  )
}
