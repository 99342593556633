import React, { ReactElement, useCallback, useState } from 'react'
import {
  PrimaryButton,
  SecondaryButton,
  DialogSidebar,
  DialogSidebarDisclosure,
  DialogSidebarContent,
  Box,
  H4,
  Body
} from '@woorcs/design-system'
import { UUID } from '@woorcs/types/UUID'
import { ElementRule } from '@woorcs/form'

import { ElementRuleForm } from '../ElementRoleForm'

const defaultInitialValue: ElementRule.ElementRule = {
  effect: 'hide',
  condition: {
    _tag: 'and',
    conditions: [
      {
        _tag: 'leaf',
        operator: 'eq',
        key: '' as UUID,
        expectedValue: ''
      }
    ]
  }
}

interface QuestionRuleFormModalFormProps {
  initialValue?: ElementRule.ElementRule
  onSubmit(rule: ElementRule.ElementRule): void
}

const QuestionRuleFormModalForm = ({
  initialValue = defaultInitialValue,
  onSubmit
}: QuestionRuleFormModalFormProps) => {
  const [value, setValue] = useState<ElementRule.ElementRule>(initialValue)

  const handleSubmit = useCallback(() => {
    onSubmit(value)
  }, [onSubmit, value])

  return (
    <>
      <Box bg='grey.50' borderRadius='medium' mb={8} p={4}>
        <ElementRuleForm rule={value} onChange={setValue} />
      </Box>

      <Box>
        <DialogSidebarDisclosure>
          <SecondaryButton mr={2}>Cancel</SecondaryButton>
        </DialogSidebarDisclosure>
        <PrimaryButton onClick={handleSubmit}>Save rule</PrimaryButton>
      </Box>
    </>
  )
}

interface QuestionRuleFormModalProps {
  // question: AsEditorElement<QuestionElement>
  children: ReactElement
  initialValue?: ElementRule.ElementRule
  onSubmit(rule: ElementRule.ElementRule): void
}

export const QuestionRuleFormModal = ({
  children,
  initialValue,
  onSubmit
}: QuestionRuleFormModalProps) => {
  const [visible, setVisible] = useState(false)

  const handleSubmit = useCallback(
    (rule: ElementRule.ElementRule) => {
      onSubmit(rule)
      setVisible(false)
    },
    [onSubmit]
  )

  return (
    <DialogSidebar
      visible={visible}
      placement='right'
      onVisiblityChange={setVisible}
    >
      <DialogSidebarDisclosure>{children}</DialogSidebarDisclosure>
      <DialogSidebarContent>
        <Box px={5} pt={8} pb={5}>
          <Box mb={8}>
            <H4 mb={2}>Element rule</H4>
            <Body>
              {`Create conditional rules to dynamically change the element's behaviour.`}
            </Body>
          </Box>
          <QuestionRuleFormModalForm
            initialValue={initialValue}
            onSubmit={handleSubmit}
          />
        </Box>
      </DialogSidebarContent>
    </DialogSidebar>
  )
}
