import React, { MutableRefObject, ReactNode } from 'react'
import { isNotNil } from '@woorcs/utils'

import { system, splitStyledProps, forwardRef, PropsOf } from '../../../system'
import { UseFormFieldProps } from '../FormField'

import { InputSize, InputVariant } from './variants'
import { InputGroup } from './InputGroup'
import { InputAddon } from './InputAddon'
import { useInput } from './useInput'
import { InputElement } from './InputElement'

type SystemInputProps = PropsOf<typeof system.input>

export interface InputProps<T extends HTMLElement = HTMLInputElement>
  extends SystemInputProps,
    Omit<UseFormFieldProps<T>, 'color'> {
  id?: string
  name?: string
  type?: string
  variant?: InputVariant
  size?: InputSize
  leftAddon?: ReactNode
  rightAddon?: ReactNode
  containerRef?: MutableRefObject<HTMLDivElement | null>
}

export const Input = forwardRef<InputProps, 'input'>(
  (
    {
      as,
      name,
      value,
      type = 'text',
      variant = 'outlined',
      size = 'medium',
      leftAddon,
      rightAddon,
      containerRef,
      ...other
    },
    ref
  ) => {
    const [styleProps, inputProps] = splitStyledProps(other)
    const { getGroupProps, getInputProps } = useInput(ref, {
      ...inputProps,
      variant,
      size
    })

    const hasLeftAddon = isNotNil(leftAddon)
    const hasRightAddon = isNotNil(rightAddon)

    return (
      <InputGroup ref={containerRef} {...styleProps} {...getGroupProps()}>
        {hasLeftAddon && <InputAddon mr={2}>{leftAddon}</InputAddon>}
        <InputElement
          as={as}
          name={name}
          value={value}
          type={type}
          {...getInputProps()}
        />
        {hasRightAddon && <InputAddon ml={2}>{rightAddon}</InputAddon>}
      </InputGroup>
    )
  }
)
