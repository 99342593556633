import React, { ComponentProps } from 'react'
import { space } from '@woorcs/utils'

import { Input } from '../Input'
import { forwardRef, system } from '../../../system'

const TextareaInput = system('textarea')({
  height: space(20),
  my: 3,
  resize: 'none'
})

type TextareaProps = ComponentProps<typeof Input>

export const Textarea = forwardRef<TextareaProps, 'textarea'>((props, ref) => (
  <Input ref={ref} as={TextareaInput} {...props} />
))
