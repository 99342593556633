import React, { ReactNode } from 'react'

import { DialogBackdrop } from '../DialogBackdrop'

import { useModalBackdrop, UseModalBackdropProps } from './useModal'

interface ModalBackdropProps extends UseModalBackdropProps {
  centerContent?: boolean
  children: ReactNode
}

export const ModalBackdrop = (props: ModalBackdropProps) => {
  const backdropProps = useModalBackdrop(props)

  return <DialogBackdrop {...backdropProps} />
}
