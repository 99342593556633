import React, { ReactElement } from 'react'

import { PopoverDisclosure } from '../../overlays'

import { useSelectDisclosure } from './useSelectState'

interface SelectDisclosureProps {
  children: ReactElement
}

export const SelectDisclosure = ({ children }: SelectDisclosureProps) => {
  const state = useSelectDisclosure()

  return <PopoverDisclosure {...state}>{children}</PopoverDisclosure>
}
