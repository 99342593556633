import { variant } from 'styled-system'

import { system, PropsOf, css } from '../../../system'

type BadgeVariant = 'info' | 'grey' | 'success' | 'warning' | 'danger'
type BadgeSizeVariant = 'medium' | 'large'

interface Props extends PropsOf<typeof system.div> {
  variant?: BadgeVariant
  size?: BadgeSizeVariant
}

const colorVariant = variant({
  prop: 'variant',
  variants: {
    info: {
      color: 'primary.500',
      backgroundColor: 'primary.50'
      // borderColor: 'primary.500'
    },
    grey: {
      color: 'grey.500',
      backgroundColor: 'grey.50',
      borderColor: 'grey.500'
    },
    success: {
      color: 'secondary.700',
      backgroundColor: 'secondary.50'
    },
    warning: {
      color: 'accent.500',
      backgroundColor: 'accent.50'
    },
    danger: {
      color: 'red.400',
      backgroundColor: 'red.50'
    }
  }
})

export const Badge = system('div')<Props>(
  css({
    display: 'inline-flex',
    alignItems: 'center',
    fontWeight: 'semi-bold',
    // border: 'thin',
    borderRadius: 'small'
  }),
  variant({
    prop: 'size',
    variants: {
      medium: {
        px: 2,
        py: 1,
        fontSize: 'mini'
      },
      large: {
        px: 4,
        py: 2,
        fontSize: 'mini'
      }
    }
  }),
  colorVariant
)

Badge.defaultProps = {
  variant: 'info',
  size: 'medium'
}
