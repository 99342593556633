import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type FormRevisionFragment = { __typename: 'FormRevision', id: string, status: SchemaTypes.FormRevisionStatus, version: number, publishedAt: any | null, createdAt: any };

export type FormFragment = { __typename: 'Form', id: string, title: string, editable: boolean, createdAt: any, active: { __typename: 'FormRevision', id: string, status: SchemaTypes.FormRevisionStatus, version: number, publishedAt: any | null, createdAt: any } | null, revisions: Array<{ __typename: 'FormRevision', id: string, status: SchemaTypes.FormRevisionStatus, version: number, publishedAt: any | null, createdAt: any }>, latestRevision: { __typename: 'FormRevision', id: string, status: SchemaTypes.FormRevisionStatus, version: number, publishedAt: any | null, createdAt: any } };

export const FormRevisionFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormRevisionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FormRevision"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"version"}},{"kind":"Field","name":{"kind":"Name","value":"publishedAt"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}}]}}]} as unknown as DocumentNode<FormRevisionFragment, unknown>;
export const FormFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FormFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Form"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}},{"kind":"Field","name":{"kind":"Name","value":"editable"}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"}},{"kind":"Field","name":{"kind":"Name","value":"active"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormRevisionFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"revisions"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormRevisionFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"latestRevision"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FormRevisionFragment"}}]}}]}},...FormRevisionFragmentDoc.definitions]} as unknown as DocumentNode<FormFragment, unknown>;