import { UpdateResolver, Cache } from '@urql/exchange-graphcache'
import { constant, pipe, constNull } from 'fp-ts/function'
import { Endomorphism } from 'fp-ts/Endomorphism'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as IOO from 'fp-ts-contrib/IOOption'
import * as Optional from 'monocle-ts/Optional'

import { superuserListQueryResponseOptional } from '../components'
import { SuperuserListUserFragment } from '../components/SuperuserList/__generated__/SuperuserList'
import {
  RemoveSuperuserMutation,
  RemoveSuperuserMutationVariables,
  SuperUserListQueryDocument
} from '../pages/superusers/__generated__/SuperUsersPage'

const updateSuperuserListResponseData = (
  update: Endomorphism<SuperuserListUserFragment[]>
) => pipe(superuserListQueryResponseOptional, Optional.modify(update))

const removeUser = (accountId: string) =>
  pipe(
    updateSuperuserListResponseData((data) => {
      return data.filter((user) => accountId !== user.account.id)
    })
  )

const removeSuperuser: UpdateResolver<
  RemoveSuperuserMutation,
  RemoveSuperuserMutationVariables
> = (_, { input: { accountId } }, cache) => {
  cache.updateQuery({ query: SuperUserListQueryDocument }, (superuserList) =>
    pipe(
      O.fromNullable(superuserList),
      O.map(removeUser(accountId)),
      O.getOrElseW(constNull)
    )
  )
}

export const updates = {
  Mutation: {
    removeSuperuser
  },
  Subscription: {}
}
