import React, { useMemo } from 'react'
import { Box, Flex, Text, Body } from '@woorcs/design-system'
import * as number from 'fp-ts/number'
import * as A from 'fp-ts/Array'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as O from 'fp-ts/Option'
import { pipe, constant } from 'fp-ts/function'
import { Ordering } from 'fp-ts/Ordering'

import { Trend, TrendChart, TrendData } from './Chart'

interface TrendWidgetProps {
  data: TrendData
  title: string
}

/**
 * ensures that the data is always an array containing 7 items
 */
const populateData = (data: TrendData) =>
  pipe(
    7,
    NEA.makeBy((index) =>
      pipe(data, A.reverse, A.lookup(index), O.getOrElse(constant(0)))
    )
  )

const orderingToTrend = (ord: Ordering): Trend => {
  if (ord === 1) {
    return 'positive'
  }

  if (ord === -1) {
    return 'negative'
  }

  return 'neutral'
}

export const TrendWidget = ({ title, data }: TrendWidgetProps) => {
  const populatedData = useMemo(() => populateData(data), [data])
  const today = NEA.head(populatedData)
  const yesterday = pipe(populatedData, A.lookup(1), O.getOrElse(constant(0)))
  const differenceToYesterday = Math.round((today / yesterday) * 100)
  const trend = pipe(number.Ord.compare(today, yesterday), orderingToTrend)

  const getLabel = () => {
    switch (trend) {
      case 'positive': {
        if (yesterday === 0) {
          return null
        }

        return (
          <>
            <Text color='secondary.500' fontWeight='bold'>
              {differenceToYesterday}%
            </Text>
            {` more than yesterday`}
          </>
        )
      }

      case 'negative': {
        return (
          <>
            <Text color='orange.500' fontWeight='bold'>
              {differenceToYesterday}%
            </Text>
            {` less than yesterday`}
          </>
        )
      }

      case 'neutral': {
        return 'Same as yesterday'
      }
    }
  }

  const getBg = () => {
    switch (trend) {
      case 'positive': {
        return 'secondary.50'
      }

      case 'negative': {
        return 'orange.50'
      }

      case 'neutral': {
        return 'primary.50'
      }
    }
  }

  return (
    <Flex
      flexDirection='column'
      justifyContent='space-between'
      p={6}
      borderRadius='large'
      bg={getBg()}
      width={1 / 3}
      height='300px'
      mr={6}
    >
      <Box>
        <Text as='h3' fontSize='medium' fontWeight='regular'>
          {title}
        </Text>
        <Text fontSize='h3' fontWeight='extra-bold'>
          {today}
        </Text>
      </Box>

      <Flex justifyContent='space-between' alignItems='flex-end'>
        <Body>{getLabel()}</Body>

        <TrendChart data={populatedData} trend={trend} />
      </Flex>
    </Flex>
  )
}
