import React from 'react'
import { Dialog } from 'reakit/Dialog'
import { space, rem } from '@woorcs/utils'
import { useSpring } from 'react-spring'

import { CloseButton } from '../../buttons'
import { DialogBackdrop } from '../DialogBackdrop'

import { useDialogSidebarContext } from './useDialogSidebar'
import {
  DialogSidebarContainer,
  DialogSidebarContainerProps
} from './DialogSidebarContainer'

interface DialogSidebarContentProps extends DialogSidebarContainerProps {
  showCloseButton?: boolean
}

export const DialogSidebarContent = ({
  showCloseButton = true,
  children,
  ...props
}: DialogSidebarContentProps) => {
  const { state, getBackdropProps, getDialogProps, placement } =
    useDialogSidebarContext()
  const animatedStyle = useSpring({
    // opacity: state.visible ? 1 : 0,
    from: state.visible
      ? {
          transform: `translate3d(100%, 0, 0)`
        }
      : { transform: `translate3d(0%, 0, 0)` },
    transform: `translate3d(${state.visible ? 0 : 100}%, 0, 0)`,
    onRest: state.stopAnimation
  })

  return (
    <DialogBackdrop
      css={{
        flexDirection: 'row',
        justifyContent: placement === 'left' ? 'flex-start' : 'flex-end',
        padding: 0
      }}
      {...getBackdropProps()}
    >
      <Dialog {...getDialogProps()}>
        {(dialogProps: any) =>
          state.visible && (
            <DialogSidebarContainer
              __css={{
                transformOrigin: 'left center',
                willChange: 'transform',
                width: rem(540),
                height: '100vh'
              }}
              style={animatedStyle}
              {...props}
              {...dialogProps}
            >
              {children}

              {showCloseButton && (
                <CloseButton
                  position='absolute'
                  top={space(2)}
                  right={space(2)}
                  onClick={state.hide}
                />
              )}
            </DialogSidebarContainer>
          )
        }
      </Dialog>
    </DialogBackdrop>
  )
}
