import React, { MouseEvent, Suspense, useCallback } from 'react'
import {
  FilterButton as StyledFilterButton,
  FilterButtonLabel,
  CloseIcon,
  IconButton,
  CalendarIcon,
  DateRangeTuple,
  Avatar,
  FormIcon,
  Flex,
  Text,
  FlexProps,
  useTheme
} from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import * as A from 'fp-ts/Array'
import * as DE from '@nll/datum/DatumEither'
import { pipe, constNull } from 'fp-ts/function'
import * as Optional from 'monocle-ts/Optional'
import { NonEmptyArray } from 'fp-ts/NonEmptyArray'
import { queryToDatumEither } from '@woorcs/graphql'
import { useQuery } from 'urql'

import { FilterConfig } from '../../types'
import { TagAvatar, TagAvatarProps } from '../../../TagAvatar'
import { SelectUserModal } from '../../../SelectUserModal'
import { SelectTagsModal } from '../../../SelectTagsModal'
import { SelectFormModal } from '../../../SelectFormModal'
import { DateRangeFilterMenu } from '../../../DateRangeFilter'

import {
  FormFilterButtonQuery,
  FormFilterButtonQueryDocument,
  TagFilterButtonQuery,
  TagFilterButtonQueryDocument,
  UserFilterButtonQuery,
  UserFilterButtonQueryDocument
} from './__generated__/FilterButton'

interface UserFilterButtonProps {
  value: string
  onChange(value: O.Option<string>): void
  onRemove(): void
}

const userButtonResponseOptional = pipe(
  Optional.id<UserFilterButtonQuery>(),
  Optional.prop('user'),
  Optional.fromNullable
)

const UserFilterButton = ({
  value,
  onChange,
  onRemove
}: UserFilterButtonProps) => {
  const query = useQuery({
    query: UserFilterButtonQueryDocument,
    variables: {
      id: value
    }
  })
  const handleSelectUser = useCallback(
    (user: { id: string }) => {
      onChange(O.some(user.id))
    },
    [onChange]
  )

  const handleRemoveClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      onRemove()
    },
    [onRemove]
  )

  return pipe(
    query,
    queryToDatumEither(userButtonResponseOptional),
    DE.squash(constNull, constNull, (user) => (
      <SelectUserModal
        selectedId={O.some(value)}
        searchPlaceholder='Filter by submitter'
        onSelect={handleSelectUser}
      >
        <StyledFilterButton mr={4}>
          <FilterButtonLabel>
            {/* <UserIcon size='small' mr={2} /> */}
            <Avatar
              size='small'
              name={user.name}
              bg='primary.50'
              color='primary.600'
              mr={1}
            />

            {user.name}
            <IconButton
              as='span'
              colorVariant='neutral'
              variant='plain'
              size='mini'
              ml={2}
              onClick={handleRemoveClick}
            >
              <CloseIcon />
            </IconButton>
          </FilterButtonLabel>
        </StyledFilterButton>
      </SelectUserModal>
    ))
  )
}

const tagButtonResponseOptional = pipe(
  Optional.id<TagFilterButtonQuery>(),
  Optional.prop('tag'),
  Optional.fromNullable
)

interface TagItemAvatarProps extends Omit<TagAvatarProps, 'tag'> {
  id: string
}

const TagItemAvatar = ({ id, ...other }: TagItemAvatarProps) => {
  const query = useQuery({
    query: TagFilterButtonQueryDocument,
    variables: {
      id
    }
  })

  return pipe(
    query,
    queryToDatumEither(tagButtonResponseOptional),
    DE.squash(constNull, constNull, (tag) => <TagAvatar tag={tag} {...other} />)
  )
}

interface TagItemAvatarGroupProps extends FlexProps {
  ids: NonEmptyArray<string>
}

const TagItemAvatarGroup = ({ ids, ...other }: TagItemAvatarGroupProps) => {
  const theme = useTheme()

  return (
    <Flex {...other}>
      {pipe(
        ids,
        A.mapWithIndex((index, id) => (
          <TagItemAvatar
            key={id}
            id={id}
            ml={index === 0 ? 0 : -1}
            style={{
              boxShadow: `0 0 0 1px ${theme.colors.grey[50]}`
            }}
          />
        ))
      )}
    </Flex>
  )
}

interface TagItemProps {
  id: string
}

const TagItem = ({ id }: TagItemProps) => {
  const query = useQuery({
    query: TagFilterButtonQueryDocument,
    variables: {
      id
    }
  })

  return pipe(
    query,
    queryToDatumEither(tagButtonResponseOptional),
    DE.squash(constNull, constNull, (tag) => (
      <Flex alignItems='center'>
        <TagItemAvatar id={tag.id} mr={2} />
        <Text>{tag.name}</Text>
      </Flex>
    ))
  )
}

interface TagGroupProps {
  ids: NonEmptyArray<string>
}

const TagGroup = ({ ids }: TagGroupProps) => {
  if (ids.length === 1) {
    return <TagItem id={ids[0]} />
  }

  return (
    <Flex alignItems='center'>
      <TagItemAvatarGroup ids={ids} mr={2} />
      <Text>{ids.length} tags</Text>
    </Flex>
  )
}

interface TagsFilterButtonProps {
  value: NonEmptyArray<string>
  onChange(value: O.Option<string[]>): void
  onRemove(): void
}

const TagsFilterButton = ({
  value,
  onChange,
  onRemove
}: TagsFilterButtonProps) => {
  const handleSelectTag = useCallback(
    (ids) => {
      if (ids.length === 0) {
        onChange(O.none)

        return
      }

      onChange(O.some(ids))
    },
    [onChange]
  )

  const handleRemoveClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      onRemove()
    },
    [onRemove]
  )

  return (
    <SelectTagsModal
      selectedIds={value}
      searchPlaceholder='Filter by tags'
      onSelect={handleSelectTag}
    >
      <StyledFilterButton mr={4}>
        <FilterButtonLabel>
          <TagGroup ids={value} />
          <IconButton
            as='span'
            colorVariant='neutral'
            variant='plain'
            size='mini'
            ml={2}
            onClick={handleRemoveClick}
          >
            <CloseIcon />
          </IconButton>
        </FilterButtonLabel>
      </StyledFilterButton>
    </SelectTagsModal>
  )
}

const formButtonResponseOptional = pipe(
  Optional.id<FormFilterButtonQuery>(),
  Optional.prop('form'),
  Optional.fromNullable
)

interface FormFilterButtonProps {
  value: string
  onChange(value: O.Option<string>): void
  onRemove(): void
}

const FormFilterButton = ({
  value,
  onChange,
  onRemove
}: FormFilterButtonProps) => {
  const query = useQuery({
    query: FormFilterButtonQueryDocument,
    variables: {
      id: value
    }
  })
  const handleSelectForm = useCallback(
    (form: { id: string }) => {
      onChange(O.some(form.id))
    },
    [onChange]
  )

  const handleRemoveClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      onRemove()
    },
    [onRemove]
  )

  return pipe(
    query,
    queryToDatumEither(formButtonResponseOptional),
    DE.squash(constNull, constNull, (form) => (
      <SelectFormModal
        selectedId={O.some(value)}
        searchPlaceholder='Filter by form'
        onSelect={handleSelectForm}
      >
        <StyledFilterButton mr={4}>
          <FilterButtonLabel>
            <FormIcon size='small' mr={2} />
            {form.title}
            <IconButton
              as='span'
              colorVariant='neutral'
              variant='plain'
              size='mini'
              ml={2}
              onClick={handleRemoveClick}
            >
              <CloseIcon />
            </IconButton>
          </FilterButtonLabel>
        </StyledFilterButton>
      </SelectFormModal>
    ))
  )
}

interface DateRangeFilterButtonProps {
  value: DateRangeTuple.DateRangeTuple
  onChange(value: O.Option<DateRangeTuple.DateRangeTuple>): void
  onRemove(): void
}

const DateRangeFilterButton = ({
  value,
  onChange,
  onRemove
}: DateRangeFilterButtonProps) => {
  const handleRemoveClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()

      onRemove()
    },
    [onRemove]
  )

  const name = DateRangeTuple.toLocaleDateString(value)

  return (
    <DateRangeFilterMenu
      value={value}
      onChange={(value) => onChange(O.some(value))}
    >
      <StyledFilterButton mr={4}>
        <FilterButtonLabel>
          <CalendarIcon size='small' mr={2} />
          {name}
          <IconButton
            as='span'
            colorVariant='neutral'
            variant='plain'
            size='mini'
            ml={2}
            onClick={handleRemoveClick}
          >
            <CloseIcon />
          </IconButton>
        </FilterButtonLabel>
      </StyledFilterButton>
    </DateRangeFilterMenu>
  )
}

interface FilterButtonProps {
  filterKey: string
  value: unknown
  filter: FilterConfig
  onRemove(key: string): void
  onChange(key: string, value: O.Option<unknown>): void
}

export const FilterButton = ({
  filterKey,
  value,
  filter,
  onRemove,
  onChange
}: FilterButtonProps) => {
  const handleRemoveClick = useCallback(() => {
    onRemove(filterKey)
  }, [filterKey, onRemove])

  const handleChange = useCallback(
    (value) => {
      onChange(filterKey, value)
    },
    [filterKey, onChange]
  )

  const renderFilter = () => {
    switch (filter._tag) {
      case 'user': {
        return (
          <UserFilterButton
            value={value as string}
            onChange={handleChange}
            onRemove={handleRemoveClick}
          />
        )
      }

      case 'tag': {
        return (
          <TagsFilterButton
            value={value as NonEmptyArray<string>}
            onChange={handleChange}
            onRemove={handleRemoveClick}
          />
        )
      }

      case 'date-range': {
        return (
          <DateRangeFilterButton
            value={value as DateRangeTuple.DateRangeTuple}
            onChange={handleChange}
            onRemove={handleRemoveClick}
          />
        )
      }

      case 'form': {
        return (
          <FormFilterButton
            value={value as string}
            onChange={handleChange}
            onRemove={handleRemoveClick}
          />
        )
      }
    }
  }

  return <Suspense fallback={null}>{renderFilter()}</Suspense>
}
