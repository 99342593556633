import React, { ReactNode } from 'react'
import { isFunction } from '@woorcs/utils'

import {
  useDialogSidebar,
  UseDialogSidebarProps,
  DialogSidebarContext,
  DialogSidebarProvider
} from './useDialogSidebar'

export interface DialogSidebarProps extends UseDialogSidebarProps {
  children: ReactNode | ((context: DialogSidebarContext) => ReactNode)
}

export const DialogSidebar = ({ children, ...props }: DialogSidebarProps) => {
  const context = useDialogSidebar(props)

  return (
    <DialogSidebarProvider value={context}>
      {isFunction(children) ? children(context) : children}
    </DialogSidebarProvider>
  )
}
