import React, { forwardRef } from 'react'
import { Box, PropsOf, system, createModalDialog } from '@woorcs/design-system'

export interface FullscreenModalDialogProps extends PropsOf<typeof system.div> {
  centerContent?: boolean
}

const Dialog = forwardRef<HTMLDivElement, FullscreenModalDialogProps>(
  (props, ref) => (
    <Box
      ref={ref}
      __css={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        perspective: '800px',
        transition: 'opacity 250ms ease-in-out, transform 250ms ease-in-out',
        opacity: 0,
        transformOrigin: 'top center',
        transform: 'translate3d(0, 2.5%, 0)',
        '&[data-enter]': {
          opacity: 1,
          transform: 'translate3d(0, 0, 0)'
        },
        zIndex: '33333',
        width: '100vw',
        height: '100vh'
      }}
      bg='white'
      {...props}
    />
  )
)

export const FullScreenModalDialog =
  createModalDialog<FullscreenModalDialogProps>(Dialog, {
    withBackdrop: false
  })
