import React from 'react'

import { Surface } from '../../layout'
import { PropsOf, system, forwardRef, css } from '../../../system'
import { focusStyle } from '../../../styles'

export type ModalContainerProps = PropsOf<typeof system.div>

export const ModalContainer = forwardRef<
  ModalContainerProps,
  typeof system.div
>((props, ref) => (
  <Surface
    ref={ref}
    css={css({
      position: 'relative',
      zIndex: 'modal',
      ':focus': focusStyle
    })}
    elevation={3}
    borderRadius='large'
    {...props}
  />
))
