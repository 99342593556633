import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type SelectFormModalFormFragment = { __typename: 'Form', id: string, title: string };

export type SelectFormModalQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type SelectFormModalQuery = { __typename: 'Query', forms: Array<{ __typename: 'Form', id: string, title: string }> };

export const SelectFormModalFormFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SelectFormModalFormFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Form"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"title"}}]}}]} as unknown as DocumentNode<SelectFormModalFormFragment, unknown>;
export const SelectFormModalQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SelectFormModalQuery"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SelectFormModalFormFragment"}}]}}]}},...SelectFormModalFormFragmentDoc.definitions]} as unknown as DocumentNode<SelectFormModalQuery, SelectFormModalQueryVariables>;