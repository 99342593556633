import {
  border,
  BorderProps,
  color,
  compose,
  flexbox,
  FlexboxProps,
  WidthProps,
  width,
  HeightProps,
  height,
  MinWidthProps,
  minWidth,
  MinHeightProps,
  minHeight,
  MaxWidthProps,
  maxWidth,
  MaxHeightProps,
  maxHeight,
  DisplayProps,
  display,
  VerticalAlignProps,
  verticalAlign,
  OverflowProps,
  overflow,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  background,
  BackgroundProps,
  grid,
  GridProps,
  ZIndexProps,
  ResponsiveValue as StyledResponsiveValue,
  FontStyleProps,
  TextAlignProps,
  LetterSpacingProps,
  overflowY,
  overflowX
} from 'styled-system'
import { Interpolation as StyledInterpolation } from '@emotion/styled'

import {
  ColorProps,
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  FontFamilyProps,
  BorderRadiusProps
} from './types'

export type ResponsiveValue<P, T> = StyledResponsiveValue<P, T>
export type Interpolation<P> = StyledInterpolation<P>

export const systemProps = compose(
  space,
  typography,
  color,
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  display,
  verticalAlign,
  overflow,
  overflowY,
  overflowX,
  flexbox,
  border,
  background,
  position,
  grid,
  shadow
)

export interface SystemProps
  extends FontSizeProps,
    FontWeightProps,
    LineHeightProps,
    FontFamilyProps,
    FontStyleProps,
    TextAlignProps,
    PositionProps,
    LetterSpacingProps,
    SpaceProps,
    ShadowProps,
    ColorProps,
    Omit<BorderProps, 'borderRadius'>,
    FlexboxProps,
    WidthProps,
    HeightProps,
    MinWidthProps,
    MinHeightProps,
    MaxWidthProps,
    MaxHeightProps,
    DisplayProps,
    VerticalAlignProps,
    OverflowProps,
    BackgroundProps,
    GridProps,
    ZIndexProps,
    BorderRadiusProps {}

export const systemPropNames = systemProps.propNames as (keyof SystemProps)[]
