import React from 'react'
import { VisuallyHidden as BaseVisuallyHidden } from 'reakit/VisuallyHidden'

import { Box, BoxProps } from '../Box'

type VisuallyHiddenProps = BoxProps

export const VisuallyHidden = (props: VisuallyHiddenProps) => (
  <BaseVisuallyHidden as={Box} {...props} />
)
