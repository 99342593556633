import { createContext } from '@woorcs/utils'

import { ModalContext, useModal, UseModalProps } from '../Modal/useModal'

type Placement = 'left' | 'right'

export type DialogSidebarContext = ModalContext & {
  placement: Placement
}

export const [DialogSidebarProvider, useDialogSidebarContext] =
  createContext<DialogSidebarContext>({
    errorMessage:
      'DialogSidebar components must be used inside the context of a DialogSidebar element'
  })

export type UseDialogSidebarProps = UseModalProps & {
  placement?: Placement
}

export const useDialogSidebar = ({
  placement = 'left',
  ...other
}: UseDialogSidebarProps) => {
  const state = useModal(other)

  return {
    ...state,
    placement
  }
}

type UseDialogSidebarContentProps = Record<string, any>

export const useDialogSidebarContent = (
  props: UseDialogSidebarContentProps
) => {
  const { getDialogProps, placement } = useDialogSidebarContext()

  return {
    ...getDialogProps(),
    placement,
    ...props
  }
}
