import React, { useCallback, useState, ReactElement } from 'react'
import { FormikHelpers } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import { CreateTagInput } from '@woorcs/graphql/schema'
import { useSnackbar } from '@woorcs/design-system'

import { TagFormModal, TagFormTag } from '../TagFormModal'

import { AddTagDocument } from './__generated__/AddTagModal'

export interface AddTagInterface {
  name: string
  color: string
  description: string
}

type AddTagModalProps = {
  children: ReactElement
}

const tagToInput = (tag: TagFormTag): CreateTagInput => ({
  ...tag,
  color: tag.color === '' ? null : tag.color,
  description: tag.description === '' ? null : tag.description
})

export const AddTagModal = ({ children }: AddTagModalProps) => {
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const { showSnackbar } = useSnackbar()
  const [, addTag] = useMutation(AddTagDocument)

  const handleSubmit = useCallback(
    (tag, actions: FormikHelpers<TagFormTag>) => {
      addTag({
        input: tagToInput(tag)
      })
        .then(() => {
          actions.resetForm({})
          setVisible(false)
          showSnackbar({
            title: t('tags:addTagModal.addTagSuccessSnackbar.title'),
            variant: 'success'
          })
        })
        .catch((e: Error) => {
          showSnackbar({
            title: e.message,
            variant: 'danger'
          })
        })
        .finally(() => actions.setSubmitting(false))
    },
    [addTag, showSnackbar, t]
  )

  return (
    <TagFormModal
      title={t('tags:addTagModal.title')}
      visible={visible}
      onVisiblityChange={setVisible}
      onSubmit={handleSubmit}
    >
      {children}
    </TagFormModal>
  )
}
