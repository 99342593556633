import React from 'react'
import * as O from 'fp-ts/Option'
import { system, WithSystem } from '@woorcs/design-system'
import { pipe, constant } from 'fp-ts/function'
import { Locale } from '@woorcs/form'
// @ts-expect-error missing ts definition
import { findFlagUrlByIso2Code } from 'country-flags-svg'

const getLanguageFlagUrl = (locale: Locale.Locale) =>
  pipe(
    Locale.getCountryCode(locale),
    O.fold(constant(''), (code) => findFlagUrlByIso2Code(code))
  )

type CountryFlagProps = WithSystem<{
  size?: number
  locale: Locale.Locale
}>

export const CountryFlag = ({
  locale,
  size = 24,
  ...other
}: CountryFlagProps) => {
  const url = getLanguageFlagUrl(locale)

  return <system.img style={{ width: size }} src={url} {...other} />
}
