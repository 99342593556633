import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import { pipe } from 'fp-ts/function'
import * as L from 'monocle-ts/Lens'
import * as Optional from 'monocle-ts/Optional'
import { UUID } from '@woorcs/types/UUID'
import * as S from '@woorcs/types/Schemable'

import * as Locale from './Locale'

// -------------------------------------------------------------------------------------
// model
// -------------------------------------------------------------------------------------

type TranslationsDict = Readonly<Record<string, string>>

type Languages = Readonly<Record<Locale.Locale, TranslationsDict>>

export type I18nConfig = {
  defaultLanguage: Locale.Locale
  languages: Languages
}

// -------------------------------------------------------------------------------------
// instances
// -------------------------------------------------------------------------------------

export const schema: S.Schema<I18nConfig> = S.make((S) =>
  S.struct({
    defaultLanguage: Locale.schema(S),
    languages: S.record(S.record(S.string))
  })
)

// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------

export const i18nConfig = (defaultLanguage: Locale.Locale) => ({
  defaultLanguage,
  languages: {}
})

// -------------------------------------------------------------------------------------
// lenses
// -------------------------------------------------------------------------------------

const configLens = L.id<I18nConfig>()

const defaultLanguageLens = pipe(configLens, L.prop('defaultLanguage'))

const languagesLens = pipe(configLens, L.prop('languages'))

const languageLens = (locale: Locale.Locale) =>
  pipe(languagesLens, L.key(locale))

const translationLens = (locale: Locale.Locale, id: UUID) =>
  pipe(languageLens(locale), Optional.key(id))

// -------------------------------------------------------------------------------------
// utils
// -------------------------------------------------------------------------------------

export const hasLanguage = (locale: Locale.Locale) => (config: I18nConfig) =>
  pipe(languageLens(locale).getOption(config), O.isSome)

export const addLanguage = (locale: Locale.Locale) => (config: I18nConfig) => {
  if (hasLanguage(locale)(config)) {
    return config
  }

  return pipe(
    languagesLens,
    L.modify((languages) => ({
      ...languages,
      [locale]: {}
    }))
  )(config)
}

export const removeLanguage = (locale: Locale.Locale) => (config: I18nConfig) =>
  pipe(languagesLens, L.modify(R.deleteAt(locale)))(config)

export const setDefaultLanguage = defaultLanguageLens.set

export const getTranslation = (locale: Locale.Locale, id: UUID) =>
  translationLens(locale, id).getOption

export const addTranslation = (
  locale: Locale.Locale,
  id: UUID,
  translation: string
) =>
  pipe(
    languageLens(locale),
    Optional.modify((translations) => ({
      ...translations,
      [id]: translation
    }))
  )

export const setTranslation =
  (locale: Locale.Locale, id: UUID, translation: string) =>
  (config: I18nConfig) => {
    const current = getTranslation(locale, id)(config)

    if (O.isNone(current)) {
      return addTranslation(locale, id, translation)(config)
    }

    return pipe(config, translationLens(locale, id).set(translation))
  }
