import React, { memo } from 'react'
import {
  Box,
  Card,
  CardProps,
  DragHandleIcon,
  Flex,
  FormIcon,
  forwardRef,
  IconButton,
  MoreIcon,
  Text
} from '@woorcs/design-system'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import { FormDocument } from '@woorcs/form'

import { PageListItemMenu } from './Menu'

export type PageListItemProps = {
  page: InspectionFormDocument.InspectionFormPageElement
} & CardProps

export const PageListItem = memo(
  forwardRef<PageListItemProps, 'div'>(({ page, ...other }, ref) => {
    const numQuestions = page.children.length

    return (
      <Card
        ref={ref}
        bg='white'
        borderRadius='medium'
        position='relative'
        borderColor='grey.100'
        borderWidth={1}
        bordeStyle='solid'
        elevation={0}
        {...other}
      >
        <Flex px={5} alignItems='center'>
          <DragHandleIcon
            css={{
              cursor: 'grab'
            }}
            mr={4}
          />
          <Flex
            justifyContent='space-between'
            alignItems='center'
            width='100%'
            height='100%'
          >
            <Flex
              css={{ cursor: 'pointer' }}
              flex={1}
              py={5}
              flexDirection='column'
              height='100%'
            >
              <Text as='p' fontWeight='bold' mb={1}>
                {FormDocument.getDefaultTitle(page)}
              </Text>
              <Text>{numQuestions} questions</Text>
            </Flex>

            <Flex alignItems='center'>
              {page.rule && (
                <Box mr={2}>
                  <Flex bg='orange.50' p={1} borderRadius='small' flexGrow={0}>
                    <FormIcon size='small' color='orange.500' />
                  </Flex>
                </Box>
              )}

              <PageListItemMenu page={page}>
                <IconButton>
                  <MoreIcon />
                </IconButton>
              </PageListItemMenu>
            </Flex>
          </Flex>
        </Flex>
      </Card>
    )
  })
)
