import React, { forwardRef } from 'react'
import { animated, useSpring } from 'react-spring'

import { CheckIcon } from '../../icons'

import { useMenuOption, UseMenuOptionProps } from './useMenu'
import { MenuItem, MenuItemProps } from './MenuItem'
import { MenuItemIcon } from './MenuItemIcon'

interface MenuOptionProps
  extends UseMenuOptionProps,
    Omit<MenuItemProps, 'value' | 'onChange'> {}

export const MenuOption = forwardRef<HTMLButtonElement, MenuOptionProps>(
  ({ children, ...other }, ref) => {
    const props = useMenuOption(other)
    const spring = useSpring({
      opacity: props.checked ? 1 : 0,
      transform: props.checked ? 'scale(1)' : 'scale(0.75)'
    })

    return (
      <MenuItem ref={ref} {...props}>
        <MenuItemIcon>
          <animated.div style={spring}>
            <CheckIcon />
          </animated.div>
        </MenuItemIcon>

        {children}
      </MenuItem>
    )
  }
)
