import React, { ReactElement, useRef, useState } from 'react'
import {
  matchRoutes,
  renderMatches,
  RouteObject,
  UNSAFE_RouteContext
} from 'react-router'
import { pipe } from 'fp-ts/function'
import { useOnce } from '@woorcs/hooks'
import history from 'history/browser'

const pdfBaseUrl = import.meta.env.VITE_REACT_APP_PDF_URL?.toString() || ''

const EMPTY_ROUTE_CONTEXT = {
  outlet: null,
  matches: []
}

interface ResetRouterContextProps {
  children: ReactElement
}

export const pdfUrl = (
  organizationId: string,
  submissionRevisionId: string
) => {
  return `${pdfBaseUrl}/${organizationId}/${submissionRevisionId}`
}

export const ResetRouterContext = ({ children }: ResetRouterContextProps) => (
  <UNSAFE_RouteContext.Provider value={EMPTY_ROUTE_CONTEXT}>
    {children}
  </UNSAFE_RouteContext.Provider>
)

const useRoutes = (routes: RouteObject[]) => {
  return useRef(routes).current
}

export const useRouter = (routes: RouteObject[]) => {
  const [location, setLocation] = useState(history.location)

  useOnce(() =>
    history.listen(() => {
      setLocation(history.location)
    })
  )

  return pipe(matchRoutes(useRoutes(routes), location), renderMatches)
}
