import React, { ReactNode } from 'react'
import {
  Flex,
  BoxProps,
  FlexProps,
  H3,
  TextProps,
  Box
} from '@woorcs/design-system'

type PageProps = {
  children: ReactNode
} & BoxProps

export const Page = ({ children, ...other }: PageProps) => (
  <Flex
    flexDirection='column'
    justifyContent='flex-start'
    width='100%'
    minHeight='100%'
    flexGrow={1}
    {...other}
  >
    {children}
  </Flex>
)

type PageHeaderProps = FlexProps

export const PageHeader = ({ children, ...other }: PageHeaderProps) => (
  <Flex pt={12} pb={8} px={12} borderBottom='base' {...other}>
    {children}
  </Flex>
)

type PageTitleProps = TextProps

export const PageTitle = (props: PageTitleProps) => <H3 {...props} />

type PageContentProps = BoxProps

export const PageContent = ({ children, ...other }: PageContentProps) => (
  <Box px={12} {...other}>
    {children}
  </Box>
)
