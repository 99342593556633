import React, { memo } from 'react'
import {
  Box,
  Card,
  Flex,
  IconButton,
  MoreIcon,
  Text
} from '@woorcs/design-system'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as O from 'fp-ts/Option'
import * as R from 'fp-ts/Record'
import { constNull, pipe } from 'fp-ts/function'
import { Locale } from '@woorcs/form'

import { CountryFlag } from '../CountryFlag'
import { useValue } from '../../state'
import { TranslateLanguageModal } from '../TranslateLanguageModal'

import { LanguageListItemMenu } from './ItemMenu'

type LanguageListItemProps = {
  locale: Locale.Locale
  isSaving: boolean
}

export const LanguageListItem = memo(
  ({ locale, isSaving }: LanguageListItemProps) => {
    return (
      <TranslateLanguageModal initialLocale={locale} isSaving={isSaving}>
        <Card
          bg='white'
          borderRadius='medium'
          position='relative'
          borderColor='grey.50'
          borderWidth={1}
          borderStyle='solid'
          mb={4}
          p={5}
        >
          <Flex justifyContent='space-between'>
            <Box css={{ cursor: 'pointer' }} width='100%' mr={8}>
              <Flex alignItems='center'>
                <CountryFlag locale={locale} mr={2} />
                <Text fontWeight='bold'>{Locale.getLocaleLabel(locale)}</Text>
              </Flex>

              {/* <Flex alignItems='center'>
              <ProgressBar value={0} mr={2} />
              <Text color='text.emphasized' fontSize='small'>
                0%
              </Text>
            </Flex> */}
            </Box>

            <LanguageListItemMenu locale={locale}>
              <IconButton>
                <MoreIcon />
              </IconButton>
            </LanguageListItemMenu>
          </Flex>
        </Card>
      </TranslateLanguageModal>
    )
  }
)

interface LanguageListProps {
  isSaving: boolean
}

export const LanguageList = ({ isSaving }: LanguageListProps) => {
  const root = useValue()

  return pipe(
    root.i18n.languages,
    R.collect((k) => k),
    NEA.fromArray,
    O.fold(constNull, (languages) => (
      <Box>
        {languages.map((locale) => (
          <LanguageListItem key={locale} isSaving={isSaving} locale={locale} />
        ))}
      </Box>
    ))
  )
}
