import React, { ReactElement, useState } from 'react'
import { Box, Flex, Modal, ModalDisclosure } from '@woorcs/design-system'
import { rem } from '@woorcs/utils'
import { Locale } from '@woorcs/form'

import { FullScreenModalDialog } from '../FullScreenModalDialog'
import { TranslateLanguageForm } from '../TranslateLanguageForm'

import { TranslateLanguageModalNavbar } from './Navbar'

type TranslateLanguageViewProps = {
  initialLocale: Locale.Locale
  isSaving: boolean
  children: ReactElement
}

export const TranslateLanguageModal = ({
  children,
  initialLocale,
  isSaving
}: TranslateLanguageViewProps) => {
  const [locale, setLocale] = useState(initialLocale)

  return (
    <Modal>
      <ModalDisclosure>{children}</ModalDisclosure>
      <FullScreenModalDialog>
        <Flex width='100%' height='100vh' flexDirection='column'>
          <TranslateLanguageModalNavbar
            isSaving={isSaving}
            selectedLanguage={locale}
            onSelectLanguage={setLocale}
          />

          <Flex
            style={{
              overflowY: 'auto'
            }}
            flexDirection='column'
            alignItems='center'
            width='100%'
            height='100%'
            py={12}
            bg='white'
          >
            <Box css={{ width: rem(960) }}>
              <TranslateLanguageForm locale={locale} />
            </Box>
          </Flex>
        </Flex>
      </FullScreenModalDialog>
    </Modal>
  )
}
