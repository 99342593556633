import React, { useCallback, useState, ReactElement, useMemo } from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import {
  Box,
  Modal,
  ModalBody,
  ModalDialog,
  ModalDisclosure,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PrimaryButton,
  SecondaryButton,
  Stack,
  useSnackbar
} from '@woorcs/design-system/src'
import * as t from 'io-ts'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import { getFormikValidator } from '@woorcs/utils/formik'
import { Role } from '@woorcs/graphql/schema'

import { FormField } from '@app/components'

import { SelectTagsInput } from '../SelectTagsInput'
import { SelectRolesInput } from '../SelectRolesInput'

import { EditUserModalFragment } from './__generated__/EditUserModalFragments'
import { UpdateUserProfileDocument } from './__generated__/EditUserModal'

interface Props {
  user: EditUserModalFragment
  children: ReactElement
}

const UserProfileForm = t.type({
  role: t.union([t.literal('ADMIN'), t.null]),
  tags: t.array(t.string)
})

export type UserProfileFormUser = t.TypeOf<typeof UserProfileForm>

const validateForm = getFormikValidator(UserProfileForm)

export const EditUserModal = ({ user, children }: Props) => {
  const { t } = useTranslation()
  const userId = user.id
  const [visible, setVisible] = useState(false)
  const snackbar = useSnackbar()
  const [, updateUserProfile] = useMutation(UpdateUserProfileDocument)
  const initialValues = useMemo(
    () => ({
      role: user.role,
      tags: user.tags.map((tag) => tag.id)
    }),
    [user]
  )

  const handleSubmit = useCallback(
    (
      form: UserProfileFormUser,
      actions: FormikHelpers<UserProfileFormUser>
    ) => {
      updateUserProfile({
        input: {
          id: userId,
          role: (form.role as Role) ?? null,
          tags: form.tags,
          firstName: user.firstName ?? '',
          lastName: user.lastName ?? ''
        }
      })
        .then(() => {
          setVisible(false)

          snackbar.showSnackbar({
            title: 'User updated',
            variant: 'success'
          })

          actions.resetForm({
            values: form
          })
        })
        .catch(() => {
          snackbar.showSnackbar({
            title: 'Failed to update user',
            variant: 'danger'
          })
        })
        .finally(() => {
          actions.setSubmitting(false)
        })
    },
    [snackbar, updateUserProfile, user, userId]
  )

  return (
    <Modal visible={visible} onVisiblityChange={setVisible}>
      <ModalDisclosure>{children}</ModalDisclosure>
      <ModalDialog role='dialog'>
        <ModalHeader>
          <ModalTitle mb={6}>{t('users:editUserModal.title')}</ModalTitle>
        </ModalHeader>

        <Formik
          validate={validateForm}
          initialValues={initialValues as any}
          onSubmit={handleSubmit}
        >
          {({ isValid, isSubmitting, values, setFieldValue }) => (
            <Form>
              <ModalBody>
                <Stack spacing={6}>
                  <FormField name='role' label={t('common:role')}>
                    <Box>
                      <SelectRolesInput
                        value={values.role}
                        onChange={(value) => setFieldValue('role', value)}
                      />
                    </Box>
                  </FormField>
                  <FormField name='tags' label={t('common:tags')}>
                    <Box>
                      <SelectTagsInput
                        value={values.tags}
                        onChange={(value) => setFieldValue('tags', value)}
                      />
                    </Box>
                  </FormField>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <ModalDisclosure>
                  <SecondaryButton mr={2}>{t('common:cancel')}</SecondaryButton>
                </ModalDisclosure>
                <PrimaryButton
                  type='submit'
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting}
                >
                  {t('common:save')}
                </PrimaryButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  )
}
