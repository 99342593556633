import React from 'react'
import { animated } from 'react-spring'

import { Surface } from '../../layout'
import { PropsOf, system, forwardRef, css } from '../../../system'
import { focusStyle } from '../../../styles'

const AnimatedSurface = animated(Surface)

export type DialogSidebarContainerProps = PropsOf<typeof system.div>

export const DialogSidebarContainer = forwardRef<
  DialogSidebarContainerProps,
  typeof system.div
>((props, ref) => (
  <AnimatedSurface
    ref={ref}
    css={css({
      position: 'relative',
      zIndex: 'modal',
      ':focus': focusStyle
    })}
    elevation={3}
    borderRadius='none'
    {...props}
  />
))
