import { CompositeItemOptions } from 'reakit/ts'

import { useListBoxContext } from './ListboxProvider'

export interface UseListboxOptionProps
  extends Pick<CompositeItemOptions, 'focusable' | 'disabled'> {
  value: any
  disabled?: boolean
}

export const useListboxOption = ({
  value,
  ...other
}: UseListboxOptionProps) => {
  const {
    isSelected,
    multiple,
    disabled,
    readOnly,
    focused,
    getOptionButtonProps
  } = useListBoxContext()
  const selected = isSelected(value)

  return {
    ...other,
    selected,
    multiple,
    disabled,
    readOnly,
    focused,
    getOptionButtonProps
  }
}
