import React, { ReactNode } from 'react'
import {
  useTabState,
  Tab as BaseTab,
  TabList as BaseTabList,
  TabPanel as BaseTabPanel,
  TabStateReturn,
  TabHTMLProps,
  TabListHTMLProps,
  TabPanelHTMLProps
} from 'reakit/Tab'
import { createContext, space } from '@woorcs/utils'

import { system, SystemComponentProps, SystemProps } from '../../../system'
import { focusStyle } from '../../../styles'

type TabsContext = TabStateReturn

const [TabsContextProvider, useTabsContext] = createContext<TabsContext>({
  errorMessage: 'You forgot wrap tab components in the Tabs component'
})

type TabListSize = 'medium' | 'large'

type TabsListStyleContext = {
  size: TabListSize
}

const [TabListStyleContextProvider, useTabsListStyleContext] =
  createContext<TabsListStyleContext>({
    errorMessage: 'You must wrap Tab components with TabList'
  })

interface TabsProps {
  currentId?: string
  children: ReactNode
}

export const Tabs = ({ children, ...initialState }: TabsProps) => {
  const tab = useTabState(initialState)

  return <TabsContextProvider value={tab}>{children}</TabsContextProvider>
}

interface TabProps extends SystemProps, Omit<TabHTMLProps, 'color'> {}

export const Tab = ({ borderBottomColor = 'grey.50', ...props }: TabProps) => {
  const tab = useTabsContext()
  const { size } = useTabsListStyleContext()

  return (
    <BaseTab
      textAlign='center'
      fontSize={size === 'medium' ? 'small' : 'base'}
      fontWeight='semi-bold'
      mb='-1px'
      bg='transparent'
      __css={{
        appearance: 'none',
        border: 'none',
        cursor: 'pointer',
        borderBottom: 'thin',
        borderBottomColor,
        mr: size === 'medium' ? space(4) : space(8),
        py: size === 'medium' ? space(3) : space(4),
        px: 0,
        ':hover': {
          borderBottomColor: 'grey.100'
        },
        '&[aria-selected=true]': {
          borderBottomColor: 'primary.500',
          color: 'primary.500'
        },
        ':last-of-type': {
          mr: 0
        },
        ':focus': focusStyle
      }}
      as={system.button as any}
      {...props}
      {...tab}
    />
  )
}

interface TabListProps
  extends SystemComponentProps,
    Omit<TabListHTMLProps, 'color' | 'css'> {
  size?: TabListSize
}

export const TabList = ({ size = 'medium', ...props }: TabListProps) => {
  const tab = useTabsContext()
  const ctx = { size }

  return (
    <TabListStyleContextProvider value={ctx}>
      <BaseTabList
        alignItems='center'
        borderBottomColor='grey.50'
        borderBottomWidth='1px'
        borderBottomStyle='solid'
        as={system.div}
        {...tab}
        {...props}
      />
    </TabListStyleContextProvider>
  )
}

interface TabPanelProps
  extends SystemComponentProps,
    Omit<TabPanelHTMLProps, 'color' | 'css'> {
  children: ReactNode
}

export const TabPanel = (props: TabPanelProps) => {
  const tab = useTabsContext()
  const isCurrent = (props.id && props.id === tab.currentId) || !props.id

  return (
    <BaseTabPanel
      as={system.div}
      css={{ ':focus': { outline: 'none' } }}
      {...tab}
      {...props}
    >
      {isCurrent && props.children}
    </BaseTabPanel>
  )
}
