import React, { forwardRef } from 'react'
import { space } from '@woorcs/utils'

import { PlainButton } from '../../buttons'
import { PropsOf } from '../../../system'

interface MenuItemButtonProps extends PropsOf<typeof PlainButton> {
  destructive?: boolean
}

export const MenuItemButton = forwardRef(
  ({ destructive, ...other }: MenuItemButtonProps, ref) => (
    <PlainButton
      ref={ref}
      __css={{
        display: 'flex',
        alignItems: 'center',
        height: space(8),
        width: '100%',
        px: 3,
        fontSize: 'small',
        fontWeight: 'medium',
        textAlign: 'left',
        color: destructive ? 'danger' : 'black',
        cursor: 'pointer',
        borderRadius: 'small',
        whiteSpace: 'nowrap',
        ':hover': {
          color: destructive ? 'danger' : 'primary.500'
        },
        ':focus, :active': {
          color: destructive ? 'destructive' : 'primary.500',
          backgroundColor: destructive ? 'red.50' : 'primary.50'
        }
      }}
      {...other}
    />
  )
)
