import { PopoverInitialState } from 'reakit/Popover'
import { useSelectState, UseSelectStateProps } from '@woorcs/hooks'
import { createContext } from '@woorcs/utils'
import { useRef, useState, useCallback } from 'react'

import { useFormField, UseFormFieldProps } from '../FormField'

type UseSelectPopoverOptions = Pick<PopoverInitialState, 'placement' | 'gutter'>

export interface UseSelectProps<T>
  extends Omit<UseSelectStateProps<T>, 'allowEmptyValue'>,
    UseFormFieldProps<HTMLSelectElement>,
    UseSelectPopoverOptions {
  name?: string
}

export function useSelect<T>({
  placement = 'bottom-start',
  gutter = 4,
  ...props
}: UseSelectProps<T>) {
  const { disabled, readOnly, invalid, focused, getInputProps } =
    useFormField(props)
  const { value, onChange, clear, multiple, hasValue, isSelected } =
    useSelectState<T>(props)
  const selectedLabelRef = useRef<string | null>(null)
  const [menuVisible, setMenuVisibility] = useState(false)
  const disclosureRef = useRef<HTMLButtonElement | null>(null)

  const handleSetMenuVisibility = useCallback(
    (visible) => {
      if ((disabled || readOnly) && visible) {
        return
      }

      setMenuVisibility(visible)
    },
    [disabled, readOnly]
  )

  const handleValueChange = useCallback(
    (selectedValue: T | null) => {
      onChange(selectedValue)

      if (!multiple) {
        handleSetMenuVisibility(false)
      }
    },
    [handleSetMenuVisibility, multiple, onChange]
  )

  const getPopoverProps = () => ({
    visible: menuVisible,
    onVisiblityChange: handleSetMenuVisibility,
    placement,
    gutter,
    initialFocusRef: disclosureRef
  })

  const getListboxProps = () => ({
    value: value as any,
    multiple,
    onChange: handleValueChange
  })

  const getDisclosureProps = () => ({
    ref: disclosureRef,
    ...getInputProps(),
    disabled,
    readOnly,
    invalid,
    focused
  })

  const getOptionProps = () => ({
    onChange: handleValueChange
  })

  return {
    disclosureRef,
    selectedLabelRef,
    getPopoverProps,
    getOptionProps,
    getDisclosureProps,
    getListboxProps,
    menuVisible,
    setMenuVisibility,
    multiple,
    value,
    hasValue,
    onValueChange: handleValueChange,
    isSelected,
    clear
  }
}

type SelectContext = ReturnType<typeof useSelect>

export const [SelectProvider, useSelectContext] = createContext<SelectContext>()

export const useSelectMenu = () => {
  const { menuVisible, getPopoverProps } = useSelectContext()

  return {
    visible: menuVisible,
    getPopoverProps
  }
}

export const useSelectDisclosure = () => {
  const { getDisclosureProps, menuVisible, setMenuVisibility } =
    useSelectContext()

  const handleKeyDown = (event: KeyboardEvent) => {
    // const inputHasFocus = state.composite.currentId === null
    const inputHasFocus = true

    // Prevents ArrowUp and ArrowDown from moving the cursor in the input
    if (
      !inputHasFocus &&
      ['ArrowUp', 'ArrowDown', 'Enter'].includes(event.key)
    ) {
      event.preventDefault()
    }

    // Up&down arrows open the popover if it's closed
    if (['ArrowUp', 'ArrowDown'].includes(event.key) && !menuVisible) {
      setMenuVisibility(true)
      event.preventDefault()

      return
    }

    // We don't want to activate items when pressing space
    if (menuVisible && event.key === ' ') {
      event.preventDefault()
      return
    }
  }

  return {
    onKeyUp: handleKeyDown,
    onKeyDown: handleKeyDown,
    ...getDisclosureProps()
  }
}
