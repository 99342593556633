import React, { ReactNode, useCallback } from 'react'
import * as t from 'io-ts'
import { Form, Formik, FormikHelpers } from 'formik'
import { Box, Card, Flex, Input } from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import {
  CreateOrganizationPayload,
  useCreateOrganization
} from '@woorcs/api-client'
import { getFormikValidator, NonEmptyTrimmedString } from '@woorcs/utils/formik'

import { SubmitButton, FormField } from '@app/components'

const OrganizationForm = t.type({
  organizationName: NonEmptyTrimmedString
})

type OrganizationFormValue = t.OutputOfProps<typeof OrganizationForm['props']>

const validate = getFormikValidator(OrganizationForm)

const initialValues = { name: '' }

type CreateOrganizationFormProps = {
  actions?: ReactNode
  onSubmitSuccess?(organization: CreateOrganizationPayload): void
  onSubmitFailure?(): void
}

export const CreateOrganizationForm = ({
  actions = (
    <Flex justifyContent='center'>
      <SubmitButton label='Create organization' maxWidth='320px' fullWidth />
    </Flex>
  ),
  onSubmitSuccess,
  onSubmitFailure
}: CreateOrganizationFormProps) => {
  const { t } = useTranslation()
  const [, createOrganization] = useCreateOrganization()
  const handleSubmit = useCallback(
    (
      values: OrganizationFormValue,
      actions: FormikHelpers<OrganizationFormValue>
    ) => {
      createOrganization(values)
        .then((result) => {
          if (result._tag === 'Right') {
            onSubmitSuccess?.(result.right)
          }
        })
        .catch(onSubmitFailure)
        .finally(() => {
          actions.setSubmitting(false)
        })
    },
    [createOrganization, onSubmitFailure, onSubmitSuccess]
  )

  return (
    <Formik initialValues={initialValues as any} onSubmit={handleSubmit}>
      <Form>
        <Box>
          <Card width={540} p={8} mb={8}>
            <FormField
              name='organizationName'
              label={t('common:name')}
              placeholder='The name of your organization'
            >
              <Input />
            </FormField>
          </Card>
          {actions}
        </Box>
      </Form>
    </Formik>
  )
}
