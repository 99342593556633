import React, { useCallback } from 'react'
import {
  Box,
  Button,
  Flex,
  H3,
  PlusIcon,
  useSnackbar
} from '@woorcs/design-system'
import { FormikHelpers } from 'formik'
import * as Optional from 'monocle-ts/Optional'
import * as Option from 'fp-ts/Option'
import history from 'history/browser'
import { pipe, constant } from 'fp-ts/function'
import { useMutation, useQuery, UseQueryState } from 'urql'

import {
  OrganizationList,
  AddOrganizationModal,
  OrganizationFormOrganization
} from '../../components'

import {
  OrganizationListQueryDocument,
  OrganizationListQuery,
  CreateOrganizationDocument
} from './__generated__/OrganizationsPage'

const organizationListQueryOptional = pipe(
  Optional.id<OrganizationListQuery>(),
  Optional.prop('organizations')
)

const organizationListQueryResultOptional = pipe(
  Optional.id<UseQueryState<OrganizationListQuery>>(),
  Optional.prop('data'),
  Optional.fromNullable,
  Optional.compose(organizationListQueryOptional)
)

export const OrganizationsPage = () => {
  const [query] = useQuery({
    query: OrganizationListQueryDocument
  })
  const { showSnackbar } = useSnackbar()

  const organizations = pipe(
    query,
    organizationListQueryResultOptional.getOption,
    Option.getOrElseW(constant([]))
  )

  const [, addOrganization] = useMutation(CreateOrganizationDocument)
  //   update(cache, { data }) {
  //     if (!data) {
  //       return
  //     }

  //     const addedOrganization = data.createOrganization

  //     if (!addedOrganization) {
  //       return
  //     }

  //     const organizations = pipe(
  //       cache.readQuery({
  //         query: OrganizationListQueryDocument
  //       }) as OrganizationListQuery,
  //       organizationListQueryOptional.getOption,
  //       Option.getOrElseW(constant([]))
  //     )

  //     cache.writeQuery({
  //       query: OrganizationListQueryDocument,
  //       data: {
  //         organizations: [addedOrganization, ...organizations]
  //       }
  //     })
  //   }
  // })

  const onManageOrganization = useCallback((id) => {
    window.location.replace(`/${id}`)
  }, [])

  const handleSubmit = useCallback(
    (organization, actions: FormikHelpers<OrganizationFormOrganization>) => {
      addOrganization(organization)
        .then(() => {
          actions.resetForm({})
          showSnackbar({
            title: 'Organization added',
            variant: 'success'
          })
        })
        .catch((e: Error) => {
          showSnackbar({
            title: e.message,
            variant: 'danger'
          })
        })
        .finally(() => {
          actions.setSubmitting(false)
        })
    },
    [showSnackbar, addOrganization]
  )

  return (
    <Box width='100%' pt={12}>
      <Flex justifyContent='space-between' alignItems='flex-end' mb={10} px={5}>
        <H3>Organizations</H3>
        <AddOrganizationModal
          title='Create organization'
          onSubmit={handleSubmit}
        >
          <Button leftIcon={<PlusIcon />}>Add organization</Button>
        </AddOrganizationModal>
      </Flex>

      <OrganizationList
        organizations={organizations}
        loading={query.fetching}
        onManageOrganization={onManageOrganization}
      />
    </Box>
  )
}
