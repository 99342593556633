import React from 'react'
import {
  Box,
  Flex,
  TabList,
  Tab,
  Tabs,
  Text,
  TabPanel,
  system,
  IconButton,
  MoreIcon
} from '@woorcs/design-system'
import { FormDocument } from '@woorcs/form'
import { InspectionFormDocument } from '@woorcs/inspection-form'
import * as Lens from 'monocle-ts/Lens'
import { pipe } from 'fp-ts/function'

import { Sidebar } from '../Sidebar'
import { PageListItemMenu } from '../PageList/Page/Menu'
import { TranslateableTextField } from '../ElementForm/Field'
import { PageRulesList } from '../PageRulesList'

const Section = system('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomColor: 'grey.50',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  px: 5
})

interface EditPageSidebarProps {
  page: InspectionFormDocument.InspectionFormPageElement
}

const titleLens = pipe(
  Lens.id<InspectionFormDocument.InspectionFormPageElement>(),
  Lens.prop('title')
)

export const EditPageSidebar = ({ page }: EditPageSidebarProps) => {
  return (
    <Sidebar style={{ left: 'auto', right: 0 }}>
      <Flex width='100%' height='100%' flexDirection='column'>
        <Section>
          <Flex alignItems='center' py={5}>
            <Text fontSize='small' fontWeight='bold'>
              {FormDocument.getDefaultTitle(page)}
            </Text>
          </Flex>
          <PageListItemMenu page={page}>
            <IconButton>
              <MoreIcon />
            </IconButton>
          </PageListItemMenu>
        </Section>
        <Box
          css={{
            overflowY: 'auto'
          }}
        >
          <Tabs>
            <Section pt={4}>
              <Flex height='100%' alignItems='flex-end' mb='-1px'>
                <TabList>
                  <Tab>Options</Tab>
                  <Tab>Rules</Tab>
                </TabList>
              </Flex>
            </Section>
            <Box px={5} py={4}>
              <TabPanel>
                <Box flex={1} style={{ overflowY: 'auto' }} pt={2}>
                  <TranslateableTextField
                    label='Page title'
                    element={page}
                    lens={titleLens}
                  />
                </Box>
              </TabPanel>
              <TabPanel>
                <PageRulesList page={page} />
              </TabPanel>
            </Box>
          </Tabs>
        </Box>
      </Flex>
    </Sidebar>
  )
}
