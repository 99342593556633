import React, { useCallback, useState } from 'react'
import { Button, PlusIcon, useSnackbar } from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import { UserStatus } from '@woorcs/graphql/schema'

import {
  UserListBulkActions,
  UserListFilters,
  ListPage,
  ListPageHeader,
  ListPageHeaderGroup,
  PageTitle,
  AddUserModal,
  UserList,
  FiltersProvider
} from '@app/components'
import { UserListUserFragment } from '@app/components/UserList/__generated__/UserListFragments'
import { useUserFilters } from '@app/components/UserList/UserFilters/filters'

import {
  DisableUserProfileDocument,
  EnableUserProfileDocument,
  RemoveUsersFromOrganizationDocument
} from './__generated__/UsersPage'

export const UsersPage = () => {
  const { t } = useTranslation()

  const snackbar = useSnackbar()
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const [, removeUsers] = useMutation(RemoveUsersFromOrganizationDocument)
  const [, disableUser] = useMutation(DisableUserProfileDocument)
  const [, enableUser] = useMutation(EnableUserProfileDocument)

  const clearSelectedUsers = useCallback(() => {
    setSelectedUserIds([])
  }, [])

  const handleBulkRemove = useCallback(async () => {
    await removeUsers({
      userIds: selectedUserIds
    })

    clearSelectedUsers()
  }, [clearSelectedUsers, removeUsers, selectedUserIds])

  const handleRemoveUser = useCallback(
    (userId: string) => {
      removeUsers({
        userIds: [userId]
      })
        .then(() => {
          snackbar.showSnackbar({
            variant: 'success',
            title: 'User removed'
          })
        })
        .catch(() => {
          snackbar.showSnackbar({
            variant: 'danger',
            title: 'Failed to remove user'
          })
        })
    },
    [removeUsers, snackbar]
  )

  const handleDisableUser = useCallback(
    (user: UserListUserFragment) => {
      if ([UserStatus.Disabled, UserStatus.Removed].includes(user.status)) {
        enableUser({
          userId: user.id
        })
        return
      }

      disableUser({
        userId: user.id
      })
    },
    [disableUser, enableUser]
  )

  const filtersContext = useUserFilters()

  return (
    <FiltersProvider value={filtersContext}>
      <ListPage>
        <ListPageHeaderGroup>
          <ListPageHeader>
            <PageTitle>{t('common:users')}</PageTitle>
            <AddUserModal>
              <Button colorVariant='secondary' leftIcon={<PlusIcon />}>
                {t('users:usersPage.addUserButton.label')}
              </Button>
            </AddUserModal>
          </ListPageHeader>

          <UserListFilters />
        </ListPageHeaderGroup>

        <UserList
          selectedUserIds={selectedUserIds}
          onSelectedUsersChange={setSelectedUserIds}
          onRemoveUser={handleRemoveUser}
          onDisableUser={handleDisableUser}
        />

        <UserListBulkActions
          selectedCount={selectedUserIds.length}
          onRemove={handleBulkRemove}
          onCancel={clearSelectedUsers}
        />
      </ListPage>
    </FiltersProvider>
  )
}
