import React, { useCallback } from 'react'
import {
  Avatar,
  Flex,
  SecondaryButton,
  TableCell,
  TableRow
} from '@woorcs/design-system'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import * as O from 'fp-ts/Option'
import { useNavigate, useLocation } from 'react-router'
import { useOrganization } from '@woorcs/graphql'

import { pdfUrl } from '@app/utils/router'

import { TagBadge } from '../TagBadge'
import { useSubmissionListFiltersContext } from '../SubmissionListFilters/filters'

import {
  SubmissionListSubmissionFragment,
  TagFragment
} from './__generated__/SubmissionList'

interface SubmissionListRowProps {
  submission: SubmissionListSubmissionFragment
}

export const SubmissionListRow = ({ submission }: SubmissionListRowProps) => {
  const { t } = useTranslation()
  const { setFilter } = useSubmissionListFiltersContext()
  const loading = false
  const navigation = useNavigate()
  const organization = useOrganization()
  const location = useLocation()
  const handleShowDetails = useCallback(() => {
    navigation(`/submissions/${submission.id}`, {
      state: {
        backgroundLocation: location
      }
    })
  }, [location, navigation, submission.id])

  const handleTagClick = useCallback(
    (tag: TagFragment) => {
      setFilter('tags', O.some([tag.id]))
    },
    [setFilter]
  )

  return (
    <TableRow>
      <TableCell>{submission.formRevision.parent.title}</TableCell>
      <TableCell>
        <Flex alignItems='center'>
          <Avatar
            size='small'
            name={submission.user.name}
            bg='orange.50'
            mr={2}
          />
          {submission.user.name}
        </Flex>
      </TableCell>
      <TableCell>
        {submission.user.tags.map((tag) => (
          <TagBadge
            key={tag.id}
            style={{
              cursor: 'pointer'
            }}
            tag={tag}
            mr={2}
            onClick={() => handleTagClick(tag)}
          >
            {tag.name}
          </TagBadge>
        ))}
      </TableCell>
      <TableCell>
        {format(new Date(submission.current.publishedAt), 'yyyy-MM-dd, HH:mm')}
      </TableCell>
      <TableCell textAlign='right'>
        <SecondaryButton size='small' onClick={handleShowDetails}>
          {t('common:history')}
        </SecondaryButton>

        <SecondaryButton
          as='a'
          href={pdfUrl(organization.id, submission.current.id)}
          target='_blank'
          disabled={loading}
          loading={loading}
          size='small'
          ml={2}
        >
          {t('common:download')}
        </SecondaryButton>
      </TableCell>
    </TableRow>
  )
}
