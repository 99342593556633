import React from 'react'
import { Box, Button, Flex, Navbar, Text } from '@woorcs/design-system'
import { useViewer } from '@woorcs/graphql'

import { useAuth } from '@app/auth'

export const OnboardingNavbar = () => {
  const viewer = useViewer()
  const [, { logout }] = useAuth()

  return (
    <Navbar bg='transparent' position='fixed' top={0} left={0} right={0}>
      <Box>
        <Text fontSize='small'>
          Logged in as <Text fontWeight='bold'>{viewer.account.email}</Text>
        </Text>
      </Box>

      <Flex>
        {viewer.account.superuser && (
          <Button as='a' href='/admin' variant='plain' mr={3}>
            Go to admin
          </Button>
        )}
        <Button variant='plain' onClick={() => logout()}>
          Logout
        </Button>
      </Flex>
    </Navbar>
  )
}
