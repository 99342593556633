import React, { useState } from 'react'
import { Box, Flex, Text, LargeBody } from '@woorcs/design-system'
import * as Optional from 'monocle-ts/Optional'
import * as DE from '@nll/datum/DatumEither'
import * as A from 'fp-ts/Array'
import * as Eq from 'fp-ts/Eq'
import * as string from 'fp-ts/string'
import { pipe, constNull, flow } from 'fp-ts/function'
import { eachDayOfInterval, sub, compareAsc } from 'date-fns'
import { useQuery } from 'urql'
import { queryToDatumEither, useViewer } from '@woorcs/graphql'

import {
  Page,
  PageContent,
  PageHeader,
  PageTitle,
  TrendWidget
} from '@app/components'

import {
  TodaysSubmissionsQuery,
  TodaysSubmissionsQueryDocument,
  TodaysSubmissionsSubmissionFragment,
  TodaysSubmissionsUserFragment
} from './__generated__/DashboardPage'

const todaysSubmissionsResponseOptional = pipe(
  Optional.id<TodaysSubmissionsQuery>(),
  Optional.prop('submissions'),
  Optional.prop('data')
)

const getWeekRange = () => {
  const today = new Date()

  return eachDayOfInterval({
    start: sub(today, {
      days: 7
    }),
    end: today
  })
}

const aggregateSubmissionData = (
  submissions: TodaysSubmissionsSubmissionFragment[]
) =>
  pipe(
    getWeekRange(),
    A.map((date) =>
      pipe(
        submissions,
        A.reduce(0, (total, submission) =>
          compareAsc(
            date.setHours(0, 0, 0, 0),
            new Date(submission.current.publishedAt).setHours(0, 0, 0, 0)
          ) === 0
            ? total + 1
            : total
        )
      )
    )
  )

const todaysSubmissionsWidget = flow(
  queryToDatumEither(todaysSubmissionsResponseOptional),
  DE.map(aggregateSubmissionData),
  DE.squash(
    () => <TrendWidget title='Todays submissions' data={[]} />,
    constNull,
    (data) => <TrendWidget title='Todays submissions' data={data} />
  )
)

const EqUser = Eq.contramap((user: TodaysSubmissionsUserFragment) => user.id)(
  string.Eq
)

const aggregateSubmittersData = (
  submissions: TodaysSubmissionsSubmissionFragment[]
) =>
  pipe(
    getWeekRange(),
    A.map((date) =>
      pipe(
        submissions,
        A.reduce([] as TodaysSubmissionsUserFragment[], (users, submission) =>
          compareAsc(
            date.setHours(0, 0, 0, 0),
            new Date(submission.current.publishedAt).setHours(0, 0, 0, 0)
          ) === 0
            ? [...users, submission.user]
            : users
        ),
        A.uniq(EqUser)
      )
    ),
    A.map((users) => users.length)
  )

const todaysSubmittersWidget = flow(
  queryToDatumEither(todaysSubmissionsResponseOptional),
  DE.map(aggregateSubmittersData),
  DE.squash(
    () => <TrendWidget title='Users today' data={[]} />,
    constNull,
    (data) => <TrendWidget title='Users today' data={data} />
  )
)

const SubmissionWidgets = () => {
  const [variables] = useState(() => ({
    from: sub(new Date(), {
      days: 7
    }),
    to: new Date()
  }))
  const query = useQuery({
    query: TodaysSubmissionsQueryDocument,
    variables
  })

  return (
    <Flex mb={6}>
      {todaysSubmissionsWidget(query)}
      {todaysSubmittersWidget(query)}
    </Flex>
  )
}

export const DashboardPage = () => {
  const viewer = useViewer()

  return (
    <Page>
      <PageHeader mb={8} borderBottom='base'>
        <Box>
          <PageTitle mb={2}>Dashboard</PageTitle>
          <LargeBody>
            Welcome back{' '}
            <Text fontWeight='bold'>{`${viewer.firstName} ${viewer.lastName}`}</Text>
          </LargeBody>
        </Box>
      </PageHeader>
      <PageContent>{<SubmissionWidgets />}</PageContent>
    </Page>
  )
}
