import React, { ReactElement, cloneElement } from 'react'
import { MenuButton as BaseMenuButton } from 'reakit/Menu'

import { forwardRef, system } from '../../../system'

import { useMenuButton, UseMenuButtonProps } from './useMenu'

export interface MenuButtonProps extends UseMenuButtonProps {
  children: ReactElement
}

export const MenuButton = forwardRef<MenuButtonProps, typeof system.button>(
  ({ children, ...props }, ref) => {
    const state = useMenuButton(props)

    return (
      <BaseMenuButton ref={ref} {...state}>
        {(disclosureProps) =>
          cloneElement(children as ReactElement, disclosureProps)
        }
      </BaseMenuButton>
    )
  }
)
