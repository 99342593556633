import React from 'react'
import { Menu } from 'reakit/Menu'
import { ariaAttr, rem } from '@woorcs/utils'

import {
  PopoverContainer,
  PopoverInner,
  PopoverContainerProps
} from '../Popover'

import { useMenuContent } from './useMenu'

type MenuContentProps = PopoverContainerProps

const minWidth = rem(180)

export const MenuContent = ({ children, ...props }: MenuContentProps) => {
  const state = useMenuContent()

  return (
    <Menu
      as={PopoverContainer}
      aria-label={ariaAttr(props['aria-label'])}
      {...state}
    >
      <PopoverInner
        minWidth={minWidth}
        p={2}
        visible={state.visible ?? false}
        {...props}
      >
        {children}
      </PopoverInner>
    </Menu>
  )
}
