import * as SchemaTypes from '@woorcs/graphql/schema';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { EditFormFormFragmentDoc } from '../../../components/EditForm/__generated__/EditForm';

/* eslint-disable max-lines, no-use-before-define, camelcase, no-unused-vars */
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run yarn run graphql:codegen to update
  
export type FormRevisionEditorPageQueryVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID'];
}>;


export type FormRevisionEditorPageQuery = { __typename: 'Query', form: { __typename: 'Form', id: string, title: string, latestRevision: { __typename: 'FormRevision', id: string, definition: any, publishedAt: any | null, createdAt: any }, revisions: Array<{ __typename: 'FormRevision', id: string, definition: any, publishedAt: any | null, createdAt: any, status: SchemaTypes.FormRevisionStatus }> } };


export const FormRevisionEditorPageQueryDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FormRevisionEditorPageQuery"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"form"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"EditFormFormFragment"}}]}}]}},...EditFormFormFragmentDoc.definitions]} as unknown as DocumentNode<FormRevisionEditorPageQuery, FormRevisionEditorPageQueryVariables>;