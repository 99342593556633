import common from './common.json'
import app from './app.json'
import onboarding from './onboarding.json'
import organizations from './organizations.json'
import submissions from './submissions.json'
import users from './users.json'
import viewer from './viewer.json'
import tags from './tags.json'
import forms from './forms.json'
import roles from './roles.json'

export const en = {
  app,
  common,
  onboarding,
  organizations,
  submissions,
  users,
  viewer,
  tags,
  forms,
  roles
} as const
