import React, { ReactElement, ReactNode } from 'react'

import { Flex } from '../../layout'
import { forwardRef, system, PropsOf } from '../../../system'
import { Text } from '../../Text'

import { NAV_SIDEBAR_COLLAPSED_WIDTH } from './NavSidebar'

export interface NavSidebarMenuItemProps extends PropsOf<typeof system.a> {
  icon: ReactElement
  children: ReactNode
  'aria-label': string
}

export const NAV_SIDEBAR_MENU_ITEM_COLLAPSED_WIDTH = NAV_SIDEBAR_COLLAPSED_WIDTH

export const NavSidebarMenuItem = forwardRef<
  NavSidebarMenuItemProps,
  typeof system.a
>(({ icon, children, ...other }, ref) => {
  return (
    <Flex
      ref={ref}
      as='a'
      alignItems='center'
      justifyContent='flex-start'
      flexDirection='row'
      pr={2}
      py={2}
      borderRadius='large'
      color='grey.600'
      __css={{
        cursor: 'pointer',
        transition: 'transform 0.25s ease-out, color 0.25s ease-out',
        ':hover': {
          color: 'grey.900',
          transform: 'scale(1.025)'
        },
        '&[aria-current="page"]': {
          color: 'primary.500',
          bg: 'primary.50',
          ':hover': {
            color: 'primary.600'
          }
        }
      }}
      {...other}
    >
      <Flex
        alignItems='center'
        justifyContent='center'
        css={{
          flexGrow: 0,
          width: 42,
          ':focus': {
            outline: 'none'
          }
        }}
      >
        {icon}
      </Flex>
      <Text fontSize='base' fontWeight='semi-bold'>
        {children}
      </Text>
    </Flex>
  )
})
