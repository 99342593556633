import React from 'react'
import { keyframes } from '@emotion/react'
import { rem } from '@woorcs/utils'

import { system, PropsOf, forwardRef } from '../../../system'
import { VisuallyHidden } from '../../layout'

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)'
  },
  '100%': {
    transform: 'rotate(360deg)'
  }
})

type SpinnerSize = 'huge' | 'large' | 'medium' | 'small' | 'mini'

const sizeStyles = {
  mini: {
    width: rem(12),
    height: rem(12)
  },
  small: {
    width: rem(18),
    height: rem(18)
  },
  medium: {
    width: rem(32),
    height: rem(32)
  },
  large: {
    width: rem(44),
    height: rem(44)
  },
  huge: {
    width: rem(54),
    height: rem(54)
  }
}

export interface SpinnerProps extends PropsOf<typeof system.div> {
  size?: SpinnerSize
  emptyColor?: string
  thickness?: string
  speed?: string
  label?: string
}

export const Spinner = forwardRef<SpinnerProps, 'div'>(
  (
    {
      label = 'Loading...',
      thickness = '2px',
      speed = '0.75s',
      color = 'primary.500',
      size = 'medium',
      emptyColor = 'transparent',
      ...other
    },
    ref
  ) => {
    const spinnerStyles = {
      display: 'inline-block',
      borderColor: 'currentColor',
      borderStyle: 'solid',
      borderRadius: 'full',
      borderWidth: thickness,
      borderBottomColor: emptyColor,
      borderLeftColor: emptyColor,
      color,
      animation: `${spin} ${speed} linear infinite`,
      ...sizeStyles[size]
    }

    return (
      <system.div ref={ref} __css={spinnerStyles} {...other}>
        {label && <VisuallyHidden>{label}</VisuallyHidden>}
      </system.div>
    )
  }
)
