import React, { ChangeEvent, useCallback, useState } from 'react'
import { Box, Input, SearchIcon, Text } from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import * as O from 'fp-ts/Option'
import { identity, pipe } from 'fp-ts/function'

import { ListPageFilters, PaginiationLimitSelect } from '@app/components'

import { useUserListFiltersContext } from './filters'

export const UserListFilters = () => {
  const { t } = useTranslation(['users'])
  const { filters, setFilter, limit, setLimit } = useUserListFiltersContext()

  const inputChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setFilter('query', O.some(event.target.value))
    },
    [setFilter]
  )

  return (
    <ListPageFilters>
      <Input
        flexGrow={1}
        width='auto'
        placeholder={t('users:usersPage.searchInput.placeholder')}
        leftAddon={<SearchIcon size='small' />}
        variant='filled'
        value={pipe(
          filters.query,
          O.fold(() => '', identity)
        )}
        onChange={inputChanged}
      />

      <Box ml={20}>
        <Text fontSize='small' fontWeight='semi-bold' color='grey.400'>
          1-50 of 300
        </Text>
        <Box display='none'>
          <PaginiationLimitSelect value={limit} onChange={setLimit} />
        </Box>
      </Box>
    </ListPageFilters>
  )
}
