import React, { useRef, ReactNode, useMemo } from 'react'
import { Provider as ReduxProvider } from 'react-redux/es/compat'
import { FormContextProvider, useFormContext } from '@woorcs/form/react'
import {
  Fields,
  formEnvironment,
  FormSubmission,
  ResponseSetRegistry,
  DocumentsRegistry,
  Translator,
  RuleEngine
} from '@woorcs/form'
import { none } from 'fp-ts/Option'

import { Editor } from './state'
import { EditorContextProvider } from './context'
import { useValue } from './hooks'

interface FormEnvironmentProviderProps {
  children: ReactNode
}

export const FormEnvironmentProvider = ({
  children
}: FormEnvironmentProviderProps) => {
  const root = useValue()
  const {
    responseSets = ResponseSetRegistry.getResponseSetRegistry(
      root.responseSets
    ),
    documents = DocumentsRegistry.getDocumentsRegistry(root.documents),
    i18n = Translator.getTranslator(root.i18n)
  } = useFormContext() ?? {}

  const formCtx = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const locale = null as any
    const fields = Fields.fromDocument(root)({
      responseSets,
      i18n,
      locale
    })
    const data = FormSubmission.fromFields(fields, {})
    const submission = FormSubmission.getFormSubmissionStore(data)

    return formEnvironment({
      responseSets,
      documents,
      i18n,
      locale,
      ruleEngine: RuleEngine.getRuleEngine(() => none),
      submission
    })
  }, [documents, i18n, responseSets, root])

  return <FormContextProvider value={formCtx}>{children}</FormContextProvider>
}

interface EditorProviderProps {
  children: ReactNode
  editor: Editor
}

export const EditorProvider = ({ editor, children }: EditorProviderProps) => {
  const store = editor.__store
  const ctx = useRef({
    editor
  }).current

  return (
    <EditorContextProvider value={ctx}>
      <ReduxProvider
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        store={store as any}
      >
        <FormEnvironmentProvider>{children}</FormEnvironmentProvider>
      </ReduxProvider>
    </EditorContextProvider>
  )
}
