import React, { ReactElement } from 'react'
import * as t from 'io-ts'
import { Formik, FormikHelpers, Form } from 'formik'
import {
  Input,
  Stack,
  Modal,
  ModalFooter,
  ModalDialog,
  ModalDisclosure,
  SecondaryButton,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ColorInput
} from '@woorcs/design-system'
import { useTranslation } from 'react-i18next'
import {
  NonEmptyTrimmedString,
  HexColor,
  getFormikValidator
} from '@woorcs/utils/formik'

import { SubmitButton, FormField } from '@app/components'

const TagForm = t.type({
  name: NonEmptyTrimmedString,
  color: HexColor,
  description: t.string
})

export type TagFormTag = t.TypeOf<typeof TagForm>

const validateForm = getFormikValidator(TagForm)

const defaultInitialValues = {
  name: '',
  description: '',
  color: ''
}

interface TagFormModalProps {
  title: string
  visible?: boolean
  initialValues?: Record<keyof TagFormTag, unknown>
  children: ReactElement
  onVisiblityChange?(visible: boolean): void
  onSubmit(tag: TagFormTag, actions: FormikHelpers<TagFormTag>): void
}

export const TagFormModal = ({
  title,
  visible,
  onSubmit,
  children,
  onVisiblityChange,
  initialValues = defaultInitialValues
}: TagFormModalProps) => {
  const { t } = useTranslation()

  return (
    <Modal visible={visible} onVisiblityChange={onVisiblityChange}>
      <ModalDisclosure>{children}</ModalDisclosure>
      <ModalDialog aria-label={title} role='dialog'>
        <ModalHeader>
          <ModalTitle mb={6}>{title}</ModalTitle>
        </ModalHeader>

        <Formik
          validate={validateForm}
          initialValues={initialValues as any}
          onSubmit={onSubmit}
        >
          {() => (
            <Form>
              <ModalBody>
                <Stack spacing={6}>
                  <FormField name='name' label={t('common:name')} required>
                    <Input />
                  </FormField>
                  <FormField name='description' label={t('common:description')}>
                    <Input type='textarea' />
                  </FormField>
                  <FormField name='color' label={t('common:colors')} required>
                    <ColorInput />
                  </FormField>
                </Stack>
              </ModalBody>

              <ModalFooter>
                <ModalDisclosure>
                  <SecondaryButton mr={2}>{t('common:cancel')}</SecondaryButton>
                </ModalDisclosure>
                <SubmitButton label={t('common:save')} />
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalDialog>
    </Modal>
  )
}
