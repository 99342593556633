import { mergeDeepRight } from 'ramda'
import * as A from 'fp-ts/Array'
// import { FunctionInterpolation } from '@emotion/styled'
import css, {
  CSSObject,
  CSSPseudoSelectorProps,
  CSSSelectorObject,
  EmotionLabel,
  SystemCssProperties,
  VariantProperty
} from '@styled-system/css'
import { pipe } from 'fp-ts/function'

import { isFunction } from '../isFunction'

export type InterpolationPrimitive =
  | SystemCssProperties
  | CSSPseudoSelectorProps
  | CSSSelectorObject
  | VariantProperty
  | EmotionLabel
  | null
  | undefined

type ArrayInterpolation<Props> = Array<Interpolation<Props>>

interface FunctionInterpolation<Props> {
  (props: Props): Interpolation<Props>
}

export type Interpolation<Props> =
  | InterpolationPrimitive
  | ArrayInterpolation<Props>
  | FunctionInterpolation<Props>

const interpolate =
  <P>(props: P) =>
  (style: Interpolation<P>) => {
    if (isFunction(style)) {
      return css(style(props) as any)(props)
    }

    return css(style)(props)
  }

export const combineInterpolators =
  <P>(...interpolations: Interpolation<P>[]) =>
  (props: P): Interpolation<P> =>
    pipe(
      interpolations,
      A.reduce([] as Interpolation<P>[], (interpolators, interpolation) => {
        if (Array.isArray(interpolation)) {
          return pipe(interpolators, A.concat(interpolation))
        }

        return pipe(interpolators, A.append(interpolation))
      }),
      A.map(interpolate(props)),
      A.reduce({} as CSSObject, mergeDeepRight)
    )

export const whenTrue =
  <K extends string, Props extends Partial<Record<K, boolean>>>(keys: K[]) =>
  (...styles: Interpolation<Props>[]) =>
  (props: Props) => {
    const correct = pipe(
      keys,
      A.every((key) => props[key] ?? false)
    )

    if (correct) {
      return pipe(props, combineInterpolators(styles))
    }
  }

export const whenFalse =
  <K extends string, Props extends Partial<Record<K, boolean>>>(keys: K[]) =>
  (...styles: Interpolation<Props>[]) =>
  (props: Props) => {
    const correct = pipe(
      keys,
      A.every((key) => Boolean(props[key]))
    )

    if (correct) {
      return pipe(props, combineInterpolators(styles))
    }
  }

export const whenIsEither =
  <K extends string, Props extends Partial<Record<K, boolean>>>(keys: K[]) =>
  (...styles: Interpolation<Props>[]) =>
  (props: Props) => {
    const correct = pipe(
      keys,
      A.some((key) => props[key] ?? false)
    )

    if (correct) {
      return pipe(props, combineInterpolators(styles))
    }
  }

export const whenPropEq =
  <K extends string, V, Props extends Partial<Record<K, V>>>(
    key: K,
    value: V
  ) =>
  (...styles: Interpolation<Props>[]) =>
  (props: Props) => {
    if (props[key] === value) {
      return pipe(props, combineInterpolators(styles))
    }
  }
