import {
  useMenuState,
  MenuStateReturn,
  MenuInitialState,
  MenuProps,
  MenuButtonProps,
  MenuItemProps,
  MenuHTMLProps
} from 'reakit/Menu'
import { createContext, ariaAttr } from '@woorcs/utils'
import {
  UseSelectStateReturn,
  useSelectState,
  UseSelectStateProps,
  useControllableState
} from '@woorcs/hooks'
import { MutableRefObject, ReactText, useCallback } from 'react'

type MenuOptions = Pick<MenuProps, 'hideOnClickOutside' | 'preventBodyScroll'>

export interface MenuContext extends MenuOptions {
  state: MenuStateReturn
  hideOnSelect?: boolean
  getContentProps(): Omit<MenuProps, keyof MenuHTMLProps>
}

export const [MenuProvider, useMenuContext] = createContext<MenuContext>()

export interface UseMenuProps
  extends Omit<
      MenuInitialState,
      | 'unstable_fixed'
      | 'unstable_flip'
      | 'unstable_offset'
      | 'unstable_preventOverflow'
      | 'unstable_autoFocusOnHide'
    >,
    MenuOptions {
  /**
   * Controlls the visiblity state of the popover
   */
  visible?: boolean
  /**
   * Sets the default visibility state
   */
  defaultVisible?: boolean

  /**
   * Automatically hides the menu when a menu item is selected
   */
  hideOnSelect?: boolean

  /**
   * Offset between the reference and the popover: [main axis, alt axis]. Should not be combined with gutter.
   */
  offset?: [ReactText, ReactText]

  initialFocusRef?: MutableRefObject<HTMLElement | null>
  finalFocusRef?: MutableRefObject<HTMLElement | null>

  /**
   * Called when the visiblity state changes
   */
  onVisiblityChange?(visible: boolean): void
}

export const useMenu = ({
  offset,
  visible: propVisible,
  defaultVisible,
  hideOnClickOutside,
  preventBodyScroll,
  onVisiblityChange,
  hideOnSelect,
  initialFocusRef,
  finalFocusRef,
  placement = 'bottom-start',
  gutter = 4,
  loop = true,
  ...props
}: UseMenuProps): MenuContext => {
  const state = useMenuState({
    ...props,
    placement,
    gutter,
    loop,
    visible: defaultVisible ?? propVisible,
    unstable_offset: offset
  })

  const visible = useControllableState(
    propVisible,
    state.visible,
    state.setVisible,
    onVisiblityChange
  )

  const getContentProps = () => ({
    ...state,
    visible,
    hideOnClickOutside,
    preventBodyScroll,
    unstable_initialFocusRef: initialFocusRef,
    unstable_finalFocusRef: finalFocusRef
  })

  return {
    hideOnClickOutside,
    preventBodyScroll,
    hideOnSelect,
    getContentProps,
    state: {
      ...state,
      visible
    }
  }
}

export type UseMenuButtonProps = Pick<MenuButtonProps, 'disabled' | 'focusable'>

export const useMenuButton = (props: UseMenuButtonProps): MenuButtonProps => {
  const { state } = useMenuContext()

  return {
    ...props,
    ...state
  }
}

export type UseMenuItemProps = Pick<
  MenuItemProps,
  'disabled' | 'focusable' | 'id' | 'onClick'
>

export const useMenuItem = (props: UseMenuItemProps) => {
  const { state, hideOnSelect } = useMenuContext()

  const handleClick = useCallback(
    (e) => {
      props.onClick?.(e)

      if (hideOnSelect && !e.defaultPrevented) {
        state.toggle()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hideOnSelect, props.onClick, state]
  )

  return {
    ...props,
    ...state,
    onClick: handleClick
  }
}

export const useMenuContent = () => {
  const { getContentProps } = useMenuContext()

  return getContentProps()
}

interface MenuOptionGroupContext<T> extends UseSelectStateReturn<T> {
  type: 'radio' | 'checkbox'
  name?: string
}

export const [MenuOptionGroupProvider, useMenuOptionGroupContext] =
  createContext<MenuOptionGroupContext<any>>({
    errorMessage: '`MenuOption` must be be a child of a `MenuOptionGroup`'
  })

export interface UseMenuOptionGroupProps<T>
  extends Omit<UseSelectStateProps<T>, 'multiple'> {
  type: 'radio' | 'checkbox'
  name?: string
}

export const useMenuOptionGroup = <T>({
  type,
  name,
  ...other
}: UseMenuOptionGroupProps<T>): MenuOptionGroupContext<T> => {
  const state = useSelectState({
    multiple: type === 'checkbox',
    ...other
  })

  return {
    type,
    name,
    ...state
  }
}

export interface UseMenuOptionProps extends UseMenuItemProps {
  value: any
  onChange?(value: any): void
}

export const useMenuOption = (props: UseMenuOptionProps) => {
  const { type, onChange, isSelected } = useMenuOptionGroupContext()
  const checked = isSelected(props.value)

  const handleClick = useCallback(
    (e) => {
      props.onClick?.(e)

      if (e.defaultPrevented) {
        return
      }

      onChange(props.value)
      props.onChange?.(props.value)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange, props.onChange, props.onClick, props.value]
  )

  return {
    role: `menuitem${type}`,
    'aria-selected': ariaAttr(checked),
    checked,
    onClick: handleClick,
    ...props
  }
}
