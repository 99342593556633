import React, { KeyboardEvent, useCallback, useRef, useState } from 'react'
import { Input, InputProps, Textarea } from '@woorcs/design-system'

interface BlurInputProps
  extends Omit<InputProps, 'value' | 'onChange' | 'onBlur'> {
  value: string
  allowEmpty?: boolean
  onChange(value: string): void
  multiline?: boolean
}

export const BlurInput = ({
  value: initialValue = '',
  allowEmpty = false,
  onChange,
  multiline = false,
  ...other
}: BlurInputProps) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const [value, setValue] = useState(initialValue)
  const isEmpty = !value || value.length === 0

  const handleBlur = useCallback(() => {
    if (isEmpty && !allowEmpty) {
      onChange(initialValue)
    }

    onChange(value)
  }, [allowEmpty, initialValue, isEmpty, onChange, value])

  const handleChange = useCallback((e) => {
    const value = e.target.value

    setValue(value)
  }, [])

  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent<HTMLInputElement>) => {
      if (key === 'Enter' && !multiline) {
        ref.current?.blur()
      }
    },
    [multiline]
  )

  let Component = Input

  if (multiline) {
    Component = Textarea
  }

  return (
    <Component
      ref={ref}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      {...other}
    />
  )
}
