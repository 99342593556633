import React, { ReactElement, useCallback, useState } from 'react'
import { ListItemTitle, SelectableListItem, Text } from '@woorcs/design-system'
import * as Optional from 'monocle-ts/Optional'
import { pipe } from 'fp-ts/function'
import * as DE from '@nll/datum/DatumEither'
import * as O from 'fp-ts/Option'
import * as string from 'fp-ts/string'
import { useQuery } from 'urql'
import { queryToDatumEither } from '@woorcs/graphql'

import { SearchModal } from '../SearchModal'

import {
  SelectFormModalQuery,
  SelectFormModalFormFragment,
  SelectFormModalQueryDocument
} from './__generated__/SelectFormModal'

interface FormListItemProps {
  isSelected: boolean
  form: SelectFormModalFormFragment
  onSelect(form: SelectFormModalFormFragment): void
}

const FormListItem = ({ isSelected, form, onSelect }: FormListItemProps) => {
  const handleClick = useCallback(() => {
    onSelect(form)
  }, [onSelect, form])

  return (
    <SelectableListItem
      alignItems='center'
      isSelected={isSelected}
      onClick={handleClick}
    >
      <ListItemTitle>
        <Text>{form.title}</Text>
      </ListItemTitle>
    </SelectableListItem>
  )
}

const responseOptional = pipe(
  Optional.id<SelectFormModalQuery>(),
  Optional.prop('forms')
)

interface SelectFormModalProps {
  searchPlaceholder?: string
  selectedId: O.Option<string>
  onSelect(form: SelectFormModalFormFragment): void
  children: ReactElement
}

const filterTag = (form: SelectFormModalFormFragment, query: string) =>
  form.title.toLocaleLowerCase().includes(query)

export const SelectFormModal = ({
  searchPlaceholder = 'Search forms',
  onSelect,
  selectedId,
  children
}: SelectFormModalProps) => {
  const [query, setQuery] = useState<string>('')

  const isSelected = (form: SelectFormModalFormFragment) =>
    O.getEq(string.Eq).equals(O.some(form.id), selectedId)

  const handleSelect = useCallback(
    (form) => {
      onSelect(form)
    },
    [onSelect]
  )

  return pipe(
    useQuery({ query: SelectFormModalQueryDocument }),
    queryToDatumEither(responseOptional),
    DE.squash(
      () => null,
      () => null,
      (forms) => (
        <SearchModal
          items={forms}
          query={query}
          searchPlaceholder={searchPlaceholder}
          filterItem={filterTag}
          renderItem={(form) => (
            <FormListItem
              key={form.id}
              form={form}
              isSelected={isSelected(form)}
              onSelect={handleSelect}
            />
          )}
          onQueryChange={setQuery}
        >
          {children}
        </SearchModal>
      )
    )
  )
}
