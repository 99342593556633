import * as O from 'fp-ts/Option'
import { Box, Input } from '@woorcs/design-system'
import React from 'react'
import { constant, constUndefined, pipe } from 'fp-ts/function'
import { Locale } from '@woorcs/form'

import { SelectLanguageMenu } from '../SelectLanguageMenu'
import { CountryFlag } from '../CountryFlag'

type SelectLanguageInputProps = {
  value: O.Option<Locale.Locale>
  onChange(locale: Locale.Locale): void
}

export const SelectLanguageInput = ({
  value,
  onChange
}: SelectLanguageInputProps) => {
  const label = pipe(value, O.fold(constant(''), Locale.getLocaleLabel))
  const flag = pipe(
    value,
    O.chain((value) => {
      if (value.length === 0) {
        return O.none
      }

      return O.some(value)
    }),
    O.fold(constUndefined, (locale) => <CountryFlag locale={locale} />)
  )

  return (
    <SelectLanguageMenu onSelectLanguage={onChange}>
      <Box>
        <Input leftAddon={flag} value={label} placeholder='Select a language' />
      </Box>
    </SelectLanguageMenu>
  )
}
