import React, { memo, ReactElement, useCallback } from 'react'
import {
  TableCell,
  TableRow,
  IconButton,
  TrashIcon,
  ConfirmAlertDialog,
  Text
} from '@woorcs/design-system'

import { useViewer } from '../../graphql'

import { SuperuserListUserFragment } from './__generated__/SuperuserList'

interface SuperuserRowProps {
  superuser: SuperuserListUserFragment
  onRemove(id: string): void
}

type ConfirmRemoveAlertProps = {
  superuser: SuperuserListUserFragment
  onConfirm(): void
  children: ReactElement
}

const ConfirmRemoveAlert = memo(
  ({ superuser, onConfirm, children }: ConfirmRemoveAlertProps) => {
    return (
      <ConfirmAlertDialog
        title={`Ta bort \`${superuser.account.email}\``}
        body='Är du säker på att du vill ta bort den här användaren?'
        confirmLabel='Ta bort'
        onConfirm={onConfirm}
      >
        {children}
      </ConfirmAlertDialog>
    )
  }
)

export const SuperuserRow = ({ superuser, onRemove }: SuperuserRowProps) => {
  const viewer = useViewer()
  const isViewer = superuser.id === viewer.id

  const handleRemoveUser = useCallback(() => {
    onRemove(superuser.account.id)
  }, [onRemove, superuser.account.id])

  return (
    <TableRow>
      <TableCell>
        <Text fontSize='largeBody'>{superuser.name}</Text>
      </TableCell>
      <TableCell>
        <Text fontSize='largeBody'>{superuser.account.email}</Text>
      </TableCell>
      <TableCell textAlign='right'>
        <ConfirmRemoveAlert superuser={superuser} onConfirm={handleRemoveUser}>
          <IconButton disabled={isViewer}>
            <TrashIcon />
          </IconButton>
        </ConfirmRemoveAlert>
      </TableCell>
    </TableRow>
  )
}
