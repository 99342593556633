import React, { ReactNode } from 'react'

import { Box } from '../../layout'
import { forwardRef, splitStyledProps, PropsOf, system } from '../../../system'

import { FormFieldLabel } from './FormFieldLabel'
import { FormFieldMessages } from './FormFieldMessages'
import {
  FormFieldProvider,
  useFormFieldProvider,
  UseFormFieldProviderProps
} from './context'

export interface FormFieldProps
  extends UseFormFieldProviderProps,
    PropsOf<typeof system.div> {
  showMessages?: boolean
  label?: ReactNode
}

export const FormField = forwardRef<FormFieldProps, 'div'>(
  ({ label, showMessages = true, children, ...props }, ref) => {
    const [styleProps, other] = splitStyledProps(
      props as Omit<FormFieldProps, 'as'>
    )

    const context = useFormFieldProvider(other)

    return (
      <FormFieldProvider value={context}>
        <Box ref={ref} role='group' {...styleProps}>
          {label && <FormFieldLabel>{label}</FormFieldLabel>}

          {children}

          {showMessages && <FormFieldMessages />}
        </Box>
      </FormFieldProvider>
    )
  }
)
