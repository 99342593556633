import React, { ReactElement, Children, cloneElement } from 'react'
import { ResponsiveValue, ThemeValue } from 'styled-system'

import { forwardRef, system, PropsOf } from '../../../system'
import { Flex } from '../Flex'

type StackDirection = 'vertical' | 'horizontal'
type StackAlign = 'start' | 'end'

export interface StackProps extends PropsOf<typeof system.div> {
  // TODO: Fix handling responsive values
  spacing?: ResponsiveValue<ThemeValue<'space', any>, any>
  align?: StackAlign
  direction?: StackDirection
  children: ReactElement<{
    mb: ResponsiveValue<ThemeValue<'space', any>, any>
    mr: ResponsiveValue<ThemeValue<'space', any>, any>
  }>[]
}

export const Stack = forwardRef<StackProps, typeof system.div>(
  (
    {
      spacing = 4,
      align = 'start',
      direction = 'vertical',
      children,
      ...other
    },
    ref
  ) => {
    const spacingProperty =
      // TODO: Remove this ugly ternary
      // eslint-disable-next-line no-nested-ternary
      direction === 'horizontal'
        ? align === 'start'
          ? 'mr'
          : 'ml'
        : align === 'start'
        ? 'mb'
        : 'mt'

    const shouldOmitSpacing = (arr: any[], index: number) => {
      if (align === 'start') {
        return arr.length - 1 === index
      }

      return index === 0
    }

    return (
      <Flex
        ref={ref}
        flexDirection={direction === 'vertical' ? 'column' : 'row'}
        {...other}
      >
        {Children.map(children, (child, index) =>
          shouldOmitSpacing(children as any, index)
            ? child
            : cloneElement(child as any, {
                [spacingProperty]: spacing
              })
        )}
      </Flex>
    )
  }
)
