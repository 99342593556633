import React, { forwardRef } from 'react'
import { MenuGroup as BaseMenuGroup } from 'reakit/Menu'

import { Box, BoxProps } from '../../layout'
import { Subtitle2 } from '../../typography'

export interface MenuGroupOptions {
  label?: string
}

export interface MenuGroupProps
  extends Omit<BoxProps, 'as'>,
    MenuGroupOptions {}

export const MenuGroup = forwardRef<HTMLDivElement, MenuGroupProps>(
  ({ label, children, ...props }, ref) => (
    <BaseMenuGroup ref={ref} as={Box} {...props}>
      {label && (
        <Box px={1} py={1}>
          <Subtitle2>{label}</Subtitle2>{' '}
        </Box>
      )}
      {children}
    </BaseMenuGroup>
  )
)
