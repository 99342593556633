import React from 'react'

import { forwardRef, system, PropsOf } from '../../../system'
import { Input } from '../Input'
import { ChevronDownIcon } from '../../icons'

const NativeSelectInput = system('select')()

export const NativeSelect = forwardRef<
  PropsOf<typeof Input>,
  typeof system.select
>(({ type = 'text', ...other }, ref) => (
  <Input
    ref={ref}
    type={type}
    as={NativeSelectInput}
    rightAddon={<ChevronDownIcon size='small' />}
    {...other}
  />
))
