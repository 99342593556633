import React, { ReactNode } from 'react'
import {
  Box,
  Flex,
  TabList,
  Tab,
  Tabs,
  Text,
  TabPanel,
  system,
  IconButton,
  MoreIcon,
  Menu,
  MenuButton,
  MenuContent,
  MenuItem,
  MenuSeparator,
  ChevronLeftIcon
} from '@woorcs/design-system'
import { FormElement } from '@woorcs/form'

import { formElementIcon, formElementLabel } from '../../element'
import { ElementForm } from '../ElementForm'
import { ElementRulesList } from '../ElementRulesList'

const Section = system('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottomColor: 'grey.50',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  px: 5
})

interface ElementMenuProps {
  children: ReactNode
}

const ElementMenu = ({ children }: ElementMenuProps) => {
  return (
    <Menu placement='bottom-end' hideOnSelect>
      <MenuButton>{children}</MenuButton>

      <MenuContent>
        <MenuItem>Copy</MenuItem>
        <MenuSeparator />
        <MenuItem destructive>Delete</MenuItem>
      </MenuContent>
    </Menu>
  )
}

type EditElementMenuProps = {
  element: FormElement.FormElementType
  onClose(): void
}

export const EditElementMenu = ({ element, onClose }: EditElementMenuProps) => {
  const Icon = formElementIcon(element.type)
  const label = formElementLabel(element.type)

  return (
    <Flex width='100%' height='100%' flexDirection='column'>
      <Section>
        <Flex alignItems='center'>
          <IconButton size='mini' mr={2} onClick={onClose}>
            <ChevronLeftIcon />
          </IconButton>
          <Flex alignItems='center' py={5}>
            <Icon mr={2} />

            <Text fontSize='small' fontWeight='bold'>
              {label}
            </Text>
          </Flex>
        </Flex>
        <ElementMenu>
          <IconButton>
            <MoreIcon />
          </IconButton>
        </ElementMenu>
      </Section>
      <Box
        css={{
          overflowY: 'auto'
        }}
      >
        <Tabs>
          <Section pt={4}>
            <Flex height='100%' alignItems='flex-end' mb='-1px'>
              <TabList>
                <Tab>Options</Tab>
                <Tab>Rules</Tab>
              </TabList>
            </Flex>
          </Section>
          <Box px={5} py={4}>
            <TabPanel>
              <ElementForm element={element} />
            </TabPanel>
            <TabPanel>
              <ElementRulesList element={element} />
            </TabPanel>
          </Box>
        </Tabs>
      </Box>
    </Flex>
  )
}
