/* eslint-disable @typescript-eslint/no-use-before-define */

import React, { ReactElement, ReactNode, useCallback } from 'react'
import {
  Badge,
  Box,
  Button,
  Card,
  CloseIcon,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuContent,
  MenuItem,
  SelectButton,
  SelectOption,
  Text
} from '@woorcs/design-system'
import { pipe } from 'fp-ts/function'
import { space } from '@woorcs/utils'
import { Condition, ConditionGroup } from '@woorcs/form'

import { ConditionForm } from './ConditionForm'

type AddConditionMenuProps = {
  onAddLeaf(): void
  onAddGroup(): void
  children: ReactElement
}

const AddConditionMenu = ({
  onAddLeaf,
  onAddGroup,
  children
}: AddConditionMenuProps) => {
  return (
    <Menu hideOnSelect>
      <MenuButton>{children}</MenuButton>

      <MenuContent aria-label='Add condition'>
        <MenuItem onClick={onAddLeaf}>Single condition</MenuItem>
        <MenuItem onClick={onAddGroup}>Condition group</MenuItem>
      </MenuContent>
    </Menu>
  )
}

export const CONDITION_GROUP_CONDITION_LABEL_WIDTH = space(13)

type ConditionGroupConditionLabelProps = {
  children: ReactNode
}

export const ConditionGroupConditionLabel = ({
  children
}: ConditionGroupConditionLabelProps) => {
  return (
    <Box
      css={{
        width: CONDITION_GROUP_CONDITION_LABEL_WIDTH
      }}
      pt={2}
      flexShrink={0}
    >
      <Badge variant='info'>{children}</Badge>
    </Box>
  )
}

type ConditionGroupType = ConditionGroup.ConditionGroup['_tag']

type ConditionGroupConditionProps = {
  condition: Condition.Condition
  groupType: ConditionGroupType
  index: number
  isFirst: boolean
  isLast: boolean
  onRemove(index: number): void
  onChange(index: number, condition: Condition.Condition): void
}

const ConditionGroupCondition = ({
  condition,
  groupType,
  index,
  isFirst,
  isLast,
  onRemove,
  onChange
}: ConditionGroupConditionProps) => {
  const handleRemove = useCallback(() => {
    onRemove(index)
  }, [index, onRemove])

  const handleChange = useCallback(
    (condition: Condition.Condition) => {
      onChange(index, condition)
    },
    [index, onChange]
  )

  const getGroupLabel = () => {
    if (isFirst) {
      return 'if'
    }

    switch (groupType) {
      case 'and': {
        return 'and'
      }

      case 'or': {
        return 'or'
      }
    }
  }

  const renderConditionType = () => {
    if (condition._tag === 'leaf') {
      return (
        <Flex alignItems='center' width='100%'>
          <Box mr={2} width='100%'>
            <ConditionForm
              key={index}
              condition={condition}
              onChange={handleChange}
            />
          </Box>
          <IconButton
            size='mini'
            opacity={isFirst ? 0 : 1}
            flexShrink={0}
            onClick={handleRemove}
          >
            <CloseIcon />
          </IconButton>
        </Flex>
      )
    }

    return (
      <Card p={4}>
        <Flex justifyContent='flex-end'>
          <IconButton size='mini' onClick={handleRemove}>
            <CloseIcon />
          </IconButton>
        </Flex>

        <ConditionGroupForm
          key={index}
          group={condition}
          onChange={handleChange}
        />
      </Card>
    )
  }

  return (
    <Box mb={isLast ? 0 : 4}>
      <Flex>
        <ConditionGroupConditionLabel>
          {getGroupLabel()}
        </ConditionGroupConditionLabel>
        {renderConditionType()}
      </Flex>
    </Box>
  )
}

type ConditionGroupFormProps = {
  group: Condition.ConditionGroup
  onChange(group: Condition.ConditionGroup): void
}

export const ConditionGroupForm = ({
  group,
  onChange
}: ConditionGroupFormProps) => {
  const handleRemoveCondition = useCallback(
    (index: number) => {
      pipe(group, ConditionGroup.removeCondition(index), onChange)
    },
    [group, onChange]
  )

  const handleAddGroup = useCallback(() => {
    pipe(
      group,
      ConditionGroup.addCondition(
        Condition.and([Condition.leaf('', '', 'eq')])
      ),
      onChange
    )
  }, [group, onChange])

  const handleAddLeaf = useCallback(() => {
    pipe(
      group,
      ConditionGroup.addCondition(Condition.leaf('', '', 'eq')),
      onChange
    )
  }, [group, onChange])

  const handleOnChildChange = useCallback(
    (index: number, update: Condition.Condition) => {
      pipe(group, ConditionGroup.updateCondition(index, update), onChange)
    },
    [group, onChange]
  )

  const handleGroupTypeChange = useCallback(
    (type: ConditionGroupType) => {
      pipe(group, ConditionGroup.setType(type), onChange)
    },
    [group, onChange]
  )

  return (
    <Box>
      <Box mb={4}>
        <Flex alignItems='center' fontSize='small'>
          <Text>When</Text>
          <SelectButton
            size='small'
            mx={2}
            value={group._tag}
            onChange={handleGroupTypeChange}
          >
            <SelectOption value='and' label='all' />
            <SelectOption value='or' label='any' />
          </SelectButton>
          <Text>conditions are met</Text>
        </Flex>
      </Box>

      {group.conditions.map((condition, index) => (
        <ConditionGroupCondition
          key={index}
          condition={condition}
          index={index}
          groupType={group._tag}
          isFirst={index === 0}
          isLast={index === group.conditions.length}
          onRemove={handleRemoveCondition}
          onChange={handleOnChildChange}
        />
      ))}

      <Box pl={CONDITION_GROUP_CONDITION_LABEL_WIDTH}>
        <AddConditionMenu onAddGroup={handleAddGroup} onAddLeaf={handleAddLeaf}>
          <Button colorVariant='secondary' size='small'>
            Add condition
          </Button>
        </AddConditionMenu>
      </Box>
    </Box>
  )
}
