/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useRef, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { useMeasure } from '@woorcs/hooks'
import { BoxModel, forkRefs } from '@woorcs/utils'
import { getEmptyImage } from 'react-dnd-html5-backend'
import * as O from 'fp-ts/Option'
import { constant } from 'fp-ts/function'
import { InspectionFormDocument } from '@woorcs/inspection-form'

import { useEditorContext, usePagesIO } from '../../../state'
import {
  ElementListItemDragItem,
  ELEMENT_LIST_ITEM_DRAG_TYPE
} from '../../ElementList'

import { PAGE_LIST_ITEM_DRAG_TYPE, PageListItemDragItem } from './DragItem'

interface UsedragPageFieldProps {
  page: InspectionFormDocument.InspectionFormPageElement
  index: number
}

const getRect = O.fold(
  constant({
    width: 0,
    height: 0
  }),
  (box: BoxModel) => ({
    width: box.paddingBox.width,
    height: box.paddingBox.height
  })
)

export const useSortablePageListItem = ({
  page,
  index
}: UsedragPageFieldProps) => {
  const { editor } = useEditorContext()
  const pagesIO = usePagesIO()
  const [ref, box] = useMeasure<HTMLDivElement>()
  const currentIndexRef = useRef(index)

  const [{ isDragging }, dragRef, dragPreview] = useDrag({
    type: PAGE_LIST_ITEM_DRAG_TYPE,
    item: () => {
      currentIndexRef.current = index

      return {
        type: PAGE_LIST_ITEM_DRAG_TYPE,
        page,
        rect: getRect(box),
        originalIndex: index,
        currentIndexRef
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    }),
    end: (_, monitor) => {
      const { page: droppedPage, originalIndex } = monitor.getItem()
      const didDrop = monitor.didDrop()

      if (!didDrop) {
        pagesIO.movePage(droppedPage.id, originalIndex)
      }
    }
  })

  const [{ isOver = false, draggingOtherItem = O.none }, dropRef] = useDrop<
    any,
    any,
    {
      isOver: boolean
      draggingOtherItem: O.Option<
        PageListItemDragItem | ElementListItemDragItem
      >
    }
  >({
    accept: [PAGE_LIST_ITEM_DRAG_TYPE, ELEMENT_LIST_ITEM_DRAG_TYPE],
    collect: (monitor) => {
      const item = monitor.getItem<
        PageListItemDragItem | ElementListItemDragItem
      >()
      const draggingOtherItem = O.fromNullable(item)

      return {
        isOver: monitor.canDrop() && monitor.isOver(),
        draggingOtherItem
      }
    },
    drop(item, monitor) {
      const type = monitor.getItemType()

      if (type === ELEMENT_LIST_ITEM_DRAG_TYPE) {
        const elementItem = item as ElementListItemDragItem

        editor.moveElement(elementItem.element.id, [index, 0])()

        return
      }
    },
    hover(item: PageListItemDragItem, monitor) {
      const type = monitor.getItemType()

      if (type !== PAGE_LIST_ITEM_DRAG_TYPE) {
        return
      }

      const { page: dragPage, currentIndexRef: dragIndexRef } =
        item as PageListItemDragItem

      const dragIndex = dragIndexRef.current
      const hoverIndex = index

      if (!ref.current) {
        return
      }

      const hoverBoundingRect = ref.current.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      if (!clientOffset) {
        return
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY / 2) {
        return
      }

      // Dragging upwards
      if (
        dragIndex > hoverIndex &&
        hoverClientY > hoverMiddleY + hoverMiddleY / 2
      ) {
        return
      }

      if (dragPage.id !== page.id && dragIndex !== index) {
        const moveElement = pagesIO.movePage(dragPage.id, index)

        moveElement()

        dragIndexRef.current = index
      }
    }
  })

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: true })
  }, [dragPreview])

  return [
    forkRefs(ref, dropRef, dragRef),
    { isOver, isDragging, draggingOtherItem }
  ] as const
}
