import React, { ComponentProps, forwardRef as reactForwardRef } from 'react'
import TextareaAutosize, {
  TextareaAutosizeProps
} from 'react-textarea-autosize'

import { Input } from '../Input'
import { forwardRef } from '../../../system'

const TextareaInput = reactForwardRef<TextareaAutosizeProps, 'textarea'>(
  (props) => (
    <TextareaAutosize
      css={{
        resize: 'none'
      }}
      {...props}
    />
  )
)

interface Props extends ComponentProps<typeof Input> {
  minRows?: number
  maxRows?: number
}

export const AutosizeTextarea = forwardRef<Props, 'textarea'>((props, ref) => (
  <Input ref={ref} as={TextareaInput} {...props} />
))
