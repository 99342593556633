import React, { forwardRef, useCallback, ReactNode, memo } from 'react'
import { CompositeItem } from 'reakit/Composite'

import { SelectableListItem, ListItemField } from '../../data'
import { PropsOf, system } from '../../../system'

import { useListboxOption, UseListboxOptionProps } from './useListboxOption'
import { ListboxOptionCheck } from './ListboxOptionCheck'
import { ListboxItem } from './useListbox'
import { useListBoxContext } from './ListboxProvider'

export interface ListboxOptionProps
  extends Omit<PropsOf<typeof system.li>, 'value' | 'as'>,
    UseListboxOptionProps {
  children: ReactNode
  label?: string
}

export const ListboxOption = memo(
  forwardRef<HTMLLIElement, ListboxOptionProps>(
    ({ children, label, ...other }, ref) => {
      const { registerItem } = useListBoxContext()
      const { selected, multiple, disabled, getOptionButtonProps } =
        useListboxOption(other)

      const handleRegisterItem = useCallback(
        (item: Omit<ListboxItem, 'label'>) => {
          registerItem({
            ...item,
            label
          })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [label]
      )

      return (
        <CompositeItem
          ref={ref}
          as={SelectableListItem}
          isSelected={selected}
          {...getOptionButtonProps(other)}
          registerItem={handleRegisterItem}
          {...other}
        >
          <ListboxOptionCheck
            checked={selected}
            multiple={multiple}
            disabled={disabled}
          />
          <ListItemField>
            <system.span __css={{ fontSize: 'small', fontWeight: 'medium' }}>
              {children}
            </system.span>
          </ListItemField>
        </CompositeItem>
      )
    }
  )
)
