import React, { ReactNode, useCallback } from 'react'
import { Box, Flex, Input } from '@woorcs/design-system'
import * as t from 'io-ts'
import { Formik, FormikHelpers, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import { useViewer } from '@woorcs/graphql'
import {
  NonEmptyTrimmedString,
  Email,
  getFormikValidator
} from '@woorcs/utils/formik'

import { SubmitButton, FormField } from '@app/components'

import { UpdateViewerProfileDocument } from './__generated__/ProfileForm'

export const ViewerProfile = t.type({
  firstName: NonEmptyTrimmedString,
  lastName: NonEmptyTrimmedString,
  email: t.union([Email, t.undefined])
})

type ViewerProfile = t.TypeOf<typeof ViewerProfile>

const validateForm = getFormikValidator(ViewerProfile)

type ViewerProfileFormProps = {
  displayEmail?: boolean
  actions?: ReactNode
  onSubmitSuccess?(): void
  onSubmitError?(): void
}

export const ViewerProfileForm = ({
  displayEmail = true,
  actions,
  onSubmitSuccess,
  onSubmitError
}: ViewerProfileFormProps) => {
  const { t } = useTranslation()
  const viewer = useViewer()
  const [, updateViewer] = useMutation(UpdateViewerProfileDocument)

  const handleSubmit = useCallback(
    (values: ViewerProfile, actions: FormikHelpers<ViewerProfile>) => {
      if (!viewer) {
        return
      }

      updateViewer({
        input: {
          // email: values.email ?? viewer.email,
          firstName: values.firstName,
          lastName: values.lastName
        }
      })
        .then(() => {
          actions.setSubmitting(false)
          onSubmitSuccess?.()
        })
        .catch(() => {
          onSubmitError?.()
        })
    },
    [onSubmitError, onSubmitSuccess, updateViewer, viewer]
  )

  return (
    <Formik
      validate={validateForm}
      initialValues={viewer as any}
      onSubmit={handleSubmit}
    >
      <Form>
        <Box>
          <FormField name='firstName' label={t('common:firstName')} mb={6}>
            <Input />
          </FormField>
          <FormField name='lastName' label={t('common:lastName')} mb={6}>
            <Input />
          </FormField>
          {displayEmail && (
            <FormField name='email' label={t('common:email')} mb={6}>
              <Input />
            </FormField>
          )}

          <Flex flexDirection='row' justifyContent='flex-end'>
            {actions || <SubmitButton label={t('common:save')} />}
          </Flex>
        </Box>
      </Form>
    </Formik>
  )
}
