import React, { ReactElement, cloneElement } from 'react'
import { DialogDisclosure as BaseDialogDisclosure } from 'reakit/Dialog'

import {
  useDialogSidebarContext,
  UseDialogSidebarProps
} from './useDialogSidebar'

export interface DialogSidebarDisclosureProps extends UseDialogSidebarProps {
  children: ReactElement
}

export const DialogSidebarDisclosure = ({
  children,
  ...props
}: DialogSidebarDisclosureProps) => {
  const { getDisclosureProps } = useDialogSidebarContext()

  return (
    <BaseDialogDisclosure {...getDisclosureProps()} {...props}>
      {(disclosureProps) => cloneElement(children, disclosureProps)}
    </BaseDialogDisclosure>
  )
}
