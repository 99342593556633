import React, { forwardRef, ReactElement, MutableRefObject } from 'react'
import { space } from '@woorcs/utils'
import { useId } from '@woorcs/hooks'

import { PopoverContent, PopoverContentProps } from '../../overlays'
import { Listbox } from '../Listbox'

import { useSelectContext } from './useSelectState'

type SelectMenuProps = {
  children: ReactElement | ReactElement[]
  disclosureRef?: MutableRefObject<HTMLDivElement | null>
} & PopoverContentProps

export const SelectMenu = forwardRef<HTMLDivElement, SelectMenuProps>(
  ({ children, ...props }, ref) => {
    const id = useId()
    const { getListboxProps } = useSelectContext()

    return (
      <PopoverContent
        ref={ref}
        id={id}
        maxHeight={space(60)}
        minWidth={space(45)}
        {...props}
      >
        <Listbox
          tabIndex={-1}
          aria-labelledby={id}
          __css={{ border: 'none', overflowX: 'hidden', overflowY: 'auto' }}
          {...getListboxProps()}
        >
          {children}
        </Listbox>
      </PopoverContent>
    )
  }
)
