import React from 'react'
import { Alert, AlertIcon, AlertMessage, Button } from '@woorcs/design-system'
import { useViewer } from '@woorcs/graphql'

export const AdministratingOrganizationAlert = () => {
  const viewer = useViewer()

  if (!viewer.account.superuser) {
    return null
  }

  return (
    <Alert
      variant='warning'
      position='sticky'
      top={0}
      zIndex={1000}
      borderRadius='none'
    >
      <AlertIcon />
      <AlertMessage>You are viewing this organization as an admin</AlertMessage>
      <Button
        as='a'
        colorVariant='secondary'
        variant='plain'
        size='small'
        href='/admin'
      >
        Back to admin
      </Button>
    </Alert>
  )
}
