import { times } from 'ramda'
import React, {
  memo,
  useRef,
  useState,
  FocusEvent,
  KeyboardEvent,
  ChangeEvent
} from 'react'
import { space, hasFocusWithin } from '@woorcs/utils'
import { FlexProps } from 'styled-system'

import { Flex } from '../../layout'
import { Input, InputProps } from '../Input'
import { forwardRef, splitSystemProps, css } from '../../../system'

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40
}

const StyledInput = forwardRef<InputProps, 'input'>((props, ref) => (
  <Input
    ref={ref}
    css={css({
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
      width: space(12),
      height: space(16),
      padding: 0,
      mr: 4,
      fontSize: 'medium',
      '& > input': {
        textAlign: 'center',
        height: '100%'
      },
      ':last-child': {
        margin: 0
      }
    })}
    variant='outlined'
    maxlength='1'
    {...props}
  />
))

interface VerificationCodeInputProps extends FlexProps {
  id?: string
  name?: string
  invalid?: boolean
  disabled?: boolean
  type?: string
  length?: number
  onFocus(e: FocusEvent<HTMLInputElement>): void
  onBlur(e: FocusEvent<HTMLInputElement>): void
  onChange(value: string): void
}

export const VerificationCodeInput = memo<VerificationCodeInputProps>(
  ({
    type = 'number',
    length = 4,
    id,
    name,
    invalid,
    disabled,
    onChange,
    onFocus,
    onBlur,
    ...other
  }: VerificationCodeInputProps) => {
    const [styleProps] = splitSystemProps(other)
    const [values, setValue] = useState(Array(length).fill(''))
    const container = useRef<HTMLDivElement | null>(null)
    const refs = useRef<Array<HTMLInputElement>>([])

    const triggerChange = (values: any[]) => {
      const value = values.join('')

      if (onChange) {
        onChange(value)
      }
    }

    // eslint-disable-next-line max-statements
    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      const index = refs.current.indexOf(e.target as HTMLInputElement)
      const prevIndex = index - 1
      const nextIndex = index + 1
      const prev = refs.current[prevIndex]
      const next = refs.current[nextIndex]

      switch (e.keyCode) {
        case KEY_CODE.backspace: {
          e.preventDefault()

          const vals = [...values]

          if (vals[index]) {
            vals[index] = ''

            setValue(vals)
            triggerChange(vals)

            return
          }

          if (prev) {
            vals[prevIndex] = ''
            prev.focus()

            setValue(vals)
            triggerChange(vals)
          }

          break
        }

        case KEY_CODE.left: {
          e.preventDefault()

          if (prev) {
            prev.focus()
          }

          break
        }

        case KEY_CODE.right: {
          e.preventDefault()

          if (next) {
            next.focus()
          }

          break
        }

        case KEY_CODE.up:
        case KEY_CODE.down:
          e.preventDefault()
          break
      }
    }

    // eslint-disable-next-line max-statements
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const index = refs.current.indexOf(e.target)

      if (e.target.value === '') {
        return
      }

      let next

      const inputValue = e.target.value
      const isPaste = inputValue.length > 1
      const vals = [...values]

      if (isPaste) {
        let nextIndex = inputValue.length + index - 1

        if (nextIndex >= length) {
          nextIndex = length - 1
        }

        next = refs.current[nextIndex]

        const split = inputValue.split('')

        split.forEach((item, i) => {
          const cursor = index + i

          if (cursor < length) {
            vals[cursor] = item
          }
        })

        setValue(vals)
      }

      if (!isPaste) {
        next = refs.current[index + 1]
        vals[index] = inputValue

        setValue(vals)
      }

      if (next) {
        next.focus()
        next.select()
      }

      triggerChange(vals)
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      if (!container.current) {
        return
      }

      e.persist()

      window.requestAnimationFrame(() => {
        if (!container.current) {
          return
        }

        if (!hasFocusWithin(container.current)) {
          onBlur(e)
        }
      })
    }

    const inputs = times(
      (idx) => (
        <StyledInput
          ref={(node: HTMLInputElement) => {
            if (node) {
              refs.current[idx] = node
            }
          }}
          key={`verification-code-input-${idx}`}
          id={idx === 0 ? id : undefined}
          type={type === 'number' ? 'tel' : type}
          pattern={type === 'number' ? '[0-9]' : undefined}
          name={name}
          value={values[idx]}
          invalid={invalid}
          disabled={disabled}
          maxLength={1}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
        />
      ),
      length
    )

    return (
      <Flex ref={container} {...(styleProps as any)}>
        {inputs}
      </Flex>
    )
  }
)
