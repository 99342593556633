import React, {
  ChangeEvent,
  ReactElement,
  useState,
  useCallback,
  ReactNode,
  memo
} from 'react'
import {
  Box,
  Input,
  Menu,
  MenuButton,
  MenuContent,
  MenuGroup,
  MenuItem,
  MenuSeparator,
  MenuItemButton
} from '@woorcs/design-system'
import { constNull, pipe } from 'fp-ts/function'
import * as NEA from 'fp-ts/NonEmptyArray'
import * as A from 'fp-ts/Array'
import * as RA from 'fp-ts/ReadonlyArray'
import * as O from 'fp-ts/Option'
import { FixedSizeList } from 'react-window'
import Highlighter from 'react-highlight-words'
import { palette } from '@woorcs/design-tokens'
import { Locale } from '@woorcs/form'

type SelectLanguageMenuItemProps = {
  filter: string
  locale: Locale.Locale
  onSelect(language: Locale.Locale): void
}

const SelectLanguageMenuItem = memo(
  ({ locale, filter, onSelect, ...other }: SelectLanguageMenuItemProps) => {
    const handleClick = useCallback(() => {
      onSelect(locale)
    }, [locale, onSelect])

    /**
     * FIXME: Use MenuItem instead of MenuItemButton
     */
    return (
      <MenuItemButton id={locale} onClick={handleClick} {...other}>
        <Highlighter
          highlightStyle={{
            display: 'inline-block',
            // TODO: use theme
            backgroundColor: palette.primary[50],
            color: palette.primary[500],
            borderRadius: 2
          }}
          searchWords={[filter]}
          textToHighlight={Locale.getLocaleLabel(locale)}
        />
      </MenuItemButton>
    )
  }
)

type SelectLanguageMenuListProps = {
  filter: string
  languages: Locale.Locale[]
  onSelectLanguage(locale: Locale.Locale): void
}

const SelectLanguageMenuList = ({
  filter,
  languages,
  onSelectLanguage
}: SelectLanguageMenuListProps) => {
  const RenderItem = useCallback(
    ({ index, ...other }: { index: number }) => {
      return pipe(
        languages,
        A.lookup(index),
        O.fold(constNull, (locale) => (
          <SelectLanguageMenuItem
            key={locale}
            locale={locale}
            filter={filter}
            onSelect={onSelectLanguage}
            {...other}
          />
        ))
      )
    },
    [filter, languages, onSelectLanguage]
  )

  return (
    <FixedSizeList
      height={180}
      itemCount={languages.length}
      itemSize={35}
      width={220}
    >
      {RenderItem}
    </FixedSizeList>
  )
}

type SelectLanguageMenuContentProps = {
  localeBlacklist: Locale.Locale[]
  onSelectLanguage(locale: Locale.Locale): void
  onHideMenu(): void
}

const SelectLanguageMenuContent = ({
  localeBlacklist,
  onSelectLanguage,
  onHideMenu
}: SelectLanguageMenuContentProps) => {
  const [filter, setFilter] = useState('')

  const filteredLanguages: O.Option<Locale.Locale[]> = pipe(
    Locale.getLocaleList(),
    RA.filter((language) => !localeBlacklist.includes(language)),
    RA.toArray,
    NEA.fromArray,
    O.chain(
      NEA.filter((locale) =>
        Locale.getLocaleLabel(locale)
          .toLowerCase()
          .includes(filter.toLowerCase())
      )
    )
  )

  const handleFilterChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value)
  }, [])

  const handleSelectLanguage = useCallback(
    (locale: Locale.Locale) => {
      onSelectLanguage(locale)
      onHideMenu()
    },
    [onHideMenu, onSelectLanguage]
  )

  return (
    <MenuContent>
      <Box>
        <Input
          autoComplete='off'
          size='small'
          placeholder='Search...'
          value={filter}
          onChange={handleFilterChange}
        />
      </Box>
      <MenuSeparator />
      <MenuGroup>
        {pipe(
          filteredLanguages,
          O.fold(
            (): ReactNode => <MenuItem>No match</MenuItem>,
            (languages) => (
              <SelectLanguageMenuList
                filter={filter}
                languages={languages}
                onSelectLanguage={handleSelectLanguage}
              />
            )
          )
        )}
      </MenuGroup>
    </MenuContent>
  )
}

type SelectLanguageMenuProps = {
  children: ReactElement
  /**
   * blacklist locales from the menu
   */
  localeBlacklist?: Locale.Locale[]
  onSelectLanguage: (locale: Locale.Locale) => void
}

export const SelectLanguageMenu = ({
  children,
  localeBlacklist = [],
  onSelectLanguage
}: SelectLanguageMenuProps) => {
  return (
    <Menu>
      {({ state }) => (
        <>
          <MenuButton>{children}</MenuButton>
          {state.visible && (
            <SelectLanguageMenuContent
              localeBlacklist={localeBlacklist}
              onSelectLanguage={onSelectLanguage}
              onHideMenu={state.hide}
            />
          )}
        </>
      )}
    </Menu>
  )
}
