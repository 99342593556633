import { useCallback, useEffect, useRef } from 'react'
import { usePopoverState, PopoverInitialState } from 'reakit/Popover'

const TIMEOUT = 100

interface UseElementActionMenuPopoverStateProps extends PopoverInitialState {
  disabled?: boolean
}

export function useElementActionMenuPopoverState({
  disabled,
  ...initialState
}: UseElementActionMenuPopoverStateProps) {
  const showTimeout = useRef<number | null>(null)
  const hideTimeout = useRef<number | null>(null)
  const popover = usePopoverState(initialState)

  const clearTimeouts = useCallback(() => {
    if (showTimeout.current !== null) {
      window.clearTimeout(showTimeout.current)
    }

    if (hideTimeout.current !== null) {
      window.clearTimeout(hideTimeout.current)
    }
  }, [])

  const show = useCallback(() => {
    clearTimeouts()

    showTimeout.current = window.setTimeout(() => {
      popover.show()
    }, TIMEOUT)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearTimeouts, popover.show])

  const hide = useCallback(() => {
    clearTimeouts()

    hideTimeout.current = window.setTimeout(() => {
      popover.hide()
    }, TIMEOUT)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearTimeouts, popover.hide])

  useEffect(() => {
    if (disabled && popover.visible) {
      popover.hide()
    }
  }, [disabled, popover])

  useEffect(
    () => () => {
      clearTimeouts()
    },
    [clearTimeouts]
  )

  return {
    ...popover,
    show,
    hide
  }
}
