import React, { ReactElement } from 'react'

import { Button, SecondaryButton } from '../../buttons'
import { Body } from '../../typography'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogDisclosure,
  AlertDialogTitle
} from '../AlertDialog'

type ConfirmAlertDialogProps = {
  title: string
  body: string
  confirmLabel?: string
  cancelLabel?: string
  children: ReactElement
  destructive?: boolean
  onConfirm(): void
}

export const ConfirmAlertDialog = ({
  title,
  body,
  confirmLabel = 'Ok',
  cancelLabel = 'Cancel',
  children,
  destructive = false,
  onConfirm
}: ConfirmAlertDialogProps) => (
  <AlertDialog>
    {({ state }) => (
      <>
        <AlertDialogDisclosure>{children}</AlertDialogDisclosure>
        <AlertDialogContent aria-label={title}>
          <AlertDialogHeader mb={6}>
            <AlertDialogTitle>{title}</AlertDialogTitle>
          </AlertDialogHeader>

          <AlertDialogBody>
            <Body>{body}</Body>
          </AlertDialogBody>

          <AlertDialogFooter>
            <SecondaryButton mr={2} onClick={state.hide}>
              {cancelLabel}
            </SecondaryButton>
            <Button
              colorVariant={destructive ? 'danger' : 'primary'}
              onClick={() => {
                onConfirm()
                state.hide()
              }}
            >
              {confirmLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </>
    )}
  </AlertDialog>
)
