import React, { ChangeEvent, useRef, useState } from 'react'
import {
  Box,
  H4,
  FormField,
  Input,
  Subtitle,
  Avatar,
  useSnackbar
} from '@woorcs/design-system'
import { useMutation } from 'urql'
import {
  RequestUploadUrlPayload,
  RequestUploadUrlSuccess
} from '@woorcs/graphql/schema'
import { pipe } from 'fp-ts/function'
import * as O from 'fp-ts/Option'
import * as TE from 'fp-ts/TaskEither'
import * as E from 'fp-ts/Either'
import * as boolean from 'fp-ts/boolean'
import { useOrganization } from '@woorcs/graphql'

import {
  RequestLogoUploadUrlMutationDocument,
  UpdateLogoMutationDocument
} from './__generated__/OrganizationPage'

const requestUploadWasSuccessful = (
  response: RequestUploadUrlPayload
): response is RequestUploadUrlSuccess => {
  return response.__typename === 'RequestUploadUrlSuccess'
}

const uploadFile = (url: string) => (file: File) =>
  pipe(
    TE.tryCatch(
      () => {
        return fetch(url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      },
      (reason) => {
        return new Error(reason as string)
      }
    )
  )

const extractFile = (files: FileList | null) =>
  pipe(
    O.fromNullable(files),
    O.map((files) => O.fromNullable(files[0])),
    O.flatten
  )

export const OrganizationGeneralSettingsPage = () => {
  const currentOrganization = useOrganization()
  const [, requestUrl] = useMutation(RequestLogoUploadUrlMutationDocument)
  const [, updateLogo] = useMutation(UpdateLogoMutationDocument)
  const [logo, setLogo] = useState<string | null>(currentOrganization.logo)
  const { showSnackbar } = useSnackbar()
  const hiddenFileUpload = useRef<HTMLInputElement>(null)

  const fileSucessfullyUploaded = (uploadResponse: Response) =>
    uploadResponse.status === 200

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const upload = pipe(
      e.target.files,
      extractFile,
      TE.fromOption(() => new Error('no file')),
      TE.chain((file) =>
        pipe(
          TE.fromTask(() =>
            requestUrl({ extension: file.name.split('.').pop() || '' })
          ),
          TE.chainW((result) =>
            pipe(
              O.fromNullable(result.data),
              O.map((data) => data.requestLogoUploadUrl),
              O.filter(requestUploadWasSuccessful),
              TE.fromOption(() => new Error('Storage not defined'))
            )
          ),
          TE.chainW((response) =>
            pipe(
              file,
              uploadFile(response.url),
              TE.filterOrElse(
                fileSucessfullyUploaded,
                () => new Error('failed to upload')
              ),
              TE.chain(() =>
                TE.tryCatch(
                  () => updateLogo({ filename: response.filename }),
                  () => new Error('failed to update logo')
                )
              )
            )
          )
        )
      ),
      TE.chainW((updateLogoResponse) =>
        pipe(
          O.fromNullable(updateLogoResponse.data),
          O.map((data) => O.fromNullable(data.updateLogo.organization.logo)),
          O.flatten,
          TE.fromOption(() => new Error('Logo could not update'))
        )
      )
    )

    const result = await upload()
    pipe(
      result,
      E.fold(
        () =>
          showSnackbar({ title: 'Could not upload logo.', variant: 'danger' }),
        (file) => {
          setLogo(file)
          showSnackbar({
            title: 'Logo updated.',
            variant: 'success'
          })
        }
      )
    )
  }

  const handleClick = () => {
    if (hiddenFileUpload.current) {
      hiddenFileUpload.current.click()
    }
  }

  return (
    <Box>
      <Box
        justifyContent='space-between'
        alignItems='center'
        mb={8}
        pb={4}
        borderBottom='base'
      >
        <H4>Organization settings</H4>
      </Box>

      <Box mb={12}>
        <Subtitle mb={4}>Logo</Subtitle>
        <Avatar
          css={{
            cursor: 'pointer'
          }}
          size='xlarge'
          name={'test'}
          bg='secondary.300'
          color='green.700'
          circle={false}
          src={O.fromNullable(logo)}
          onClick={handleClick}
        />
        <input
          ref={hiddenFileUpload}
          type='file'
          accept='image/*'
          style={{ display: 'none' }}
          onChange={handleChange}
        />
      </Box>

      <Subtitle mb={4}>General</Subtitle>

      {/*<FormField label='Organization name'>
        <Input value={currentOrganization.name} />
        </FormField>*/}
    </Box>
  )
}
