import React from 'react'

import { system, forwardRef, PropsOf } from '../../../system'

import { InputActiveBorder } from './InputActiveBorder'
import { InputSize, InputVariant } from './variants'
import { InputState } from './InputGroup'

interface InputElementProps
  extends Omit<PropsOf<typeof system.input>, 'size'>,
    InputState {
  variant: InputVariant
  size: InputSize
}

export const InputElement = forwardRef<InputElementProps, typeof system.input>(
  ({ size, ...props }, ref) => {
    const isUnderlined = props.variant === 'underlined'

    return (
      <>
        <system.input
          ref={ref}
          fontWeight='medium'
          css={{
            appearance: 'none',
            width: '100%',
            padding: 0,
            fontSize: 'inherit',
            color: 'inherit',
            backgroundColor: 'transparent',
            outline: 'none',
            border: 'none',
            cursor: 'inherit',
            ':focus, :active': {
              outline: 'none'
            },
            '::placeholder': {
              color: 'grey.medium'
            },
            '::-ms-clear': {
              display: 'none'
            }
          }}
          {...props}
        />
        {isUnderlined && <InputActiveBorder focused={props.focused} />}
      </>
    )
  }
)
