import React, { ReactElement, cloneElement } from 'react'
import { DialogDisclosure as BaseDialogDisclosure } from 'reakit/Dialog'

import { useModalDisclosure, UseModalDisclosureProps } from './useModal'

export interface ModalDisclosureProps extends UseModalDisclosureProps {
  children: ReactElement
}

export const ModalDisclosure = ({
  children,
  ...props
}: ModalDisclosureProps) => {
  const state = useModalDisclosure(props)

  return (
    <BaseDialogDisclosure {...state}>
      {(disclosureProps) => cloneElement(children, disclosureProps)}
    </BaseDialogDisclosure>
  )
}
