import React, { ReactNode } from 'react'
import { isFunction } from '@woorcs/utils'

import {
  useModal,
  ModalProvider,
  UseModalProps,
  ModalContext
} from './useModal'

export interface ModalProps extends UseModalProps {
  children: ReactNode | ((context: ModalContext) => ReactNode)
}

export const Modal = ({ children, ...props }: ModalProps) => {
  const context = useModal(props)

  return (
    <ModalProvider value={context}>
      {isFunction(children) ? children(context) : children}
    </ModalProvider>
  )
}
