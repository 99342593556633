import React, { ReactElement, useState } from 'react'
import {
  Popover,
  PopoverContent,
  PopoverDisclosure
} from '@woorcs/design-system'
import { ResponseSet } from '@woorcs/form'

import { ResponseSetOptionList } from './List'

type SelectResponseSetOptionMenuProps = {
  responseSet: ResponseSet.ResponseSet
  children: ReactElement
  onSelect(option: ResponseSet.ResponseSetOption): void
}

export const SelectResponseSetOptionMenu = ({
  responseSet,
  children,
  onSelect
}: SelectResponseSetOptionMenuProps) => {
  const [visible, setVisible] = useState(false)

  return (
    <Popover
      placement='bottom-start'
      visible={visible}
      onVisiblityChange={setVisible}
    >
      <PopoverDisclosure>{children}</PopoverDisclosure>
      <PopoverContent p={4} width={320}>
        <ResponseSetOptionList
          responseSet={responseSet}
          onOptionClick={(option) => {
            setVisible(false)
            onSelect(option)
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
