import React from 'react'

import { PropsOf, system, forwardRef } from '../../../system'
import { Stack } from '../../layout'

import { NAV_SIDEBAR_WIDTH } from './NavSidebar'

type NavSidebarMenuProps = PropsOf<typeof system.div>

export const NavSidebarMenu = forwardRef<
  NavSidebarMenuProps,
  typeof system.nav
>((props, ref) => (
  <Stack
    ref={ref}
    as='nav'
    css={{
      willChange: 'transform'
    }}
    width={NAV_SIDEBAR_WIDTH}
    aria-label='main'
    spacing={3}
    px={4}
    {...props}
  />
))
