import React, { ReactElement, useCallback } from 'react'
import {
  Box,
  Button,
  Flex,
  ListItemActionField,
  ListItemField,
  Popover,
  PopoverContent,
  PopoverDisclosure,
  SelectableListItem,
  Text
} from '@woorcs/design-system'
import * as R from 'fp-ts/Record'
import * as A from 'fp-ts/Array'
import { space } from '@woorcs/utils'
import { pipe } from 'fp-ts/function'
import { Locale } from '@woorcs/form'

import { useI18nConfig } from '../../state'
import { CountryFlag } from '../CountryFlag'
import { ManageLanguagesModal } from '../ManageLanguagesModal'

type SwitchLanguageMenuItemProps = {
  locale: Locale.Locale
  isSelected: boolean
  onSelect(locale: Locale.Locale): void
}

const SwitchLanguageMenuItem = ({
  locale,
  isSelected,
  onSelect
}: SwitchLanguageMenuItemProps) => {
  const handleClick = useCallback(() => {
    onSelect(locale)
  }, [onSelect, locale])

  return (
    <SelectableListItem
      css={{ cursor: 'pointer' }}
      isSelected={isSelected}
      onClick={handleClick}
    >
      <ListItemActionField mr={4}>
        <CountryFlag locale={locale} />
      </ListItemActionField>
      <ListItemField py={1}>
        <Box>
          <Text as='p' fontWeight='bold'>
            {Locale.getLocaleLabel(locale)}
          </Text>
          {/* <ProgressBar value={0.1} />
          <Text>10%</Text> */}
        </Box>
      </ListItemField>
    </SelectableListItem>
  )
}

const SwitchLanguageMenuFooter = () => (
  <Flex justifyContent='center' pt={2}>
    <ManageLanguagesModal>
      <Button colorVariant='secondary' fullWidth>
        Manage languages
      </Button>
    </ManageLanguagesModal>
  </Flex>
)

type SwitchLanguageMenuProps = {
  selectedLanguage: Locale.Locale
  onSelectLanguage(locale: Locale.Locale): void
  children: ReactElement
}

export const SwitchLanguageMenu = ({
  selectedLanguage,
  onSelectLanguage,
  children
}: SwitchLanguageMenuProps) => {
  const [{ languages }] = useI18nConfig()
  const renderLanguageList = (onSelect: () => void) =>
    pipe(
      languages,
      R.collect((locale) => locale),
      A.map((locale) => (
        <SwitchLanguageMenuItem
          key={locale}
          locale={locale}
          isSelected={locale === selectedLanguage}
          onSelect={(id) => {
            onSelect()
            onSelectLanguage(id)
          }}
        />
      ))
    )

  return (
    <Popover placement='bottom'>
      {({ hide }) => (
        <>
          <PopoverDisclosure>{children}</PopoverDisclosure>
          <PopoverContent aria-label='Switch language' width={480} p={5}>
            <Box css={{ overflowY: 'auto' }} maxHeight={space(80)}>
              {renderLanguageList(hide)}
            </Box>
            <SwitchLanguageMenuFooter />
          </PopoverContent>
        </>
      )}
    </Popover>
  )
}
