import {
  usePopoverState,
  PopoverStateReturn,
  PopoverInitialState,
  PopoverDisclosureProps,
  PopoverProps
} from 'reakit/Popover'
import { createContext } from '@woorcs/utils'
import { ReactText, MutableRefObject } from 'react'
import { useControllableState } from '@woorcs/hooks'

type PopoverOptions = Pick<
  PopoverProps,
  'hideOnEsc' | 'hideOnClickOutside' | 'preventBodyScroll'
>

export interface PopoverContext extends PopoverOptions {
  state: PopoverStateReturn
  initialFocusRef?: MutableRefObject<HTMLElement | null>
  finalFocusRef?: MutableRefObject<HTMLElement | null>
  disclosureRef?: MutableRefObject<HTMLElement | null>
  autoFocusOnHide: boolean
  autoFocusOnShow: boolean
}

export const [PopoverProvider, usePopoverContext] =
  createContext<PopoverContext>()

export interface UsePopoverProps
  extends Omit<
      PopoverInitialState,
      | 'unstable_fixed'
      | 'unstable_flip'
      | 'unstable_offset'
      | 'unstable_preventOverflow'
      | 'unstable_autoFocusOnHide'
      | 'unstable_autoFocusOnShow'
      | 'unstable_disclosureRef'
    >,
    PopoverOptions {
  /**
   * Controlls the visiblity state of the popover
   */
  visible?: boolean
  /**
   * Sets the default visibility state
   */
  defaultVisible?: boolean
  /**
   * Offset between the reference and the popover: [main axis, alt axis]. Should not be combined with gutter.
   */
  offset?: [ReactText, ReactText]

  initialFocusRef?: MutableRefObject<HTMLElement | null>
  finalFocusRef?: MutableRefObject<HTMLElement | null>
  disclosureRef?: MutableRefObject<HTMLElement | null>

  autoFocusOnHide?: boolean
  autoFocusOnShow?: boolean

  /**
   * Called when the visiblity state changes
   */
  onVisiblityChange?(visible: boolean): void
}

export const usePopover = ({
  offset,
  visible: propVisible,
  defaultVisible,
  hideOnEsc,
  hideOnClickOutside,
  preventBodyScroll,
  onVisiblityChange,
  initialFocusRef,
  finalFocusRef,
  disclosureRef,
  autoFocusOnHide = true,
  autoFocusOnShow = true,
  ...props
}: UsePopoverProps): PopoverContext => {
  const state = usePopoverState({
    ...props,
    animated: 0.15 * 1000,
    visible: defaultVisible,
    unstable_offset: offset
  })

  const visible = useControllableState(
    propVisible,
    state.visible,
    state.setVisible,
    onVisiblityChange
  )

  return {
    hideOnEsc,
    hideOnClickOutside,
    preventBodyScroll,
    initialFocusRef,
    finalFocusRef,
    disclosureRef,
    autoFocusOnHide,
    autoFocusOnShow,
    state: {
      ...state,
      visible
    }
  }
}

export type UsePopoverDisclosure = Pick<
  PopoverDisclosureProps,
  'disabled' | 'focusable'
>

export const usePopoverDisclosure = (props: UsePopoverDisclosure) => {
  const { state } = usePopoverContext()
  const { visible, baseId, toggle, unstable_referenceRef } = state

  return {
    ...props,
    visible,
    baseId,
    toggle,
    unstable_referenceRef
  }
}

export const usePopoverContent = () => {
  const {
    state,
    initialFocusRef,
    finalFocusRef,
    disclosureRef,
    autoFocusOnShow,
    autoFocusOnHide,
    ...other
  } = usePopoverContext()

  return {
    unstable_initialFocusRef: initialFocusRef,
    unstable_finalFocusRef: finalFocusRef,
    unstable_autoFocusOnShow: autoFocusOnShow,
    unstable_autoFocusOnHide: autoFocusOnHide,
    ...state,
    ...other,
    unstable_disclosureRef: disclosureRef ?? state.unstable_disclosureRef
  }
}
