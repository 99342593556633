import React from 'react'

import { Box, Flex } from '../../layout'
import { H3 } from '../../typography'
import { PropsOf } from '../../../system'

type ModalBodyProps = PropsOf<typeof Box>

export const ModalBody = (props: ModalBodyProps) => <Box px={8} {...props} />

type ModalTitleProps = PropsOf<typeof H3>

type ModalHeaderProps = PropsOf<typeof Box>

export const ModalHeader = (props: ModalHeaderProps) => (
  <Box px={8} pt={8} mb={6} {...props} />
)

export const ModalTitle = (props: ModalTitleProps) => <H3 {...props} />

type ModalFooterProps = PropsOf<typeof Flex>

export const ModalFooter = (props: ModalFooterProps) => (
  <Flex justifyContent='flex-end' p={8} {...props} />
)
