/* eslint-disable no-console */
/* eslint-disable max-statements */
import { ADTType, makeADT, ofType } from '@morphic-ts/adt'
import undoable, {
  ActionCreators,
  ActionTypes,
  StateWithHistory
} from 'redux-undo'
import * as IO from 'fp-ts/IO'
import * as R from 'fp-ts/Reader'
import { pipe } from 'fp-ts/function'
import { Reducer, createStore, applyMiddleware, Store } from 'redux'
import { createLogger } from 'redux-logger'
import * as Path from '@woorcs/types/Path'
import * as ET from '@woorcs/types/ElementTree'

// -------------------------------------------------------------------------------------
// actions
// -------------------------------------------------------------------------------------

export type ResetAction = {
  type: 'Reset'
  payload: {
    state: EditorState
  }
}

export type AddElementAction = {
  type: 'AddElement'
  payload: {
    at: Path.Path
    element: ET.Element
  }
}

export type MoveElementAction = {
  type: 'MoveElement'
  payload: {
    from: Path.Path
    to: Path.Path
  }
}

export type RemoveElementAction = {
  type: 'RemoveElement'
  payload: {
    path: Path.Path
  }
}

export type UpdateElementAction = {
  type: 'UpdateElement'
  payload: {
    path: Path.Path
    element: ET.Element
  }
}

export type UpdateRootAction = {
  type: 'UpdateRoot'
  payload: {
    element: ET.Element
  }
}

export const EditorAction = makeADT('type')({
  Reset: ofType<ResetAction>(),
  AddElement: ofType<AddElementAction>(),
  MoveElement: ofType<MoveElementAction>(),
  RemoveElement: ofType<RemoveElementAction>(),
  UpdateElement: ofType<UpdateElementAction>(),
  UpdateRoot: ofType<UpdateRootAction>()
})

export type EditorAction = ADTType<typeof EditorAction>

export const undo = ActionCreators.undo

export const redo = ActionCreators.redo

export type HistoryActionType = ActionTypes

// -------------------------------------------------------------------------------------
// state model
// -------------------------------------------------------------------------------------

export type EditorState = ET.ElementTree

export type EditorStateWithHistory = StateWithHistory<EditorState>

const initialState: EditorState = {
  type: '',
  children: []
}

// -------------------------------------------------------------------------------------
// selectors
// -------------------------------------------------------------------------------------

export const presentStateSelector: R.Reader<
  EditorStateWithHistory,
  EditorState
> = (state) => state.present

export const canUndoSelector: R.Reader<EditorStateWithHistory, boolean> = ({
  past
}) => past.length > 0

export const canRedoSelector: R.Reader<EditorStateWithHistory, boolean> = ({
  future
}) => future.length > 0

// -------------------------------------------------------------------------------------
// reducers
// -------------------------------------------------------------------------------------

export const editorReducer: Reducer<EditorState, EditorAction> = (
  state = initialState,
  action
): EditorState => {
  switch (action.type) {
    case 'Reset': {
      return action.payload.state
    }

    case 'AddElement': {
      const { element, at } = action.payload

      return pipe(
        state,
        // TODO: insertElementAt shouldn't return an Option
        ET.insertElementAt(element, at)
      )
    }

    case 'RemoveElement': {
      const { path } = action.payload

      return pipe(state, ET.removeElementAt(path))
    }

    case 'MoveElement': {
      const { from, to } = action.payload

      if (Path.Path.equals(from, to)) {
        return state
      }

      return pipe(state, ET.moveElement(from, to))
    }

    case 'UpdateElement': {
      const { element, path } = action.payload

      return pipe(state, ET.replaceElementAt(element, path))
    }

    case 'UpdateRoot': {
      const { element } = action.payload

      return element
    }

    // default: {
    //   return absurd(action)
    // }
  }

  return state
}

// -------------------------------------------------------------------------------------
// store
// -------------------------------------------------------------------------------------

export interface CreateEditorStoreOptions {
  debug?: boolean
  historyLimit?: number
}

export const editorStore = (
  initialValue: ET.ElementTree,
  { debug = false, historyLimit = 15 }: CreateEditorStoreOptions
) => {
  const middlewares = debug
    ? [createLogger({ stateTransformer: (state) => state.present })]
    : []
  const middleware = applyMiddleware(...middlewares)

  if (debug) {
    console.log('initialized editor with state: ', initialState)
  }

  const store = createStore<
    EditorStateWithHistory,
    EditorAction,
    unknown,
    unknown
  >(
    undoable(editorReducer, {
      limit: historyLimit,
      /**
       * Group updates made on the same element
       */
      groupBy: (action) => {
        if (action.type !== 'UpdateElement') {
          return null
        }

        return `UpdateElement-${action.payload}-${Path.Show.show(
          action.payload.path
        )}`
      }
    }),
    {
      past: [],
      present: initialValue,
      future: []
    },
    middleware
  )

  return {
    initialValue,
    ...store
  }
}

export type EditorStore = Store<EditorStateWithHistory, EditorAction> & {
  initialValue: ET.ElementTree
}

// -------------------------------------------------------------------------------------
// history
// -------------------------------------------------------------------------------------

export interface EditorStateHistoryIO {
  canRedo(): IO.IO<boolean>
  canUndo(): IO.IO<boolean>
  undo(): IO.IO<void>
  redo(): IO.IO<void>
  reset(): IO.IO<void>
}

export const getHistoryIO = (
  store: Store,
  initialValue: ET.ElementTree
): EditorStateHistoryIO => {
  return {
    canUndo: () => () => canUndoSelector(store.getState()),
    canRedo: () => () => canRedoSelector(store.getState()),
    undo: () => () => store.dispatch(undo()),
    redo: () => () => store.dispatch(redo()),
    reset: () => () =>
      store.dispatch(
        EditorAction.of.Reset({
          payload: {
            state: initialValue
          }
        })
      )
  }
}

// -------------------------------------------------------------------------------------
// io
// -------------------------------------------------------------------------------------

export interface EditorStateSelectorIO {
  getValue<T extends ET.Element = ET.ElementTree>(): IO.IO<T>
}

export interface EditorStateIO
  extends EditorStateSelectorIO,
    EditorStateHistoryIO {
  addElement(element: ET.Element, at: Path.Path): IO.IO<void>
  updateRoot(element: ET.Element): IO.IO<void>
  updateElement(element: ET.Element, path: Path.Path): IO.IO<void>
  moveElement(from: Path.Path, to: Path.Path): IO.IO<void>
  removeElement(path: Path.Path): IO.IO<void>
}

export const getStateIO = (
  store: EditorStore,
  history: EditorStateHistoryIO
): EditorStateIO => {
  return {
    ...history,
    addElement: (element, at) => () =>
      store.dispatch(
        EditorAction.of.AddElement({
          payload: {
            element,
            at
          }
        })
      ),
    updateRoot: (element) => () =>
      store.dispatch(
        EditorAction.of.UpdateRoot({
          payload: {
            element
          }
        })
      ),
    updateElement: (element, path) => () =>
      store.dispatch(
        EditorAction.of.UpdateElement({
          payload: {
            element,
            path
          }
        })
      ),
    moveElement: (from, to) => () =>
      store.dispatch(
        EditorAction.of.MoveElement({
          payload: {
            from,
            to
          }
        })
      ),
    removeElement: (path: Path.Path) => () =>
      store.dispatch(
        EditorAction.of.RemoveElement({
          payload: {
            path
          }
        })
      ),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getValue: () => () => pipe(store.getState(), presentStateSelector) as any
  }
}
