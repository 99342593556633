// browse available icons at: https://react-icons.github.io/react-icons/icons?name=cg

import React from 'react'
import {
  CgCheck,
  CgMoreVerticalAlt,
  CgClose,
  CgMathPlus,
  CgChevronDown,
  CgChevronUp,
  CgChevronLeft,
  CgChevronRight,
  CgTrash,
  CgFormatColor,
  CgUndo,
  CgRedo,
  CgCopy,
  CgCode,
  CgExternal,
  CgMail,
  CgMoreAlt,
  CgSearch,
  CgFileDocument,
  CgInfo,
  CgDanger,
  CgFormatUnderline,
  CgSoftwareDownload,
  CgArrowLongRight,
  CgAttribution,
  CgUser,
  CgUserList,
  CgCalendarToday,
  CgBell,
  CgFormatUppercase,
  CgSelect,
  CgTag,
  CgPen,
  CgPin,
  CgTime,
  CgImage,
  CgLayoutList,
  CgHashtag,
  CgInbox,
  CgPerformance,
  CgSmileSad,
  CgSmileNeutral
} from 'react-icons/cg'
import styled from '@emotion/styled'

import { Icon, IconProps } from './Icon'

const createIcon = (Svg: React.ComponentType) => (props: IconProps) =>
  (
    <Icon {...props}>
      <Svg />
    </Icon>
  )

export const ArrowLongRight = createIcon(CgArrowLongRight)
export const BellIcon = createIcon(CgBell)
export const CalendarIcon = createIcon(CgCalendarToday)
export const ListIcon = createIcon(CgLayoutList)
export const CheckIcon = createIcon(CgCheck)
export const ChevronDownIcon = createIcon(CgChevronDown)
export const ChevronLeftIcon = createIcon(CgChevronLeft)
export const ChevronRightIcon = createIcon(CgChevronRight)
export const ChevronUpIcon = createIcon(CgChevronUp)
export const CloseIcon = createIcon(CgClose)
export const CodeIcon = createIcon(CgCode)
export const CopyIcon = createIcon(CgCopy)
export const DangerIcon = createIcon(CgDanger)
export const DocumentIcon = createIcon(CgFileDocument)
export const DownloadIcon = createIcon(CgSoftwareDownload)
export const ExternalIcon = createIcon(CgExternal)
export const FormatTextIcon = createIcon(CgFormatColor)
export const FormatUnderlineIcon = createIcon(CgFormatUnderline)
export const FormatUppercaseIcon = createIcon(CgFormatUppercase)
export const FormIcon = createIcon(CgAttribution)
export const InfoIcon = createIcon(CgInfo)
export const MoreHorizontalIcon = createIcon(CgMoreAlt)
export const MoreIcon = createIcon(CgMoreVerticalAlt)
export const PlusIcon = createIcon(CgMathPlus)
export const RedoIcon = createIcon(CgRedo)
export const ImageIcon = createIcon(CgImage)
export const SearchIcon = createIcon(CgSearch)
export const SelectIcon = createIcon(CgSelect)
export const TrashIcon = createIcon(CgTrash)
export const UndoIcon = createIcon(CgUndo)
export const UserIcon = createIcon(CgUser)
export const UserListIcon = createIcon(CgUserList)
export const TagIcon = createIcon(CgTag)
export const EditIcon = createIcon(CgPen)
export const LocationIcon = createIcon(CgPin)
export const ClockIcon = createIcon(CgTime)
export const EmailIcon = createIcon(CgMail)
export const HashtagIcon = createIcon(CgHashtag)
export const InboxIcon = createIcon(CgInbox)
export const DashboardIcon = createIcon(CgPerformance)
export const SmileSadIcon = createIcon(CgSmileSad)
export const SmileNeutralIcon = createIcon(CgSmileNeutral)

const SVG = styled.svg({
  display: 'block'
})

export const RadioActiveIcon = createIcon((props: Record<string, unknown>) => (
  <SVG viewBox='0 0 24 24' {...props}>
    <circle fill='currentColor' cx='12' cy='12' r='6' />
  </SVG>
))

export const IndeterminateIcon = createIcon(
  (props: Record<string, unknown>) => (
    <SVG viewBox='0 0 24 24' {...props}>
      <rect fill='currentColor' height='2' width='12' x='6' y='11' />
    </SVG>
  )
)

export const DragHandleIcon = createIcon((props: Record<string, unknown>) => (
  <SVG viewBox='0 0 24 24' {...props}>
    <g stroke='none' strokeWidth='1' fill='currentColor' fillRule='evenodd'>
      <rect x='8' y='5' width='2' height='2' rx='1' />
      <rect x='8' y='9' width='2' height='2' rx='1' />
      <rect x='8' y='13' width='2' height='2' rx='1' />
      <rect x='8' y='17' width='2' height='2' rx='1' />

      <rect x='12' y='5' width='2' height='2' rx='1' />
      <rect x='12' y='9' width='2' height='2' rx='1' />
      <rect x='12' y='13' width='2' height='2' rx='1' />
      <rect x='12' y='17' width='2' height='2' rx='1' />
    </g>
  </SVG>
))
