/* eslint-disable @typescript-eslint/ban-types */
import * as React from 'react'

export type As<Props = any> = React.ElementType<Props>

export type PropsWithAs<T extends As, P> = P &
  Omit<PropsOf<T>, 'as' | 'color' | keyof P> & {
    as?: T | As
  }

// export type PropsOf<T extends As> = React.ComponentPropsWithoutRef<T> & {
//   as?: As
// }

export type PropsOf<T extends As> = React.ComponentProps<T>

export type OmitCommonProps<
  Target,
  OmitAdditionalProps extends keyof any = never
> = Omit<Target, 'transition' | 'as' | 'color' | OmitAdditionalProps>

export type RightJoinProps<
  SourceProps extends object = {},
  OverrideProps extends object = {}
> = OmitCommonProps<SourceProps, keyof OverrideProps> & OverrideProps

// export type MergeWithAs<
//   ComponentProps extends object,
//   AsProps extends object,
//   AdditionalProps extends object = {},
//   AsComponent extends As = As
// > = RightJoinProps<ComponentProps, AdditionalProps> &
//   RightJoinProps<AsProps, AdditionalProps> & {
//     as?: AsComponent
//   }

type Merge<T, P> = P extends Record<string, unknown> ? P & Omit<T, keyof P> : T

export interface ComponentWithAs<
  Component extends As,
  Props extends object = {}
> {
  // <AsComponent extends As>(
  //   props: MergeWithAs<
  //     React.ComponentProps<Component>,
  //     React.ComponentProps<AsComponent>,
  //     Props,
  //     AsComponent
  //   >
  // ): JSX.Element
  <AsComponent extends As>(
    props: Merge<PropsWithAs<Component, Props>, PropsWithAs<AsComponent, Props>>
  ): JSX.Element
  displayName?: string
  propTypes?: React.WeakValidationMap<any>
  contextTypes?: React.ValidationMap<any>
  defaultProps?: Partial<any>
  id?: string
}

export function forwardRef<P extends object, T extends As>(
  component: React.ForwardRefRenderFunction<
    any,
    RightJoinProps<PropsOf<T>, P> & {
      as?: As
    }
  >
) {
  // return React.forwardRef(component as any) as ComponentWithAs<T, P>
  return React.forwardRef(component as any) as unknown as ComponentWithAs<
    T,
    Omit<P, 'children' | 'as'>
  >
}
