import React, { forwardRef, ReactNode } from 'react'
import { Dialog, DialogProps, DialogStateReturn } from 'reakit/Dialog'
import { space, rem, isRenderProp } from '@woorcs/utils'
import { AnimatePresence, motion } from 'framer-motion'

import { CloseButton } from '../../buttons'

import { useModalContext, useModalDialog } from './useModal'
import { ModalContainer, ModalContainerProps } from './ModalContainer'
import { ModalBackdrop } from './ModalBackdrop'

type CreateModalDialogOptions = {
  withBackdrop?: boolean
}

export const createModalDialog = <
  P extends {
    centerContent?: boolean
  }
>(
  DialogComponent: React.ForwardRefExoticComponent<
    P & Pick<DialogStateReturn, 'show' | 'hide' | 'toggle'>
  >,
  { withBackdrop = true }: CreateModalDialogOptions = {}
) => {
  return (props: P) => {
    const state = useModalDialog()

    const dialog = (
      <Dialog {...state}>
        {(dialogProps: DialogProps) =>
          state.visible && (
            <DialogComponent {...dialogProps} {...state} {...props} />
          )
        }
      </Dialog>
    )

    if (!withBackdrop) {
      return dialog
    }

    return (
      <ModalBackdrop centerContent={props.centerContent}>
        {dialog}
      </ModalBackdrop>
    )
  }
}

type ModalChildren =
  | ReactNode
  | ((state: Pick<DialogStateReturn, 'show' | 'hide' | 'toggle'>) => ReactNode)

export interface ModalDialogProps extends ModalContainerProps {
  showCloseButton?: boolean
  centerContent?: boolean
  visible?: boolean
  children: ModalChildren
}

const defaultWidth = rem(620)

export const ModalDialog = createModalDialog<ModalDialogProps>(
  forwardRef<HTMLDivElement, ModalDialogProps>(
    (
      {
        showCloseButton = true,
        children,
        width = defaultWidth,
        visible,
        ...props
      },
      ref
    ) => {
      const { state } = useModalContext()

      return (
        <AnimatePresence>
          {visible ? (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, z: -100 }}
              animate={{ opacity: 1, z: 0 }}
              exit={{ opacity: 0, z: -100 }}
              transition={{
                type: 'spring',
                damping: 20,
                stiffness: 200
              }}
              onAnimationComplete={state.stopAnimation}
            >
              <ModalContainer
                ref={ref}
                __css={{
                  minWidth: width
                }}
                {...props}
              >
                {isRenderProp(children) ? children(props) : children}

                {showCloseButton && (
                  <CloseButton
                    position='absolute'
                    top={space(2)}
                    right={space(2)}
                    onClick={props.hide}
                  />
                )}
              </ModalContainer>
            </motion.div>
          ) : null}
        </AnimatePresence>
      )
    }
  )
)
