import React, { ReactNode } from 'react'
import { isFunction } from '@woorcs/utils'
import { PopoverStateReturn } from 'reakit/Popover'

import { usePopover, PopoverProvider, UsePopoverProps } from './usePopover'

export type Placement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start'

interface PopoverProps extends UsePopoverProps {
  children: ReactNode | ((state: PopoverStateReturn) => ReactNode)
}

export const Popover = ({ children, ...props }: PopoverProps) => {
  const context = usePopover(props)

  return (
    <PopoverProvider value={context}>
      {isFunction(children) ? children(context.state) : children}
    </PopoverProvider>
  )
}
