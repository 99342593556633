import { useCallback, useState } from 'react'
import * as TO from 'fp-ts-contrib/TaskOption'
import { useAuth0 } from '@auth0/auth0-react'
import { graphqlClient } from '@woorcs/graphql'
import { useOnce } from '@woorcs/hooks'
import { Client } from '@urql/core'
import { cacheExchange } from '@urql/exchange-graphcache'

import { introspectionSchema } from '../schema'

import { updates } from './updates'

export interface UseCreateClientProps {
  url: string
}

export const useCreateClient = ({ url }: UseCreateClientProps) => {
  const { getAccessTokenSilently, logout } = useAuth0()

  const getAccessToken = useCallback(
    () => TO.fromTask(getAccessTokenSilently),
    [getAccessTokenSilently]
  )

  const [client, setClient] = useState<Client | null>()

  useOnce(() => {
    const cache = cacheExchange({
      updates,
      schema: introspectionSchema as any
    })
    setClient(
      graphqlClient({
        // suspense: true,
        url,
        getAccessToken,
        logout,
        cache,
        onRequestError: (error) => {
          if (import.meta.env.DEV) {
            console.log('Graphql Request error', error)
          }
        }
      })
    )
  })

  return client
}
