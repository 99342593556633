import React, { useCallback, MouseEvent } from 'react'
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CopyIcon,
  TrashIcon,
  IconButton,
  Tooltip,
  EditIcon,
  Separator,
  Flex
} from '@woorcs/design-system'
import * as O from 'fp-ts/Option'
import * as RA from 'fp-ts/ReadonlyArray'
import { constFalse, identity, pipe } from 'fp-ts/function'
import { FormElement } from '@woorcs/form'
import { Path } from '@woorcs/types/Path'
import { uuid } from '@woorcs/types/UUID'

import { useElementIO, useParentElementIO } from '../../state'

interface ElementActionMenuActionsProps {
  element: FormElement.FormElement
  path: Path
  parent: FormElement.FormParentElement
  onEdit(): void
}

const isFirstChild =
  (child: FormElement.FormElement) => (parent: FormElement.FormParentElement) =>
    pipe(
      parent.children,
      RA.head,
      O.map((parent) => parent.id === child.id),
      O.fold(constFalse, identity)
    )

const isLastChild =
  (child: FormElement.FormElement) => (parent: FormElement.FormParentElement) =>
    pipe(
      parent.children,
      RA.last,
      O.map((parent) => parent.id === child.id),
      O.fold(constFalse, identity)
    )

export const ElementActionMenuActions = ({
  element,
  path,
  parent,
  onEdit
}: ElementActionMenuActionsProps) => {
  const [, index] = path
  const { onRemove } = useElementIO(element)
  const { addChild, moveChild } = useParentElementIO(parent)

  const isFirst = pipe(parent, isFirstChild(element))
  const isLast = pipe(parent, isLastChild(element))

  const handleMoveUp = useCallback(
    (e: MouseEvent) => {
      const move = moveChild(element, index - 1)

      move()
      e.preventDefault()
    },
    [element, index, moveChild]
  )

  const handleMoveDown = useCallback(
    (e: MouseEvent) => {
      const move = moveChild(element, index + 1)

      move()
      e.preventDefault()
    },
    [element, index, moveChild]
  )

  const handleCopy = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()

      if (FormElement.isInputElement(element)) {
        addChild({ ...element, id: uuid(), key: uuid() }, path[1] + 1)()

        return
      }

      addChild({ ...element, id: uuid() }, path[1] + 1)()
    },
    [addChild, element, path]
  )

  const handleRemove = useCallback(() => {
    const remove = onRemove()

    remove()
  }, [onRemove])

  return (
    <Flex alignItems='center' onClick={(e) => e.preventDefault()}>
      <Tooltip title='Edit'>
        <IconButton color='grey.200' variant='plain' mr={1} onClick={onEdit}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Copy'>
        <IconButton
          color='grey.200'
          variant='plain'
          mr={1}
          onClick={handleCopy}
        >
          <CopyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Remove'>
        <IconButton variant='plain' color='danger' onClick={handleRemove}>
          <TrashIcon />
        </IconButton>
      </Tooltip>

      <Separator
        backgroundColor='grey.700'
        orientation='vertical'
        height={12}
        mx={2}
      />

      {!isFirst && (
        <Tooltip title='Move up'>
          <IconButton color='grey.200' variant='plain' onClick={handleMoveUp}>
            <ChevronUpIcon />
          </IconButton>
        </Tooltip>
      )}

      {!isLast && (
        <Tooltip title='Move down'>
          <IconButton color='grey.200' variant='plain' onClick={handleMoveDown}>
            <ChevronDownIcon />
          </IconButton>
        </Tooltip>
      )}
    </Flex>
  )
}
