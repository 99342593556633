import React from 'react'

import { FilterList } from '../filters'
import { ListPageFilters } from '../ListPage'
import { PaginiationLimitSelect } from '../PaginiationLimitSelect'

import { useSubmissionListFiltersContext } from './filters'

export const SubmissionListFilters = () => {
  const { limit, setLimit } = useSubmissionListFiltersContext()
  return (
    <ListPageFilters>
      <FilterList />

      <PaginiationLimitSelect value={limit} onChange={setLimit} />
    </ListPageFilters>
  )
}
