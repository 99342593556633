import { SecondaryButton } from '@woorcs/design-system'
import { queryToDatumEither } from '@woorcs/graphql'
import { PreviewFormModal } from '@woorcs/inspection-form-editor'
import { constNull, pipe } from 'fp-ts/function'
import React from 'react'
import { useQuery } from 'urql'
import * as Optional from 'monocle-ts/Optional'
import * as DE from '@nll/datum/DatumEither'

import {
  PreviewModalQuery,
  PreviewModalQueryDocument
} from './__generated__/PreviewModal'

interface PreviewFromModalProps {
  revisionId: string
}

const formRevisionResponseOptional = pipe(
  Optional.id<PreviewModalQuery>(),
  Optional.prop('formRevision'),
  Optional.fromNullable
)

export const PreviewModal = ({ revisionId }: PreviewFromModalProps) => {
  const query = useQuery({
    query: PreviewModalQueryDocument,
    variables: { id: revisionId }
  })

  return pipe(
    query,
    queryToDatumEither(formRevisionResponseOptional),
    DE.squash(constNull, constNull, (revision) => (
      <PreviewFormModal definition={revision.definition} backButtonLabel='Back'>
        <SecondaryButton as='a' size='small' ml={2} target='_blank'>
          Preview
        </SecondaryButton>
      </PreviewFormModal>
    ))
  )
}
