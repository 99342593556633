import React, { ReactNode, useCallback } from 'react'
import { Modal } from '@woorcs/design-system'
import { useNavigate } from 'react-router'
import { Location } from 'history'

export interface ModalRouteProps {
  prevLocation: Location
  children: ReactNode
}

export const ModalRoute = ({ prevLocation, children }: ModalRouteProps) => {
  const navigate = useNavigate()

  const handleVisibilityChange = useCallback(
    (visible) => {
      if (!visible) {
        navigate(prevLocation.pathname, { replace: true })
      }
    },
    [navigate, prevLocation.pathname]
  )

  return (
    <Modal defaultVisible onVisiblityChange={handleVisibilityChange}>
      {children}
    </Modal>
  )
}
