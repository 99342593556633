import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {
  ListItemAvatar,
  ListItemTitle,
  SelectableListItem,
  Text
} from '@woorcs/design-system'
import * as Optional from 'monocle-ts/Optional'
import { pipe } from 'fp-ts/function'
import * as DE from '@nll/datum/DatumEither'
import * as O from 'fp-ts/Option'
import * as string from 'fp-ts/string'
import { useQuery } from 'urql'
import { queryToDatumEither } from '@woorcs/graphql'
import { useDebouncedCallback } from 'use-debounce'

import { SearchModal } from '@app/components'

import {
  SelectUserModalQuery,
  SelectUserModalUserFragment,
  SelectUserModalQueryDocument
} from './__generated__/SelectUserModal'

interface UserListItemProps {
  isSelected: boolean
  user: SelectUserModalUserFragment
  onSelect(user: SelectUserModalUserFragment): void
}

const UserListItem = ({ isSelected, user, onSelect }: UserListItemProps) => {
  const handleClick = useCallback(() => {
    onSelect(user)
  }, [onSelect, user])

  return (
    <SelectableListItem
      alignItems='center'
      isSelected={isSelected}
      dense
      onClick={handleClick}
    >
      <ListItemAvatar name={user.name} bg='accent.50' />
      <ListItemTitle>
        <Text>{user.name}</Text>
      </ListItemTitle>
    </SelectableListItem>
  )
}

const responseOptional = pipe(
  Optional.id<SelectUserModalQuery>(),
  Optional.prop('searchUsers')
)

interface SelectUserModalProps {
  searchPlaceholder?: string
  selectedId: O.Option<string>
  onSelect(user: SelectUserModalUserFragment): void
  children: ReactElement
}

export const SelectUserModal = ({
  searchPlaceholder = 'Find user',
  onSelect,
  selectedId,
  children
}: SelectUserModalProps) => {
  const isSelected = (user: SelectUserModalUserFragment) =>
    O.getEq(string.Eq).equals(O.some(user.id), selectedId)

  const [query, setQuery] = useState<string>('')
  const [debounced, setDebounced] = useState(query)
  const debounce = useDebouncedCallback((query: string) => {
    setDebounced(query)
  }, 200)

  useEffect(() => {
    debounce.callback(query)
  }, [debounce, query])

  const variables = useMemo(
    () => ({
      query: debounced,
      limit: 100
    }),
    [debounced]
  )

  return pipe(
    useQuery({
      query: SelectUserModalQueryDocument,
      variables
    }),
    queryToDatumEither(responseOptional),
    DE.squash(
      () => null,
      () => null,
      (users) => (
        <SearchModal
          items={users}
          query={query}
          searchPlaceholder={searchPlaceholder}
          renderItem={(user) => (
            <UserListItem
              key={user.id}
              user={user}
              isSelected={isSelected(user)}
              onSelect={onSelect}
            />
          )}
          onQueryChange={setQuery}
        >
          {children}
        </SearchModal>
      )
    )
  )
}
