import React from 'react'
import { space } from '@woorcs/utils'
import { palette } from '@woorcs/design-tokens'
import Color from 'color'

import { forwardRef } from '../../../system'
import { focusStyle } from '../../../styles'

import { ListItem, ListItemProps } from './List'
import { useListContext } from './Context'

const hoverColor = Color(palette.primary['300']).alpha(0.25).string()

interface SelectableListItemProps extends ListItemProps {
  isSelected?: boolean
}

export const SelectableListItem = forwardRef<SelectableListItemProps, 'li'>(
  (
    { dense: propDense, isSelected, ...other }: SelectableListItemProps,
    ref
  ) => {
    const { dense } = useListContext({
      dense: propDense
    })

    return (
      <ListItem
        ref={ref}
        __css={{
          px: dense ? 1 : 2,
          mb: space(1),
          color: 'grey.900',
          ':hover, :focus': {
            backgroundColor: 'primary.50'
          },
          //':focus': {
          //  color: 'primary.500',
          //  ...focusStyle
          //},
          '&:last-child': {
            mb: 0
          },
          ...(isSelected
            ? {
                color: 'primary.500',
                backgroundColor: 'primary.50',
                ':hover': {
                  backgroundColor: hoverColor
                }
              }
            : {})
        }}
        dense={dense}
        {...other}
      />
    )
  }
)
