import React from 'react'
import ReactDOM from 'react-dom'
import { DesignSystemProvider, SnackbarProvider } from '@woorcs/design-system'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { App } from './app'
import { AuthProvider } from './auth'

if (import.meta.env.PROD) {
  Sentry.init({
    environment: import.meta.env.MODE,
    dsn: import.meta.env.VITE_REACT_APP_SENTRY_DNS?.toString(),
    integrations: [new Integrations.BrowserTracing()]
    //tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '1.0')
  })
}

const root = document.getElementById('root') as HTMLElement

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <DesignSystemProvider>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </DesignSystemProvider>
    </AuthProvider>
  </React.StrictMode>,
  root
)
